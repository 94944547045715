import { Station } from "dash-dashradio-app-client-api";
import { navigationEventEmitter, EVENT_FAVORITES } from '../components/AppDrawer';
import { PresetsAPI } from 'dash-dashradio-app-client-api';
import { addFavorite, removeFavorite } from '../reducers/AllStationsReducer';
import { session } from '../App';

const favStationsInProgress: number[] = [];

export const handleStationFavorisation = async (station: Station, active: boolean,  authToken: string | undefined, dispatch: any) => {
    if (station.id) {
        const stationId = station.id!
        if(favStationsInProgress.includes(stationId))
            return;
        
        favStationsInProgress.push(stationId);
        //TODO apply to account if logged in
        if (active) {
            await dispatch(removeFavorite(stationId))
            if (authToken) {
                try{
                    await PresetsAPI.removePreset(session)(stationId)
                }catch(error){
                    dispatch(addFavorite(stationId))        //add preset again (restore state)
                }
            }
        } else {
            await dispatch(addFavorite(stationId))
            if (authToken) {
                try{
                    await PresetsAPI.addPreset(session)(stationId)
                }catch(error){
                    dispatch(removeFavorite(stationId))     //remove preset again (restore state)
                }
            }
            
        }
        const removeElementIndex = favStationsInProgress.indexOf(stationId);
        if(removeElementIndex > -1)
            favStationsInProgress.splice(removeElementIndex, 1);
        
            
    }
    navigationEventEmitter.emit(EVENT_FAVORITES)
}