import React, {CSSProperties, useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import { IconButton, useMediaQuery } from '@mui/material';
import * as Icons from '../Icons';
import SongHistoryItem from './SongHistoryItem';
import clsx from 'clsx';
import { HistorySong } from 'dash-dashradio-app-client-api'



const styles: {[key: string]: CSSProperties} = {
    drawerStyle: {
        minHeight: '100%',
        backgroundColor: '#fff',
        color: '#000',
        transition: 'all 250ms',
        overflow: 'auto',
        background: 'linear-gradient(180deg, rgba(1,63,94,1) 0%, rgba(6,16,44,1) 100%)',
    },
    darkBackground: {
        // backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--app-drawer-background-color'),
    },
    iconStyle: {
        width: 24,
        height: 24,
        objectFit: "contain"
    },
    flexbox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 8,
    },
    drawerTitle: {
        fontFamily: 'Lato',
        fontWeight: 900,   /* Regular */
        fontSize: '1.5625rem',
        color: '#fff',
        letterSpacing: -0.32,
        flex: 1,
    },
};
export const SongHistoryDrawer: React.FC<{ drawerOpen: boolean, setDrawerOpen: (open: boolean) => void }> = (props) => {
    const { drawerOpen, setDrawerOpen } = props;
    const [songHistory, setSongHistory] = useState([])

    const smallScreen = useMediaQuery('(max-width: 500px)');
    const drawerWidth = smallScreen ? '100vw' : 350;

    return (
        <Drawer anchor="right" open={drawerOpen} PaperProps={{ style: { overflow: 'hidden' } }}>
            <div style={{...styles.drawerStyle, width: drawerWidth}}>
                <div style={{...styles.darkBsackground, ...styles.flexbox}}>
                    <div style={styles.drawerTitle}>History</div>
                    <IconButton color="primary" onClick={() => setDrawerOpen(!drawerOpen)}>
                        <img style={styles.iconStyle} src={Icons.ICON_CHEVRON_RIGHT_WHITE} alt="" />
                    </IconButton>
                </div>

                <div style={{ padding: 8 }}>
                    {
                        songHistory && songHistory.map((item: HistorySong) => {
                            return (
                                <div style={{ padding: 8 }} key={`history_${item.pubDate}`}>
                                    <SongHistoryItem
                                        title={item.title}
                                        artist={item.description}
                                        cover={item.thumbnail || ""}
                                        time={item.pubDate && new Date(item.pubDate).toLocaleTimeString()} />
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </Drawer>
    )
}
