import React from 'react';
import { Page } from '../../AppDrawer';
import { getActivePage, setPageActive } from './Functions';
import { makeStyles, IconButton } from '@mui/material';
import Spacer from '../../ui-elements/Spacer';
import Content from '../../ui-elements/Content';
import Center from '../../ui-elements/Center';
import AppText from '../AppText';
import clsx from 'clsx';

interface DrawerItemProps {
    active: boolean;
    noPadding?: boolean;
    iconOnly?: boolean;
    iconTitle?: string;
    signIn?: boolean;
    signInButton?: boolean;
    mobileScreen?: boolean;
    renderIcon?: (active: boolean) => React.ReactNode;
    onClick?: () => void;
    children?: React.ReactNode;
}

export default class DrawerItem extends React.Component<DrawerItemProps> {

    constructor(props: DrawerItemProps) {
        super(props)

    }

    render() {
        return (
            <>
                {this.props.iconOnly && this.props.renderIcon && (
                    <div style={{ flex: 1 }}>
                        <Spacer size={10} />
                        <Content icons>
                            <IconButton
                                // className='drawer-item-button'
                                className='App-Drawer-Navigation-Item'
                                color={this.props.active ? "primary" : "secondary"}
                                onClick={this.props.onClick}
                                style={this.props.onClick && { cursor: "pointer" }}
                            >
                                {this.props.renderIcon && this.props.renderIcon(this.props.active)}
                            </IconButton>
                            {this.props.iconTitle && (
                                <Center>
                                    <AppText
                                        noPadding
                                        className={clsx('drawer-item-smallDrawerText', this.props.active && "sel")}
                                    >
                                        {this.props.iconTitle}
                                    </AppText>
                                </Center>
                            )}
                        </Content>
                    </div>
                )}
                <div
                    className={this.props.signInButton ? "App-Drawer-Navigation-Item-SignIn" : "App-Drawer-Navigation-Item"}
                    onClick={this.props.onClick}
                    style={{
                        ...(this.props.onClick && { cursor: "pointer" }),
                        ...(this.props.noPadding && { paddingTop: 0, paddingBottom: 0 }),
                    }}
                >
                    {this.props.children}
                </div >
            </>
        )
    }
}
