import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "@mui/material";
import { EventEmitter } from "events";
import { useDispatch } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import ClearIcon from '@mui/icons-material/Clear';
import { updateSettings } from "../reducers/LocalSettingsReducer";
import RenderTopbar from "./views/AppDrawerComponents/TopBar/TopBar";
import RenderSidebar from "./views/AppDrawerComponents/SideBar.tsx/SideBar";
import { StationsAPI, HighlightsAPI } from "dash-dashradio-app-client-api";
import { session } from "../App";
import { updateAllStations } from '../reducers/AllStationsReducer';
import { updateHighlights } from "../reducers/HighlightsReducer";
import { updateFeaturedStations } from "../reducers/FeaturedStationsReducer";


export const navigationEventEmitter = new EventEmitter();
export const EVENT_NAVIGATION = "com.dashradio.event.NAVIGATION";
export const EVENT_GENRE = "com.dashradio.event.GENRE";
export const EVENT_SCROLL = "com.dashradio.event.SCROLL";
export const EVENT_CURRENTSTATION = "com.dashradio.event.CURRENTSTATION";
export const EVENT_FAVORITES = "com.dashradio.event.FAVORITES";
export const EVENT_OPENMENUS = "com.dashradio.event.OPENMENUS";
export const EVENT_MYFAVORITES = "com.dashradio.event.MYFAVORITES";

export const HeaderHeightInVH = 5
export const HeaderHeightInPx = 55
export const HeaderHeightInPxForMobileAndTablet = 59

export enum Page {
	TWITTER_CALLBACK = "/twitter",
	DISCORD_CALLBACK = "/discord-auth",

	HOME = "/home",
	GENRES = "/genres",
	GUIDE = "/guide",
	NEWS = "/news",
	NEWS_DETAIL = "/news/*",
	APPS = "/apps",
	VIDEO = "/video",
	RESET_PASSWORD = "/resetpassword/*",
	LINK_ACCOUNT = "/link",
	RADIO = "/radio",

	ALL_STATIONS = "/allstations",
	FAVORITES = "/favorites",

	PROFILE = "/user/profile",
	POINTS = "/user/points",

	ABOUT = "/about",
	CONTACT = "/contact",
	TROUBLESHOOTING = "/troubleshooting",
	CONTEST_RULES = "/contest-rules",
	PRIVACY_POLICY = "/privacy",
	TERMS_OF_SERVICE = "/tos",
	CORPORATE = "/corporate",

	TWITTERLOGIN_FAILED = "/twitter-login-failed",
	DISCORDLOGIN_FAILED = "/discord-login-failed",

	SONOS_CONNECT = "/sonos-connect/*"
}
export enum MenuPage {
	USER = "/user/",
	DASH = "/dash/",
	DEBUG = "/debug/"
}

export const AppDrawer: React.FC<{
	drawerOpen: boolean;
	setDrawerOpen: (open: boolean) => void;
}> = props => {
	const { drawerOpen, setDrawerOpen } = props;
	const smallScreen = useMediaQuery("(max-width: 960px)");
	const mobileScreen = useMediaQuery('(max-width: 480px)')

	const dispatch = useDispatch()

	const setNewSideBar = (open: boolean) => {
		dispatch(updateSettings({ newSideBarOpen: open }))
	}

	useEffect(() => {
		HighlightsAPI.getHighlights(session)()
			.then(highlights => {
				if (highlights && Array.isArray && Array.isArray(highlights)) {
					dispatch(updateHighlights(highlights));
				}
			})
			.catch(err => {
				// console.log("error", err);
			});
	}, [])

	useEffect(() => {
		StationsAPI.getStations(session)().then(allStations => {
			allStations && dispatch(updateAllStations(allStations))      //update cache
		})
			.catch(err => {
				// console.log(err)
			})
	}, [])

	useEffect(() => {
		StationsAPI.getFeaturedStations(session)()
			.then(featured => {
				if (featured && Array.isArray && Array.isArray(featured)) {
					dispatch(updateFeaturedStations(featured));
				}
			})
			.catch(err => console.error("featured stations", err));
	}, [])

	return (
		<>
			{(!smallScreen || drawerOpen) &&
				<Drawer
					variant="permanent"
					open={true}
					PaperProps={{ style: { overflow: "hidden", border: 'none', zIndex: 1200, ...(mobileScreen && { maxWidth: 200 }) } }}
					anchor="left"
				>
					<RenderSidebar
						{...{
							smallScreen,
							drawerOpen,
							mobileScreen,
							drawerIconClick: () => { setDrawerOpen(!drawerOpen); setNewSideBar(drawerOpen) }
						}}
					/>
				</Drawer>}
			<Backdrop open={drawerOpen && smallScreen} onClick={() => { setDrawerOpen(!drawerOpen); setNewSideBar(!drawerOpen) }} style={{ zIndex: 3, backgroundColor: 'rgba(0,0,0, 0.8)' }}>
				<div className="header-backdrop-container">
					<ClearIcon style={{ color: 'white', fontSize: '3em' }} />
				</div>
			</Backdrop>
			<Drawer
				variant="permanent"
				open={true}
				PaperProps={{ style: { overflow: "hidden", borderBottom: 'none', alignSelf: 'flex-end', zIndex: 2 } }}
				anchor="top"
			>
				<RenderTopbar
					{...{
						smallScreen,
						mobileScreen,
						drawerIconClick: () => { setDrawerOpen(!drawerOpen); setNewSideBar(drawerOpen) }
					}}
				/>
			</Drawer>
		</>
	);
};
