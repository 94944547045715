import React from "react";
import { Page } from "../../AppDrawer";
import { getActivePage } from "./Functions";

export const DashRoute: React.FC<{
    route: Page[] | Page | undefined;
    fallback?: () => React.ReactNode;
    children?: React.ReactNode;
}> = props => {
    const page = getActivePage();
    let visible = false;
    if (page !== undefined && Array.isArray && Array.isArray(props.route)) {
        if (props.route.includes(page)) {
            visible = true;
        }
    } else if (props.route === page) {
        visible = true;
    }
    return (
        <>
            {visible && props.children}
            {!visible && props.fallback && props.fallback()}
        </>
    );
};
