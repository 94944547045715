import React from 'react';
import { Page } from '../../AppDrawer';
import { getActivePage, setPageActive } from './Functions';
import * as Icons from "../../../Icons";

interface DrawerSubPageItemProps {
    page: Page,
    active?: boolean,
    title: string,
    mobileScreen?: boolean
}

interface DrawerSubPageItemState {
    activeItem: boolean
}

export default class DrawerSubPageItem extends React.Component<DrawerSubPageItemProps, DrawerSubPageItemState>{

    constructor(props: DrawerSubPageItemProps) {
        super(props)

        var itemActive = this.checkActiveItem()

        this.state = {
            activeItem: itemActive
        }
    }

        componentDidUpdate() {
        var itemActive = this.checkActiveItem()

        if(this.state.activeItem !== itemActive){
            this.setState({ activeItem: itemActive })
        }
       
    }

    private checkActiveItem(): boolean {
        var activePage: Page | undefined = getActivePage();
        return this.props.page == activePage;
    }

    render() {
        return (
            <div className="App-Drawer-Genres" key={`DrawerSubPageItem_${this.props.title}`} onClick={() => { setPageActive(this.props.page); window.scrollTo(0, 0) }} style={{ ...(this.state.activeItem && { marginLeft: '1rem' }) }}>
                <div style={{ height: '100%', display: 'flex', justifySelf: 'center', alignSelf: 'center', }}>
                    <img alt="" src={Icons.ICON_POINT} width={6} height={6} style={{ ...(!this.state.activeItem && { display: 'none' }) }} />
                </div>
                <div style={{ ...(!this.state.activeItem ? { marginLeft: 0 } : { marginLeft: 6 }) }}>
                    {this.props.title}
                </div>
            </div>
        )
    }

}