import React from 'react'
import { Fade } from '@mui/material';

class FadeInHOC extends React.Component<{children?: React.ReactNode}> {
	constructor(props) {
		super(props);
	}
	render() {
		return (<Fade in={true} timeout={800}>
			<div tabIndex={-1}>
				{this.props.children}
			</div>
		</Fade>);
	}
}

export default FadeInHOC
