import * as React from 'react'
import { PresetsAPI } from 'dash-dashradio-app-client-api';
import { useSelector, useDispatch } from 'react-redux'
import { session } from '../App';
import { addFavorite, removeFavorite } from '../reducers/AllStationsReducer';
import PersistentReduxStoreProvider from '../GlobalStore';
import { ReduxAppState } from '../reducers';
import { ICON_HEART_UNUSED, ICON_HEART_USED, ICON_HEART_USED_WHITE, ICON_HEART_UNUSED_WHITE } from '../Icons';
import { navigationEventEmitter, EVENT_FAVORITES } from './AppDrawer';
import {handleStationFavorisation} from "../helper/StationFavHelper";

interface Props {
    station: any,
    appPlayer?: boolean,
    className?: string,
    style?: React.CSSProperties
}

const StationItemHeart: React.FC<Props> = React.memo((props) => {
    const { station, appPlayer } = props

    const authToken = useSelector((state: ReduxAppState) => state.login.auth_token)
    const active = useSelector((state: ReduxAppState) => Boolean(state.allStations.favorites && station.id && state.allStations.favorites.includes(station.id)))
    const dispatch = useDispatch()
    const favStationsInProgress: number[] = [];

    const handleClick = () => {
        handleStationFavorisation(station, active, authToken, dispatch);
    }

    return (
        <div className={!props.appPlayer ? "station-heart" : ''} style={props.style}>
            {active ?
                <img src={appPlayer ? ICON_HEART_USED_WHITE : ICON_HEART_USED} width={appPlayer ? '29px' : '22px'} className={props.appPlayer ? "image-heart" : ''} style={{ ...(!appPlayer && { display: 'flex', alignItems: 'top', marginTop: '1px', color: '#FF0000', cursor: 'pointer' }) }} onClick={() => handleClick()} />
                // <img src={ICON_HEART_UNUSED} width='100%' style={{ fontSize: appPlayer ? '14px' : '17px', display: 'flex', alignItems: 'top', color: appPlayer ? '#FFF' : '#FF0000', cursor: 'pointer' }} onClick={() => handleClick()} />
                :
                <img src={appPlayer ? ICON_HEART_UNUSED_WHITE : ICON_HEART_UNUSED} width={appPlayer ? '29px' : '22px'} className={props.appPlayer ? "image-heart" : ''} style={{ ...(!appPlayer && { display: 'flex', alignItems: 'top', marginTop: '1px', color: '#FF0000', cursor: 'pointer' }) }} onClick={() => handleClick()} />
            }
        </div>
    )
})

export default React.memo((props: any) => (
    <PersistentReduxStoreProvider>
        <StationItemHeart {...props} />
    </PersistentReduxStoreProvider>
))
