import React, { CSSProperties } from 'react'
import { LEFT_BUBBLE, RIGHT_BUBBLE } from '../../Icons';
import dash_story_text from './dash_studios/dash_story_text';
import Spacer from '../../components/ui-elements/Spacer';
import { useMediaQuery } from '@mui/material';
import { isSafari } from 'react-device-detect';

function getStyles(smallScreen?: boolean, smallTablet?: boolean, mobileScreen?: boolean) {
    const styles: { [key: string]: CSSProperties } = {
        title: {
            fontFamily: 'Lato',
            color: '#4C4C4C',
            letterSpacing: 0,
            fontWeight: "bold",
            fontSize: (!smallTablet && !mobileScreen) ? '54px' : (smallTablet && !mobileScreen) ? '32px' : '28px',
            lineHeight: (!smallTablet && !mobileScreen) ? '65px' : (smallTablet && !mobileScreen) ? '38px' : '34px'
        },
        subtitle: {
            fontFamily: 'Lato',
            color: '#A1A4AB',
            letterSpacing: .4,
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '33px'
        },
        text: {
            fontFamily: 'Lato',
            color: '#A1A4AB',
            letterSpacing: .4,
            fontSize: '14px'
        },
        pictureLeft: {
            flexDirection: 'column',
            display: 'flex',
            height: '100%',
            width: '33.333%',
            ...(smallTablet && { width: '25%', alignItems: 'flex-end' }),
        },
        pictureRight: {
            flexDirection: 'column',
            display: 'flex',
            height: '100%',
            width: smallTablet ? '25%' : '33.333%',
        }
    }
    return styles
}

const DasStorySection: React.FC = (props) => {
    const smallScreen = useMediaQuery("(max-width: 1300px)");
    const smallTablet = useMediaQuery("(max-width: 1200px)");
    // const smallTablet2 = useMediaQuery("(max-width: 1280px)");
    const smallTablet2 = useMediaQuery("(max-width: 960px)");
    const mobileScreen = useMediaQuery('(max-width: 480px)')

    const breakpointSafari = useMediaQuery('(max-width: 555px)')

    const styles = getStyles(smallScreen, smallTablet, mobileScreen)

    return (
        <>
            <div style={{ flexDirection: 'row', display: 'flex', height: '50vh', justifyContent: 'space-between', ...(mobileScreen && { paddingLeft: 16, paddingRight: 16 }) }}>
                {!mobileScreen &&
                    <div style={styles.pictureLeft}>
                        <img alt="left bubble" src={LEFT_BUBBLE} style={{ objectFit: (smallTablet && !smallTablet2) ? 'scale-down' : ((smallTablet && smallTablet2) ? 'cover' : 'none'), height: '100%' }} />
                    </div>
                }
                <div style={{ flexDirection: 'column', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ ...styles.title, ...(isSafari && breakpointSafari && { fontSize: 30 }) }}>
                        THE LITT STORY
                    </div>
                    <div style={{ ...styles.subtitle, ...(isSafari && breakpointSafari && { fontSize: 18 }) }}>
                        OUR D&A
                    </div>
                    <Spacer size={16} />
                    <div style={{ ...styles.text, ...(isSafari && breakpointSafari && { fontSize: 12 }) }} dangerouslySetInnerHTML={{ __html: dash_story_text }} />
                </div>
                {!mobileScreen &&
                    <div style={styles.pictureRight}>
                        <img src={RIGHT_BUBBLE} alt="right bubble" style={{ objectFit: smallTablet ? 'cover' : 'contain', height: '100%' }} />
                    </div>
                }
            </div>
        </>
    )
}

export default React.memo(DasStorySection)
