import React from 'react';
import './sidebar.scss';
import { View } from '../../../ui-elements/View';
import { Page, navigationEventEmitter, EVENT_CURRENTSTATION, EVENT_GENRE, EVENT_FAVORITES, EVENT_OPENMENUS, EVENT_MYFAVORITES } from '../../../AppDrawer';
import { setPageActive, getActivePage, getDrawerWidth, getGenres, getFavorites } from '../Functions';
import { Station } from 'dash-dashradio-app-client-api';
import { AllStationsState } from '../../../../reducers/AllStationsReducer';
import { useSelector, useDispatch, connect } from "react-redux";
import * as Icons from "../../../../Icons";
import DrawerNavigationItem from '../DrawerNavigationItem';
import PlayerStoreProvider from '../../../../PlayerStore';
import { PlayerIdleSelector } from '../../../../reducers/PlayerReducer';
import StationDefaultCover from '../../../player/StationDefaultCover';
import DrawerSubPageItem from '../DrawerSubPageItem';
import LoginButton from '../LoginButton/LoginButton';
import { ReduxAppState } from '../../../../reducers';
import RedirectButton from './RedirectButton';

interface RenderSideBarProps {
	smallScreen?: boolean,
	mobileScreen?: boolean,
	drawerIconClick(): void,
	drawerOpen?: boolean,
	stations?: Station[]
	allStations: AllStationsState,
	favs?: Station[]
}

interface RenderSideBarStates {
	isFavorites: boolean,
	genres: any,
	drawerWidth: any,
	isPlayerActive: boolean,
	activePage: Page | undefined,
	favorites?: Station[] | undefined,
	allGenresOpen?: boolean,
	aboutOpen?: boolean
}

interface aboutPagesInterface {
	title: string,
	page: Page
}

const CoverView: React.FC = props => {
	const playerIdle = useSelector(PlayerIdleSelector);
	return (
		<div
			style={{
				position: "absolute",
				bottom: 0,
				left: 0,
				right: 0,
				display: playerIdle ? "none" : "block"
			}}
		>
			<StationDefaultCover />
		</div>
	);
};

const mapStateToProps = (state: ReduxAppState) => ({
	stations: state.allStations.stations,
	allStations: state.allStations
})

class RenderSidebar extends React.Component<RenderSideBarProps, RenderSideBarStates>{

	constructor(props: RenderSideBarProps) {
		super(props)

		let activePage: Page | undefined = getActivePage();

		this.state = {
			isFavorites: false,
			genres: undefined,
			drawerWidth: getDrawerWidth(true),
			isPlayerActive: localStorage.getItem('currentStation') ? true : false,
			activePage: activePage,
			favorites: undefined,
			allGenresOpen: false,
			aboutOpen: false
		}

		this.eventListener = this.eventListener.bind(this)

	}

	eventListener = (event) => {
		this.setState({
			isPlayerActive: true
		})
	}

	heartListener = (event) => {
		this.setState({
			isFavorites: getFavorites(this.props.allStations).length > 0,
			favorites: getFavorites(this.props.allStations)
		})
	}

	handleOpenMenus = (event) => {
		this.setState({
			allGenresOpen: false,
			aboutOpen: false
		})
	}

	componentDidMount() {
		this.setState({
			isFavorites: getFavorites(this.props.allStations).length > 0,
			genres: getGenres(this.props.stations || []),
			favorites: getFavorites(this.props.allStations)
		})

		navigationEventEmitter.addListener(EVENT_CURRENTSTATION, this.eventListener)
		navigationEventEmitter.addListener(EVENT_FAVORITES, this.heartListener)
		navigationEventEmitter.addListener(EVENT_OPENMENUS, this.handleOpenMenus)
	}

	componentWillUnmount() {
		navigationEventEmitter.removeListener(EVENT_CURRENTSTATION, this.eventListener)
		navigationEventEmitter.removeListener(EVENT_FAVORITES, this.heartListener)
		navigationEventEmitter.addListener(EVENT_OPENMENUS, this.handleOpenMenus)
	}

	componentDidUpdate() {
		let activePage = getActivePage();
		if (this.state.activePage !== activePage)
			this.setState({ activePage });
		if (!this.state.isFavorites && this.state.favorites && this.state.favorites.length > 0) {
			this.setState({
				isFavorites: true
			})
		} else if (this.state.isFavorites && this.state.favorites && this.state.favorites.length === 0) {
			this.setState({
				isFavorites: false
			})
		}
	}

	private logoClick() {
		setPageActive(Page.HOME)
	}

	private genreClick(genre: any) {
		let activePage = getActivePage()
		setPageActive(Page.GENRES, false, { genre: genre.id })
		if (activePage !== (Page.GENRES || Page.FAVORITES || Page.ALL_STATIONS)) {
			const timer = setTimeout(() => {
				navigationEventEmitter.emit(EVENT_GENRE, genre)

			}, 1500);
			return () => clearTimeout(timer);
		} else {
			navigationEventEmitter.emit(EVENT_GENRE, genre)
		}
	}

	private getAboutPages(): aboutPagesInterface[] {
		let aboutPages = [
			{
				title: 'Troubleshooting',
				page: Page.TROUBLESHOOTING
			},
			{
				title: 'Contest Rules',
				page: Page.CONTEST_RULES
			},
			{
				title: 'Privacy Policy',
				page: Page.PRIVACY_POLICY
			},
			{
				title: 'Terms of Service',
				page: Page.TERMS_OF_SERVICE
			},
			{
				title: 'Contact',
				page: Page.CONTACT
			}
		]

		return aboutPages
	}

	render(): React.ReactNode {
		return (
			<View>
				{(!this.props.smallScreen || this.props.drawerOpen) &&
					<>
						<div className="side-bar-container">
							<div
								style={{
									maxWidth: this.state.drawerWidth,
								}}
								className="logo-container"
								onClick={() => this.logoClick()}
							>
								<img
									className="LogoDash"
									src={Icons.ICON_LOGO_DASH_WHITE}
									alt="LITT Logo"
									width="100%"
								/>
							</div>
							{/**86 = 100vh - 14% (logo) */}
							<div className="side-bar" style={{ ...(!this.state.isPlayerActive ? { height: '86vh' } : { height: 'calc(86vh - 222px)' }) }}>
								<div className="side-bar-item-container">
									<>
										<div style={{ marginBottom: '.6rem', cursor: 'pointer' }}>
											<LoginButton mobileScreen={this.props.mobileScreen} />
										</div>
										{/* <DrawerNavigationItem
											secondary
											drawerOpen={true}
											page={Page.HOME}
											title="Home"
											icon={Icons.ICON_HOME}
											mobileScreen={this.props.mobileScreen}
										/> */}
										<div onClick={() => this.setState({ allGenresOpen: !this.state.allGenresOpen })}>
											<DrawerNavigationItem
												secondary
												drawerOpen={true}
												page={Page.GENRES}
												title="Listen by Genre"
												icon={Icons.ICON_VIDEOS}
												active={this.state.activePage === Page.GENRES || this.state.activePage === Page.ALL_STATIONS || this.state.activePage === Page.FAVORITES}
												mobileScreen={this.props.mobileScreen}
											/>
										</div>

										<View visible={this.state.allGenresOpen}>
											<div style={{ marginBottom: '1.4rem' }}>
												{/* {this.state.genres && this.state.genres.map((genre) => { */}
												{this.state.isFavorites &&
													<div className="App-Drawer-Genres" onClick={() => navigationEventEmitter.emit(EVENT_MYFAVORITES)}>
														My Favorites
													</div>
												}
												{getGenres(this.props.stations && this.props.stations || []).map((genre) => {
													return (
														<div className="App-Drawer-Genres" key={`Genre_${genre.genre_name}`} onClick={() => this.genreClick(genre.id)}>
															{genre.genre_name}
														</div>
													)
												})}
											</div>
										</View>


										{/* <DrawerNavigationItem
											secondary
											drawerOpen={true}
											page={Page.VIDEO}
											title="Watch"
											icon={Icons.ICON_VIDEOS}
											mobileScreen={this.props.mobileScreen}
										/> */}


										<div onClick={() => this.setState({ aboutOpen: !this.state.aboutOpen })}>
											<DrawerNavigationItem
												secondary
												drawerOpen={true}
												page={Page.ABOUT}
												title="About"
												// active={this.state.activePage === Page.ABOUT}
												mobileScreen={this.props.mobileScreen}
											/>
										</div>

										<View visible={this.state.aboutOpen}>
											<div style={{ marginBottom: '1.4rem' }} >
												{this.getAboutPages().map((about) => {
													return (
														<DrawerSubPageItem
															title={about.title}
															page={about.page}
															mobileScreen={this.props.mobileScreen}
															active={this.state.activePage === about.page}
															key={`AboutPage_${about.title}`}
														/>
													)
												})}
											</div>
										</View>
										
										{/*<RedirectButton
											title="Invest Here"
											url="https://invest.thelittapp.com/"
											/>*/}

									</>
									<div className="social-icon-container">
										<div className="firstSocialIcon">
											<img
												src={Icons.ICON_INSTAGRAM}
												onClick={() =>
													window.open("https://www.instagram.com/littxlive/")
												}
												alt="instagram"
											/>
										</div>
										<div className="social-icons">
											<img
												src={Icons.ICON_TWITTER}
												onClick={() => window.open("https://twitter.com/littxlive")}
												alt="X"
												style={{ width: '19px', height: '17px' }}
											/>
										</div>
										{/* <div className="social-icons">
											<img
												src={Icons.ICON_YOUTUBE}
												onClick={() =>
													window.open(
														"https://youtube.com/dashradio"
													)
												}
												alt="youtube"
											/>
										</div> */}
										<div className="social-icons">
											<img
												src={Icons.ICON_FACEBOOK}
												onClick={() =>
													window.open("https://www.facebook.com/littxlive/")
												}
												alt="facebook"
											/>
										</div>
										<div className="social-icons">
											<img
												src={Icons.ICON_TIKTOK}
												onClick={() =>
													window.open("https://www.tiktok.com/@littxlive")
												}
												alt="tiktok"
											/>
										</div>
									</div>

									<img
										src={Icons.DISCORD_INSTALL}
										width="100%"
										height="auto"
										style={{marginTop: 20, cursor: "pointer"}}
										onClick={() =>
											window.open("https://discord.com/api/oauth2/authorize?client_id=774181965250428989&permissions=4185152&scope=applications.commands%20bot")
										} />
								</div>
							</div>
						</div>

						<PlayerStoreProvider>
							<CoverView />
						</PlayerStoreProvider>
					</>
				}
			</View>
		)
	}
}

export default connect(mapStateToProps)(RenderSidebar)
