import React from 'react'
import HeadlineText from './HeadlineText';
import HeadlineSubText from './HeadlineSubText';
import { useMediaQuery } from '@mui/material';

const HeadlineBigText2: React.FC<{ style?: any, className?: any, onClick?: any, center?: boolean, title: string, subtitle?: string, smallScreen?: boolean, smallTablet?: boolean, mobileScreen?: boolean }> = (props) => (
    <div style={props.style} onClick={props.onClick}>
        <HeadlineText style={{ ...(props.center && { textAlign: 'center', fontFamily: 'Lato', ...((props.smallTablet && !props.mobileScreen) ? { fontSize: '2rem' } : ((props.smallTablet && props.mobileScreen) ? { fontSize: '1rem' } : { fontSize: '54px', lineHeight: '65px' })), color: 'white', fontWeight: 500, paddingBottom: 0 }) }}>{props.title.toUpperCase()}</HeadlineText>
        {props.subtitle && <HeadlineSubText style={{ ...(props.center && { textAlign: 'center', fontFamily: 'Lato', ...(props.smallTablet ? { fontSize: '.6rem' } : { fontSize: '20px' }), color: 'white', fontWeight: 500 }) }}>{props.subtitle.toUpperCase()}</HeadlineSubText>}
    </div>
)

export default HeadlineBigText2
