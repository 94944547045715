import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { DashThemeLight } from '../Themes';
import AppPage from './AppPage';

export const LoadingPage: React.FC = () => {
	return (
		<AppPage>
			<DashThemeLight>
				<CircularProgress/>
			</DashThemeLight>
		</AppPage>
	)
}
export default LoadingPage;
