import { User } from "dash-dashradio-app-client-api";

interface LoginState {
    auth_token?: string,
    user?: User,
    discord_csrf_token?: string;
}
const initialState: LoginState = { auth_token: undefined, user: undefined, discord_csrf_token: undefined }

const ACTION_SET_SESSION = "actions-login-set-session"
const ACTION_CLEAR_SESSION = "actions-login-clear-session"
const ACTION_SET_DISCORD_CSRF_TOKEN = "actions-login-set-discord-csrf-token";

interface ActionSetSession {
    type: typeof ACTION_SET_SESSION,
    auth_token?: string,
    user?: User,
}
interface ActionClearSession {
    type: typeof ACTION_CLEAR_SESSION,
}
interface ActionSetDiscordCSRFToken {
    type: typeof ACTION_SET_DISCORD_CSRF_TOKEN,
    discord_csrf_token?: string,
}
export type Actions = ActionSetSession | ActionClearSession | ActionSetDiscordCSRFToken;

export function setSession(auth_token?: string, user?: User): ActionSetSession {
    return {
        type: ACTION_SET_SESSION,
        auth_token, user,
    }
}
export function clearSession(): ActionClearSession {
    return {
        type: ACTION_CLEAR_SESSION,
    }
}
export function setDiscordCSRFToken(discord_csrf_token?: string): ActionSetDiscordCSRFToken {
    return {
        type: ACTION_SET_DISCORD_CSRF_TOKEN,
        discord_csrf_token,
    }
}


export default function LoginReducer(state = initialState, action: Actions): LoginState {
    var isEqual = false
    var stateUpdate
    switch (action.type) {
        case ACTION_SET_SESSION: {
            stateUpdate = {
                ...state,
            }
            if (action.auth_token) {
                stateUpdate.auth_token = action.auth_token
            }
            if (action.user) {
                stateUpdate.user = action.user
            }
            break
        }
        case ACTION_CLEAR_SESSION: {
            stateUpdate = {
                ...state,
                auth_token: undefined,
                user: undefined,
            }
            break
        }
        case ACTION_SET_DISCORD_CSRF_TOKEN: {
            stateUpdate = {
                ...state,
                discord_csrf_token: action.discord_csrf_token,
            }
        }
    }

    if (stateUpdate) {
        try {
            if (JSON.stringify(state) === JSON.stringify(stateUpdate)) {
                isEqual = true
            }
        }
        catch (err) {
            //
        }
        if (!isEqual) {
            return stateUpdate      //return new state
        }
    }

    return state
}
