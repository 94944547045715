import React, {CSSProperties} from 'react'
import { CircularProgress, makeStyles } from '@mui/material';
import { IMG_DEFAULT_COVER } from '../Icons';
import {Img} from 'react-image'

const styles: {[key: string]: CSSProperties} = {
    imageStyle: {
        width: 64,
        height: 64,
        objectFit: 'cover',
    },
    centerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexbox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        overflow: 'hidden',
        padding: 8,
    },
    textTime: {
        fontFamily: 'Lato',
        fontWeight: 300,   // Light
        fontSize: '0.6875rem',
        color: '#fff',
        letterSpacing: -0.13,
        lineHeight: '13px',
        paddingBottom: 2,
    },
    textTitle: {
        fontFamily: 'Lato',
        fontWeight: 400,   // Regular
        fontSize: '0.8125rem',
        color: '#fff',
        letterSpacing: -0.13,
        lineHeight: '13px',
        paddingBottom: 4,
    },
    textArtist: {
        fontFamily: 'Lato',
        fontWeight: 300,   // Light
        fontSize: '0.8125rem',
        color: '#fff',
        letterSpacing: -0.13,
        lineHeight: '11px',
    },
}

export interface Props {
    cover: string,
    time?: string,
    title: string,
    artist?: string,
}
const SongHistoryItem: React.FC<Props> = (props) => {
    return (
        <div style={styles.root}>
            <Img
                src={[props.cover, IMG_DEFAULT_COVER]}
                loader={<div style={styles.centerStyle}><CircularProgress color="primary" /></div>}
                style={styles.imageStyle}
                alt="" />
            <div style={styles.flexbox}>
                <div style={styles.textTime}>{props.time}</div>
                <div style={styles.textTitle}>{props.title}</div>
                <div style={styles.textArtist}>{props.artist}</div>
            </div>
        </div>
    )
}

export const MockupSongHistoryItem: React.FC = ()=><SongHistoryItem cover="" time="11:16 AM" title="Mark Yo Money" artist="By TeeFlii ft. Casey Veggies and Nipsey Hussle" />

export default SongHistoryItem
