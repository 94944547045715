import * as React from 'react';
import MediaQuery from 'react-responsive';


export default class extends React.Component<{children?: React.ReactNode}> {


	render() {
		return (
			<MediaQuery orientation="portrait">
				{this.props.children}
			</MediaQuery>
		)
	}
}
