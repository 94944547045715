import React from 'react';
import Spacer from '../../components/ui-elements/Spacer';
import Headline from '../../components/views/Headline';
import PlatformSection from '../../components/PlatformSection';
import * as Icons from '../../Icons';


interface PlatformItem {
    // title: string,
    icon: any,
    // maxHeight?: string
}

const AppPlatformsElement: React.FC = (props) => {

    const itemsMobile: PlatformItem[] = [
        {
            icon: Icons.onEveryDevice1
        },
        {
            icon: Icons.onEveryDevice2
        },
        {
            icon: Icons.onEveryDevice3
        },
    ]
    const itemsHome: PlatformItem[] = [
        {
            icon: Icons.inYourHome1
        },
        {
            icon: Icons.inYourHome2
        },
        {
            icon: Icons.inYourHome3
        },
        {
            icon: Icons.inYourHome4
        },
        {
            icon: Icons.inYourHome5
        },
        {
            icon: Icons.inYourHome6
        },
        {
            icon: Icons.inYourHome7
        },
        // {
        //     icon: Icons.inYourHome8
        // },
        {
            icon: Icons.inYourHome9
        },
        {
            icon: Icons.inYourHome10
        },
        {
            icon: Icons.inYourHome11
        },
        // {
        //     icon: Icons.inYourHome12
        // },
        {
            icon: Icons.inYourHome13
        },
    ]
    const itemsCar: PlatformItem[] = [
        {
            icon: Icons.inYourCar1
        },
        {
            icon: Icons.inYourCar2
        },
        {
            icon: Icons.inYourCar3
        },
        {
            icon: Icons.inYourCar4
        },
        {
            icon: Icons.inYourCar5
        },
        {
            icon: Icons.inYourCar6
        },
        {
            icon: Icons.inYourCar7
        },
        {
            icon: Icons.inYourCar8
        },
        {
            icon: Icons.inYourCar9
        },
        {
            icon: Icons.inYourCar10
        },
        {
            icon: Icons.inYourCar11
        },
        {
            icon: Icons.inYourCar12
        },
        {
            icon: Icons.inYourCar13
        },
        {
            icon: Icons.inYourCar14
        },
        {
            icon: Icons.inYourCar15
        },
        {
            icon: Icons.inYourCar16
        },
        {
            icon: Icons.inYourCar17
        },
        {
            icon: Icons.inYourCar18
        },
    ]

    return (
        <>
            {/* <Headline title="Apps" subtitle="Radio Everywhere You Go" /> */}

            {/* <PlatformSection appSide title="On Every Device" items={itemsMobile} background={IMG_APP_PLATFORM_BACKGROUND_MOBILE} /> */}
            <PlatformSection title="On Every Device" inYourCar items={itemsMobile} logoSlider apps style={{ height: 100 }} />
            <Spacer size={16} />
            <PlatformSection title="In Your Home" inYourCar items={itemsHome} logoSlider apps style={{ height: 100 }} />
            {/* <PlatformSection appSide title="In Your Home" items={itemsHome} background={IMG_APP_PLATFORM_BACKGROUND_HOME} /> */}
            <Spacer size={16} />
            <PlatformSection title="In Your Car" inYourCar items={itemsCar} logoSlider apps style={{ height: 100 }} />
            {/* <PlatformSection appSide title="In Your Car" items={itemsCar} background={IMG_APP_PLATFORM_BACKGROUND_CAR} /> */}
        </>
    )
}

export default AppPlatformsElement
