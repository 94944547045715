import React, { useEffect, useState } from 'react';
import { Grid, Hidden, useMediaQuery } from '@mui/material';
import AppPage from '../AppPage';
import { useDispatch } from 'react-redux'
import { Station, StationsAPI } from 'dash-dashradio-app-client-api';
import Headline from '../../components/views/Headline';
import './StationDetailed.scss';
import { updateAllStations } from '../../reducers/AllStationsReducer';
import { session } from '../../App';
import axios from 'axios';
import { AllStationsInGenreSelector } from '../../components/views/AppDrawerComponents/Functions';
import StationItem from '../../components/StationItem';
import { Referrer } from '../../reducers/PlayerReducer';
import StationItemHeart from '../../components/StationItemHeart';
const XMLParser = require('react-xml-parser');

interface IActualStation {
    cover: string,
    album_name: string,
    song_title: string,
    artist: string
}

const StationDetailed: React.FC<{ station: Station }> = (props) => {
    const dispatch = useDispatch()

    const mobileScreen = useMediaQuery('(max-width: 480px)');

    const [actualStation, setActualStation] = useState<IActualStation>()
    const [genreStations, setGenreStations]: any = useState([])

    useEffect(() => {
        if(props.station && props.station.current_song_url && !props.station.prevent_song_cover) {
            const url = props.station.current_song_url.replace('http://', 'https://')

            axios.get(url).then(data => {
                let xml = new XMLParser().parseFromString(data.data)
                let stationObject: IActualStation = {
                    cover: xml.getElementsByTagName('cover')[0].value,
                    album_name: xml.getElementsByTagName('album')[0].value,
                    song_title: xml.getElementsByTagName('title')[0].value,
                    artist: "By " + xml.getElementsByTagName('artist')[0].value
                }
                setActualStation(stationObject)
            })
            StationsAPI.getStations(session)().then(allStations => {
                if(!allStations)
                    return;
                setGenreStations(AllStationsInGenreSelector(allStations, props.station.genreObj!.id))
                dispatch(updateAllStations(allStations));
            });

        }
    }, [])

    return (
        <React.Fragment>
            <AppPage>
                <Grid container direction="row" justifyContent="center">
                    <Hidden smDown><Grid item sm={1}></Grid></Hidden>
                    <Grid item xs={12} sm={12}>
                        {!mobileScreen &&
                            <div className="station-detailed-container">
                                <div className="station-detailed-logo">
                                    <img className="station-detailed-logo-img" src={props.station.default_cover_url} alt="Station-logo" />
                                </div>
                                <div className="station-detailed-infos">
                                    <div className="genre">{props.station.genre}</div>
                                    <div className="name-heart-container">
                                        <div className="name">
                                            {props.station.name}
                                        </div>
                                        <StationItemHeart station={props.station} style={{ width: 'unset', alignItems: 'center', marginLeft: 60 }} />
                                    </div>
                                    <div className="description">{props.station.description}</div>
                                    <div className="genre">Now Playing</div>
                                    <div className="station-detailed-album">
                                        <img className="album-img" src={actualStation && actualStation.cover.length > 1 ? actualStation.cover : props.station.default_cover_url} width={101} height={101} />
                                        <div className="album-infos">
                                            <div className="album-title">{actualStation && actualStation.song_title}</div>
                                            {/* <div className="album-artist-name" >By {actualStation && actualStation.artist}</div> */}
                                            <div className="album-artist-name" dangerouslySetInnerHTML={{ __html: actualStation !== undefined ? actualStation.artist : '' }} />
                                            <div className="album-artist-name">{actualStation && actualStation.album_name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {mobileScreen &&
                            <div className="station-detailed-container mobile">
                                <div className="station-detailed-logo">
                                    <img className="station-detailed-logo-img" src={actualStation && actualStation.cover.length > 1 ? actualStation.cover : props.station.default_cover_url} alt="Station-logo" />
                                </div>
                                <div className="station-detailed-infos mobile">
                                    {/* <div className="genre">{props.station.genre}</div> */}
                                    <div className="name-heart-container">
                                        <div className="name">
                                            {props.station.name}
                                        </div>
                                        <StationItemHeart station={props.station} style={{ width: 'unset', alignItems: 'center', marginLeft: !mobileScreen ? 60 : 99 }} />
                                    </div>
                                    <div className="description">{props.station.description}</div>
                                    {/* <div className="genre">Now Playing</div> */}
                                    <div className="station-detailed-album">
                                        <img className="album-img" src={actualStation && actualStation.cover.length > 1 ? actualStation.cover : props.station.default_cover_url} width={101} height={101} />
                                        <div className="album-infos">
                                            <div className="album-title">{actualStation && actualStation.song_title}</div>
                                            <div className="album-artist-name">By {actualStation && actualStation.artist}</div>
                                            <div className="album-artist-name">{actualStation && actualStation.album_name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </Grid>
                    <Grid item xs={12} sm={10} className="similiar-stations-grid">
                        <Headline center title="SIMILAR STATIONS" />
                        <div className="Genre-grid">
                            <Grid container spacing={4} justifyContent="center" alignItems="center" alignContent="center" style={{ width: '100%', margin: 'unset' }}>
                                {
                                    genreStations && genreStations.map(station => {
                                        return <StationItem key={station.id} station={station} referrerInfo={{ referrer: Referrer.ALL }} />
                                    })
                                }
                            </Grid>
                        </div>
                    </Grid>
                    <Hidden smDown><Grid item sm={1}></Grid></Hidden>
                </Grid>
            </AppPage>
        </React.Fragment>
    )
}


export default React.memo(StationDetailed)

