import { Station } from "dash-dashradio-app-client-api";
import { ReduxPlayerState } from ".";

export enum PlaybackState {
    IDLE,
    LOADING,        //unused until further
    BUFFERING,      //unused until further
    PLAYING,
    PAUSED
}

export enum Referrer {
    HIGHLIGHTS = "highlights",
    FEATURED = "featured",
    GENRE = "genre",
    DASHBOARD = "dashboard",
    ALL = "all",
}

interface PlayerReduxState {
    playbackState: PlaybackState
    referrer: Referrer
    referrerId?: string | number
    useCleanStream: { [stationId: string]: boolean }
    volumeLevel: number
    currentStation?: Station
}
const initialState: PlayerReduxState = {
    playbackState: PlaybackState.IDLE,
    referrer: Referrer.ALL,
    referrerId: undefined,
    useCleanStream: {},
    volumeLevel: 50,
    currentStation: undefined
}

const ACTION_SET_PLAYBACK_STATE = "actions-player-set-playback-state"
const ACTION_SET_CURRENT_STATION = "actions-player-set-current-station"
const ACTION_SET_USE_CLEAN_STREAM = "actions-player-set-use-clean-stream"
const ACTION_SET_VOLUME = "actions-player-set-volume"
interface ActionSetPlaybackState {
    type: typeof ACTION_SET_PLAYBACK_STATE,
    playbackState: PlaybackState
}
interface ActionSetCurrentStation {
    type: typeof ACTION_SET_CURRENT_STATION,
    currentStation?: Station,
    playbackState?: PlaybackState,
    referrer?: Referrer,
    referrerId?: string | number,
}
interface ActionSetUseCleanStream {
    type: typeof ACTION_SET_USE_CLEAN_STREAM,
    useCleanStream: { [stationId: string]: boolean }
}
interface ActionSetVolume {
    type: typeof ACTION_SET_VOLUME,
    volume: number
}
export type Actions = ActionSetPlaybackState | ActionSetCurrentStation | ActionSetUseCleanStream | ActionSetVolume

export function setPlaybackState(playbackState: PlaybackState): ActionSetPlaybackState {
    return {
        type: ACTION_SET_PLAYBACK_STATE,
        playbackState: playbackState || PlaybackState.IDLE
    }
}
export function setCurrentStation(station?: Station, playbackState?: PlaybackState, referrer?: Referrer, referrerId?: string | number): ActionSetCurrentStation {
    if (playbackState === PlaybackState.PLAYING) {
        localStorage.setItem('currentStation', JSON.stringify(station))
    }
    return {
        type: ACTION_SET_CURRENT_STATION,
        currentStation: station,
        playbackState,
        referrer, referrerId,
    }
}
export function setUseCleanStream(stationId: number, useCleanStream: boolean): ActionSetUseCleanStream {
    var payload = {}
    payload[stationId.toString()] = useCleanStream
    return {
        type: ACTION_SET_USE_CLEAN_STREAM,
        useCleanStream: payload
    }
}
export function setVolume(volume: number): ActionSetVolume {
    return {
        type: ACTION_SET_VOLUME,
        volume
    }
}


export default function PlayerReducer(state = initialState, action: Actions): PlayerReduxState {
    switch (action.type) {
        case ACTION_SET_PLAYBACK_STATE: {
            if (state.playbackState !== action.playbackState) {
                return {
                    ...state,
                    playbackState: action.playbackState
                }
            }
            break
        }
        case ACTION_SET_CURRENT_STATION: {
            const setCurrentStationAction = action as ActionSetCurrentStation
            return {
                ...state,
                currentStation: setCurrentStationAction.currentStation,
                ...(setCurrentStationAction.playbackState && { playbackState: setCurrentStationAction.playbackState }),
                referrer: setCurrentStationAction.referrer || Referrer.ALL,
                referrerId: setCurrentStationAction.referrerId || undefined,
            }
        }
        case ACTION_SET_USE_CLEAN_STREAM: {
            var useCleanStreamStateFromAction = action.useCleanStream || {}
            var useCleanStreamState = state.useCleanStream || {}

            return {
                ...state,
                useCleanStream: { ...useCleanStreamState, ...useCleanStreamStateFromAction }
            }
        }
        case ACTION_SET_VOLUME: {
            if (state.volumeLevel !== action.volume) {
                return {
                    ...state,
                    volumeLevel: action.volume
                }
            }
            break
        }
    }
    return state
}

export const PlayerIdleSelector = (state: ReduxPlayerState) => {
    return state.player.playbackState === PlaybackState.IDLE
}
export const PlayingSelector = (state: ReduxPlayerState) => {
    switch (state.player.playbackState) {
        case PlaybackState.BUFFERING:
        case PlaybackState.LOADING:
        case PlaybackState.PLAYING:
            return true
    }
    return false
}

export const ReferrerSelector = (state: ReduxPlayerState) => {
    return state.player.referrer || Referrer.ALL
}

export const AccentColorSelector = (state: ReduxPlayerState) => {
    return state.player.currentStation && state.player.currentStation.genreObj && state.player.currentStation.genreObj.genre_accent_color
}

export const UseCleanStreamSelector = (state: ReduxPlayerState) => {
    return (state.player.currentStation && Boolean(state.player.useCleanStream[state.player.currentStation.id.toString()])) || false
}

export const HasCleanStreamSelector = (state: ReduxPlayerState) => {
    return (state.player.currentStation && Boolean(state.player.currentStation.clean_stream_url)) || false
}

export const StreamUrlSelector = (state: ReduxPlayerState) => {
    const station = state.player.currentStation
    var url = station && station.stream_url
    var urlClean = station && station.clean_stream_url
    if (UseCleanStreamSelector(state) && Boolean(urlClean)) {
        url = urlClean
        url = url!.replace("http:", window.location.protocol)
    }
    if (url && url.endsWith('.m3u')) {
        url = url.substr(0, url.length - 4)
        url = url.replace("http:", window.location.protocol)
    }
    return url || ""
}
