import React from "react";
import { useDispatch } from "react-redux";
import { Station } from "dash-dashradio-app-client-api";
import { setCurrentStation, PlaybackState, Referrer } from "../../../reducers/PlayerReducer";
import StationDetailed from "../../../pages/StationDetailed/StationDetailed";

export const PlayStationHandler: React.FC<{ station: Station }> = props => {
    const dispatch = useDispatch();
    if (props.station) {
        const genreId = props.station.genreObj && props.station.genreObj.id;
        dispatch(
            setCurrentStation(
                props.station,
                PlaybackState.PLAYING,
                Boolean(genreId) ? Referrer.GENRE : Referrer.ALL,
                genreId
            )
        );
    }

    // return <Redirect to={Page.GENRES} />;
    return <StationDetailed station={props.station} />
};