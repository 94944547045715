import React, { useEffect, useState, useRef } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import AppPage from "../AppPage";
import Headline from "../../components/views/Headline";
import GenresSlider from "../genre/GenresSlider";
import Spacer from "../../components/ui-elements/Spacer";
import HighlightItem from "../../components/HighlightItem";
import {
	Page, navigationEventEmitter, EVENT_GENRE,
} from "../../components/AppDrawer";
import {
	Highlight,
	HighlightsAPI,
	Station,
} from "dash-dashradio-app-client-api";
import { session } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import {
	setCurrentStation,
	PlaybackState,
	Referrer
} from "../../reducers/PlayerReducer";
import PlayerStoreProvider from "../../PlayerStore";
import { ReduxAppState } from "../../reducers";
import { updateHighlights } from "../../reducers/HighlightsReducer";
import { DashThemeLight } from "../../Themes";
import StationItem from "../../components/StationItem";
import { FeaturedStationsSelector } from "../../reducers/FeaturedStationsReducer";
import { useCookies } from "react-cookie";
import ChangePasswordDialog from "../../components/ChangePassword";

import LinkAccountDialog from "../../components/LinkAccount";
import { updateSettings, IsSideBarOpenSelector } from '../../reducers/LocalSettingsReducer';
import StationsPage from "../StationsPage";
import DashStorySection from "./DashStorySection";
import ListenEveryWhereBanner from '../apps/ListenEveryWhereBanner';
import HomeTeaserNew from "./HomeTeaserNew";
import { setPageActive, goToTop } from "../../components/views/AppDrawerComponents/Functions";
import Ticker from "react-ticker";
import PageVisibility from 'react-page-visibility'
import Carousel from "react-elastic-carousel";

export const HomeHighlightsPage: React.FC<{
	token?: string;
	link?: boolean;
	errorTwitter?: string;
}> = props => {
	const dispatch = useDispatch();
	const highlights = useSelector(
		(state: ReduxAppState) => state.highlights.highlights
	);
	const featuredStations = useSelector(FeaturedStationsSelector);
	const [cookies,] = useCookies(["dashUser"]);
	const [, handleOpen] = useState(true);
	const [openResetPassword, setOpenResetPassword] = useState(false);
	const [openLinkAccount, setOpenLinkAccount] = useState(false);
	const [token, setToken] = useState<string>();
	const [logoSlider, setLogoSlider] = useState(false);
	const isSideBarOpen = useSelector(IsSideBarOpenSelector);


	const featuredStationSection: any = useRef(null)

	// const smallScreen = useMediaQuery("(max-width: 1280px)");
	const smallScreen = useMediaQuery("(max-width: 960px)");
	const mobileScreen = useMediaQuery('(max-width: 480px)')
	const smallTablet = useMediaQuery("(max-width: 950px)");



	const setSideBarOpen = (open: boolean) => {
		dispatch(updateSettings({ sideBar: open }))
	}

	useEffect(() => {
		if (isSideBarOpen) {
			setSideBarOpen(false)
		}
	})

	useEffect(() => {
		goToTop()
	}, [])


	useEffect(() => {
		HighlightsAPI.getHighlights(session)()
			.then(highlights => {
				highlights.sort((a, b) => {
					return a.priority < b.priority ? 1 : -1;
				})
				dispatch(updateHighlights(highlights))
			})
			.catch(err => {
				console.log("error", err);
			});
	}, [dispatch]);

	const handleResetPasswordClose = () => {
		setOpenResetPassword(false);
		setOpenLinkAccount(false);
		setPageActive(Page.HOME);
	};

	useEffect(() => {
		if (props.token) {
			var split = props.token.split("=");
			var token = split[split.length - 1];
			setToken(token);
			setOpenResetPassword(true);
		}
	}, [props.token]);

	useEffect(() => {
		if (props.errorTwitter) {
			// setErrorTwitter(true)
		}
	})

	useEffect(() => {
		if (props.link) {
			setOpenLinkAccount(true);
		}
	}, [props.link]);

	// useEffect(() => {
	//   StationsAPI.getFeaturedStations(session)()
	//     .then(featured => {
	//       if (featured && Array.isArray && Array.isArray(featured)) {
	//         dispatch(updateFeaturedStations(featured));
	//       }
	//     })
	//     .catch(err => console.error("featured stations", err));
	// }, [dispatch]);

	useEffect(() => {
		if (cookies.dashUser) {
			handleOpen(false);
		}
		// removeCookie('dashUser')
	}, [cookies.dashUser]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!logoSlider) {
				setLogoSlider(true)
			}
		}, 500);
		return () => clearTimeout(timer)
	})

	return (
		<React.Fragment>
			<ChangePasswordDialog
				open={openResetPassword}
				onClose={() => handleResetPasswordClose()}
				token={token}
			/>

			<LinkAccountDialog
				open={openLinkAccount}
				onClose={() => handleResetPasswordClose()}
			/>

			<AppPage withLogo home>
				<Grid container direction="row" justifyContent="center">
					<Grid item xs={12} sm={12}>
						{/* <HomeTeaserNew home /> */}

						<DashStorySection />

						<PlayerStoreProvider>
							<DashThemeLight>
								<HighlightsGridList data={highlights} smallScreen={smallScreen} smallTablet={smallTablet} mobileScreen={mobileScreen} />
							</DashThemeLight>
						</PlayerStoreProvider>

						<Spacer size={16} />
						<span ref={featuredStationSection} />
						{featuredStations && featuredStations.length > 0 && (
							<FeaturedStations stations={featuredStations} />
						)}

						<Spacer size={64} />

						<GenresSlider
							onGenreClick={genre => {
								if (genre && genre.id) {
									setPageActive(Page.GENRES, false, { genre: genre.id })
									navigationEventEmitter.emit(EVENT_GENRE, genre.id)
								} else {
									//All Genre Button
									setPageActive(Page.GENRES, false, { genre: 'allstations' })
								};
							}}
						/>

						<Spacer size={16} />

						<ListenEveryWhereBanner smallScreen={smallScreen} smallTablet={smallTablet} mobileScreen={mobileScreen} />

						{/* <Spacer size={64} />
            <YoutubeSection /> */}

						{/* <Spacer size={64} />
            <DASection /> */}
						<Spacer size={32} />


						<StationsPage />

						<Spacer size={72} />

					</Grid>
					{/* <Hidden smDown>
            <Grid item sm={1}></Grid>
          </Hidden> */}
				</Grid>
			</AppPage>
		</React.Fragment>
	);
};

export const HighlightsGridList: React.FC<{ data: Highlight[], smallScreen?: boolean, smallTablet?: boolean, mobileScreen?: boolean }> = props => {

	const dispatch = useDispatch();
	const [pageIsVisible, setPageIsVisible] = useState(true)
	const [waitingForPage, setWaitingForPage] = useState(false)
	const smallScreen = useMediaQuery('(max-width: 960px)');
	const mobileScreen = useMediaQuery('(max-width: 480px)')

	const handleVisbilityChange = (isVisible) => {
		setPageIsVisible(isVisible)
	}
	useEffect(() => {
		const timer = setTimeout(() => {
			setWaitingForPage(true)
		}, 2000);
		return () => clearTimeout(timer)
	}, [])

	return (
		<div style={{ marginBottom: 16 }}>
			<PageVisibility onChange={handleVisbilityChange}>
				{pageIsVisible && waitingForPage && props.data.length > 0 && (
					<Ticker height={(smallScreen && !mobileScreen ? (257 / 1.78) : (mobileScreen ? (208 / 1.78) : (401 / 1.78)))}>
						{({ index }) => (
							<div style={{ width: (smallScreen && !mobileScreen ? 257 : (mobileScreen ? 208 : 401)), height: '100%' }}>
								<HighlightItem
									onClick={() => {
										const data = props.data[index % props.data.length]
										if (data.linked_url) {
											window.open(data.linked_url, "_blank");
										} else if (data.linked_station_id) {
											dispatch(
												setCurrentStation(
													data.stationObj,
													PlaybackState.PLAYING,
													Referrer.HIGHLIGHTS
												)
											);
										}
									}}
									image={props.data[index % props.data.length].image_url || ''}
								/>
							</div>
						)}
					</Ticker>
				)}
			</PageVisibility>
		</div>
	);
};

export const FeaturedStations: React.FC<{ stations: Station[], style?: any }> = props => {
	const firstBreakpoint = useMediaQuery('(min-width: 1500px)');
	const secondBreakpoint = useMediaQuery('(min-width: 1170px)');

	const smallScreen = useMediaQuery("(max-width: 960px)");
	const mobileScreen = useMediaQuery('(max-width: 640px)')

	return (
		<div style={props.style}>
			{/* <Spacer size={64} /> */}
			<Headline
				center
				title={"FEATURED STATIONS"}
			// subtitle={"Always Free. No commercials."}
			/>

			<Spacer size={16} />

			{!smallScreen && (
				<Carousel isRTL={false} itemsToShow={firstBreakpoint ? 4 : (secondBreakpoint ? 3 : 2)}>
					{props.stations.map((item, index) => {
						return (
							<StationItem
								key={item.id}
								station={item}
								referrerInfo={{ referrer: Referrer.FEATURED }}
							/>
						)
					})}
				</Carousel>)
			}

			{smallScreen && (
				<Grid
					container
					spacing={4}
					justifyContent="center"
					alignItems="center"
					alignContent="center"
				>
					{props.stations &&
						props.stations.map(station => {
							return (
								<StationItem
									key={station.id}
									station={station}
									referrerInfo={{ referrer: Referrer.FEATURED }}
								/>
							);
						})}
				</Grid>
			)}

			<Spacer size={96} />
			<div style={{ ...((smallScreen || mobileScreen) && { paddingLeft: 16, paddingRight: 16 }) }}>
				<Headline style={{ cursor: 'pointer' }} onClick={() => setPageActive(Page.RADIO)} center title={'BROWSE STATIONS'} />
			</div>
		</div>
	);
};

export default React.memo(HomeHighlightsPage);
