import React, { useRef } from 'react';
import { Grid, useTheme } from '@mui/material';
import classnames from 'classnames'
import { ICON_CHEVRON_LEFT, ICON_CHEVRON_RIGHT } from '../../Icons';
import classes from "./HorizontalGridList.module.css";

const SingleLineGridList: React.FC<{ data: any[], renderItem: (item: any) => React.ReactNode, style?: any }> = (props) => {
  const { data, renderItem } = props
  const grid = useRef(null)
  const theme = useTheme();

  const scrollList = (forward: boolean) => {
    if (grid && grid.current) {
      const offset = 300
      const htmlElement = grid.current as unknown as HTMLElement;
      //htmlElement.scrollLeft = htmlElement.scrollLeft + offset
      scrollElements(htmlElement, forward ? offset : -offset, 100)
    }
  }

  var scrollRequestCode = 0;
  const scrollElements = (element, offset, duration) => {
    var start = element.scrollLeft;
    var currentTime = 0;
    var increment = 20;

    //console.log(start)

    let _requestCode = ++scrollRequestCode;
    var animateScroll = () => {
      if (_requestCode !== scrollRequestCode) {
        return;
      }

      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, offset, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  const easeInOutQuad = (currentTime, startValue, offset, duration) => {		//https://stackoverflow.com/questions/52747018/horizontal-scrolling-using-buttons-in-reactjs
    currentTime /= duration / 2;
    if (currentTime < 1) {
      return offset / 2 * currentTime * currentTime + startValue;
    }
    currentTime--;
    return -offset / 2 * (currentTime * (currentTime - 2) - 1) + startValue;
  }

  return (
    <div className={classes.root}>
      {data.length > 3 ?
        <div className={classes.chevronContainer}>
          <img src={ICON_CHEVRON_LEFT} alt="" className={classes.chevron} onClick={() => scrollList(false)} />
        </div>
        :
        <></>
      }
      <div className={classes.gridListContainer} style={{backgroundColor: theme.palette.background.paper}}>
        <Grid ref={grid} container className={classnames(classes.gridList, classes.scrollX)} spacing={4} justifyContent="flex-start" alignItems="center" alignContent="center" style={props.style}>
          {data.map((item, index) => (
            renderItem(item)
          ))}
        </Grid>
      </div>
      {data.length > 3 ?
        <div className={classes.chevronContainer}>
          <img src={ICON_CHEVRON_RIGHT} alt="" className={classes.chevron} onClick={() => scrollList(true)} />
        </div> :
        <></>
      }
    </div>
  );
}
export default SingleLineGridList
