import React from 'react';
import './loginbutton.scss';
import { connect } from "react-redux";
import * as Icons from "../../../../Icons";
import DrawerItem from '../DrawerItem';
import LoginDialogModal from '../../../LoginDialog';
import SignupDialogModal from '../../../SignupDialog';
import ForgotPasswordDialogModal from '../../../ForgotPasswordDialog';
import LoggedInModal from '../../../LoggedInDialog';
import { ReduxAppState } from '../../../../reducers';
import { IconButton } from '@mui/material';
import Content from '../../../ui-elements/Content';
import Center from '../../../ui-elements/Center';
import { User } from 'dash-dashradio-app-client-api';
import { setSession, clearSession } from '../../../../reducers/LoginReducer';

interface LoginButtonProps {
    smallDrawer?: boolean,
    topBar?: boolean,
    mobileScreen?: boolean,
    currentUser?: User
}

interface LoginButtonState {
    loginDialogOpen: boolean,
    signupDialogOpen: boolean,
    forgotPasswordDialogOpen: boolean,
    loggedInDialogOpen: boolean,
    loggedIn: boolean,
    currentUser?: User
}


const mapStateToProps = (state: ReduxAppState) => ({
    currentUser: state.login.user
})

const mapDispatchToProps = () => {
    return {
        setSession, clearSession
    }
}

class LoginButton extends React.Component<LoginButtonProps, LoginButtonState>{

    constructor(props: any) {
        super(props)

        this.state = {
            loginDialogOpen: false,
            signupDialogOpen: false,
            forgotPasswordDialogOpen: false,
            loggedInDialogOpen: false,
            loggedIn: false,
            currentUser: this.props.currentUser
        }
    }

    private loggedInButton(open: boolean) {
        this.setState({
            loggedInDialogOpen: open
        })
    }

    private logInButton(open: boolean) {
        this.setState({
            loginDialogOpen: open
        })
    }

    private handleLoggedInClose(logout?: boolean) {
        this.setState({
            loggedInDialogOpen: false,
            loggedIn: logout ? false : true,
            currentUser: logout ? undefined : this.props.currentUser
        })
    }

    private handleLoginClose() {
        this.setState({
            loginDialogOpen: false,
            loggedIn: Boolean(this.props.currentUser),
            currentUser: this.props.currentUser
        })
    }

    componentDidMount() {
        this.setState({
            loggedIn: Boolean(this.props.currentUser),
            currentUser: this.props.currentUser
        })
    }
    componentDidUpdate(prevProps: Readonly<LoginButtonProps>, prevState: Readonly<LoginButtonState>) {
        if(prevProps.currentUser !== this.props.currentUser) {
            this.setState({
                loggedIn: Boolean(this.props.currentUser),
                currentUser: this.props.currentUser
            })
        }
    }

    render() {
        return (
            <div>
                {(this.props.smallDrawer && (
                    <>
                        <Content icons>
                            <IconButton
                                className='login-button'
                                onClick={() => {
                                    this.state.loggedIn ? this.loggedInButton(!this.state.loggedInDialogOpen) : this.logInButton(true)
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <img
                                    alt=""
                                    src={Icons.ICON_PROFIL}
                                    style={{
                                        width: 16,
                                        height: 16,
                                        objectFit: "contain",
                                        opacity: this.state.loggedIn ? 1 : 0.65
                                    }}
                                />
                            </IconButton>
                            <Center>
                            </Center>
                        </Content>
                    </>
                )) || (
                        <>
                            <DrawerItem
                                active={false}
                                signIn={this.state.loggedIn}
                                onClick={() => {
                                    this.state.loggedIn ? this.loggedInButton(!this.state.loggedInDialogOpen) : this.logInButton(true);
                                }}
                                signInButton={true}
                            >
                                <div
                                    className="sign-in-container"
                                >
                                    <div style={{ flex: 1, ...(this.props.topBar && { fontSize: '1.125rem' }), maxWidth: 120, textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {this.state.loggedIn ? this.state.currentUser && `${this.state.currentUser.first_name} ${this.state.currentUser.last_name}` : "Sign In/Sign Up"}
                                    </div>
                                </div>
                            </DrawerItem>
                        </>
                    )}
                <LoginDialogModal
                    open={this.state.loginDialogOpen}
                    onClose={() => this.handleLoginClose()}
                    onSignupClick={() => {
                        this.setState({
                            loginDialogOpen: false,
                            signupDialogOpen: true
                        })
                    }}
                    onNoAccountWithEmail={input => {
                        // setAlertDialogEmail(input.email);
                    }}
                    onForgotPasswordClick={() => {
                        this.setState({
                            loggedInDialogOpen: false,
                            forgotPasswordDialogOpen: true
                        })
                    }}
                />
                <SignupDialogModal
                    open={this.state.signupDialogOpen}
                    onClose={() => {
                        this.handleLoginClose();
                        this.setState({
                            signupDialogOpen: false
                        })
                    }}
                    onBack={() => {
                        this.setState({
                            loggedInDialogOpen: true,
                            signupDialogOpen: false
                        })
                    }}
                />
                <ForgotPasswordDialogModal
                    open={this.state.forgotPasswordDialogOpen}
                    onClose={() => {
                        this.setState({
                        forgotPasswordDialogOpen: false
                    })}}

                    onBack={() => {
                        this.setState({
                            loggedInDialogOpen: false,
                            loginDialogOpen: false,
                            forgotPasswordDialogOpen: false
                        })
                    }}
                />
                <LoggedInModal open={this.state.loggedInDialogOpen} onClose={() => this.setState({ loggedInDialogOpen: false })} onChoose={(loggedOut?: boolean) => this.handleLoggedInClose(loggedOut)} />
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton)
