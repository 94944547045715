import React from 'react';
import { CombinedState, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import { Provider, RootStateOrAny } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from './reducers'

const persistConfig = {
  key: 'com.dashradio.dash:store:root',
  storage,
  stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer<RootStateOrAny>(persistConfig, rootReducer)

export const createGlobalStore = () => {
  let store = createStore(persistedReducer)
  let persistor = persistStore(store)
  return { store, persistor }
}

const { store, persistor } = createGlobalStore()

export const PersistentReduxStoreProvider: React.FC<{children?: React.ReactNode}> = (props) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            {
                props.children
            }
            </PersistGate>
        </Provider>
    );
}

export default PersistentReduxStoreProvider
