import React from 'react'
import { CircularProgress } from '@mui/material';
import { IMG_DEFAULT_COVER } from '../Icons';
import {Img} from 'react-image'
import classes from "./HighlightItem.module.css";

interface Props {
    title?: string,
    description?: string,
    image: string,
    onClick?: () => void,
}

const HighlightItem: React.FC<Props> = (props) => {

    return (
        <div className={classes.clickable}>
            <Img
                src={[props.image, IMG_DEFAULT_COVER]}
                loader={<div className={classes.centerStyle}><CircularProgress color="primary" /></div>}
                className={classes.imageStyle}
                alt="" />
            <div className={classes.clickOverlay} onClick={props.onClick} />
        </div>
    )
}
export default HighlightItem
