import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface Props {
    open: boolean,
    setOpen: (open: boolean)=>void,
    title: string,
    message: string,

    buttonYesTitle?: string,
    buttonNoTitle?: string,
    buttonYesClick?: ()=>void,
    buttonNoClick?: ()=>void,
    okayButton?: boolean,
}

const AlertDialog: React.FC<Props> = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery('(max-width: 600px)');

    // function handleClickOpen() {
    //     props.setOpen(true);
    // }

    function handleClose() {
        props.setOpen(false);
    }

    return (
        <Dialog
            fullScreen={smallScreen ? false : fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
			aria-describedby="alert-dialog-description" >
            {/* style={{left: smallScreen ? '5vw' : 'undefined',
            top: smallScreen ? '5vh' : 'undefined', width: smallScreen ? '90vw' : 'undefined', height: smallScreen ? '20vh' : 'undefined'}} */}
            <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
            <DialogContent id="alert-dialog-description">
                <DialogContentText>
                {
                    props.message
                }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    !Boolean(props.okayButton) &&
                    <Button onClick={()=>{
                        handleClose()
                        props.buttonNoClick && props.buttonNoClick()
                    }} color="primary">
                    {
                        props.buttonNoTitle || "No"
                    }
                    </Button>
                }
                <Button onClick={()=>{
                    handleClose()
                    props.buttonYesClick && props.buttonYesClick()
                }} color="primary" autoFocus>
                {
                    props.buttonYesTitle || (Boolean(props.okayButton) ? "Okay" : "Yes")
                }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog

export interface AlertDialogProps {
    title?: string,
    message?: string,
    onOkayClick?: ()=>void,
    onDeclineClick?: ()=>void,
    okayText?: string,
    declineText?: string
}

export const RenderedAlertDialog: React.FC<AlertDialogProps & {onClose: ()=>void}> = (props) => {
	return (
		<Dialog
			open={true}
			onClose={()=>props.onClose()}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">{props.title || "Alert"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{props.message || ""}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{
					props.onOkayClick &&
					<Button onClick={()=>{
						props.onClose()
						props.onDeclineClick && props.onDeclineClick();
					}} color="primary">{props.declineText || "Decline"}</Button>
				}
				<Button onClick={()=>{
					props.onClose()
					props.onOkayClick && props.onOkayClick();
				}} color="primary" autoFocus>{props.okayText || "Okay"}</Button>
			</DialogActions>
		</Dialog>
	)
}
