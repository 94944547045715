import React, { useState, useEffect } from 'react';
import {useMediaQuery, Grid, ImageListItemBar, ImageListItem} from '@mui/material';
import { ICON_CHEVRON_LEFT, ICON_CHEVRON_RIGHT } from '../Icons';
import AppTextBold from '../components/views/AppTextBold';
import {Img} from 'react-image'
import HorizontalGridList from '../components/ui-elements/HorizontalGridList';
import Spacer from '../components/ui-elements/Spacer';
import AspectRatioView from '../components/ui-elements/AspectRatioView';
import LoadingImage from './LoadingImage';
import InfiniteCarousel from 'react-leaf-carousel';
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'
import classes from "./PlatformSection.module.css";

interface PlatformItem {
    title?: string,
    icon: any,
    maxHeight?: string
}

const PlatformSection: React.FC<{ items: PlatformItem[], title?: string, background?: any, apps?: boolean, logoSlider?: boolean, appSide?: boolean, style?: React.CSSProperties, inYourCar?: boolean }> = (props) => {
    const { title, items, background, apps = false, logoSlider = false, appSide, style } = props
    const smallScreen = useMediaQuery('(max-width: 415px)')
    const [pageIsVisible, setPageIsVisible] = useState(true)
    const [waitingForPage, setWaitingForPage] = useState(false)
    const tabletScreen = useMediaQuery('(max-width: 960px)')

    const handleVisbilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setWaitingForPage(true)
        }, 2000);
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            {!logoSlider && <Spacer size={8} />}
            {title && logoSlider && <AppTextBold centerText>{title}</AppTextBold>}
            {apps && !logoSlider && <AppTextBold centerText>{title}</AppTextBold>}
            {!apps && !logoSlider && <AppTextBold centerText>{title}</AppTextBold>}
            <div className={classes.container} style={style}>
                {apps &&
                    <div className={classes.itemsContainer}>
                        {
                            smallScreen && !logoSlider &&
                            <HorizontalGridList data={items} renderItem={(item: PlatformItem) => {
                                return (
                                    <ImageListItem key={`GridTitle_${item.icon}`} className={classes.gridListTile}>
                                        <LoadingImage url={item.icon} />
                                        <ImageListItemBar title={item.title} classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                            titleWrap: classes.titleWrap
                                        }} />
                                    </ImageListItem>
                                )
                            }} />
                        }
                        {
                            logoSlider &&
                            <PageVisibility onChange={handleVisbilityChange}>
                                {pageIsVisible && waitingForPage && (
                                    items.length <= 3
                                        ?
                                        <HorizontalGridList data={items} style={{ display: 'flex', justifyContent: 'center', margin: 'auto', overflow: 'hidden' }} renderItem={(item: PlatformItem) => {
                                            return (
                                                <div key={`item-platform_${item.icon}`} style={{ width: props.inYourCar ? (tabletScreen ? '90px' : '96px') : '6rem', height: props.inYourCar ? (tabletScreen ? '90px' : '96px') : '6rem', marginRight: props.inYourCar ? 30 : '1rem' }}>
                                                    <LoadingImage url={item.icon} style={{ objectFit: 'cover' }} />
                                                </div>
                                            )
                                        }} />
                                        :
                                        <Ticker height={props.inYourCar ? 100 : (tabletScreen ? 59 : 55)}>
                                            {({ index }) => (
                                                <div style={{ width: props.inYourCar ? (tabletScreen ? '90px' : '96px') : '40px', height: props.inYourCar ? (tabletScreen ? '90px' : '96px') : '100%', marginRight: 30 }}>
                                                    <LoadingImage url={items && items[index % items.length] && items[index % items.length].icon} style={{ objectFit: 'contain' }} />
                                                </div>
                                            )}
                                        </Ticker>
                                    // <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', maxWidth: '400px' }}>
                                    //     <div id="marquee1" className="marquee-image" style={{ width: '100%', height: '100%', maxWidth: '400px' }}>
                                    //         {items.map((item, index) => {
                                    //             return (
                                    //                 <img src={item.icon} style={{ objectFit: 'contain', height: '100%', width: '40px' }} />
                                    //             )
                                    //         })}
                                    //     </div>
                                    // </div>
                                )}
                            </PageVisibility>

                        }
                        {!smallScreen && !logoSlider &&
                            <InfiniteCarousel breakpoints={[
                                {
                                    breakpoint: 580,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 2,
                                    },
                                },
                                {
                                    breakpoint: 730,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 3,
                                    },
                                },
                            ]}
                                prevArrow={
                                    <div className={classes.chevronContainer}>
                                        <img src={ICON_CHEVRON_LEFT} alt="" className={classes.chevron} />
                                    </div>
                                }
                                nextArrow={
                                    <div className={classes.chevronContainer}>
                                        <img src={ICON_CHEVRON_RIGHT} alt="" className={classes.chevron} />
                                    </div>
                                }
                                autoCycle={true}
                                responsive={true}
                                cycleInterval={5000}
                                arrows={true}
                                dots={false}
                                showSides={true}
                                sidesOpacity={.5}
                                sideSize={.1}
                                slidesToScroll={3}
                                slidesToShow={3}
                                scrollOnDevice={true}>
                                {items.map((item, index) => {
                                    return (
                                        <AspectRatioView w={16} h={9} key={index}>
                                            <div className={classes.appsBox} key={index} style={{ width: '100%' }}>
                                                <LoadingImage url={item.icon} />
                                                <ImageListItemBar title={item.title} classes={{
                                                    root: classes.titleBar,
                                                    title: classes.title,
                                                    titleWrap: classes.titleWrap
                                                }} />
                                            </div>
                                        </AspectRatioView>
                                    )
                                })}
                            </InfiniteCarousel>
                        }
                    </div>}
                {!apps &&
                    <div className={classes.itemsContainer}>
                        <Img src={background} alt="" className={classes.backgroundImage} />
                        {
                            smallScreen &&
                            <Grid container justifyContent="flex-start" alignItems="center">
                                {
                                    items && items.map((item, index) => (
                                        <Grid item spacing={8} key={index} style={{ padding: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={4}>
                                            <Img src={item.icon} alt={item.title} className={classes.logoImage} style={{ maxHeight: item.maxHeight }} />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        }
                        {
                            !smallScreen && !appSide &&
                            <InfiniteCarousel breakpoints={[
                                {
                                    breakpoint: 500,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                    },
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                    },
                                },
                            ]}
                                prevArrow={
                                    <div className={classes.chevronContainer}>
                                        <img src={ICON_CHEVRON_LEFT} alt="" className={classes.chevron} />
                                    </div>
                                }
                                nextArrow={
                                    <div className={classes.chevronContainer}>
                                        <img src={ICON_CHEVRON_RIGHT} alt="" className={classes.chevron} />
                                    </div>
                                }
                                autoCycle={true}
                                cycleInterval={3000}
                                arrows={false}
                                dots={false}
                                showSides={true}
                                sidesOpacity={.5}
                                sideSize={.1}
                                slidesToScroll={3}
                                slidesToShow={3}
                                scrollOnDevice={true}>
                                {items.map((item, index) => {
                                    return (
                                        <div className={classes.centerBox} key={index}>
                                            <Img src={item.icon} alt={item.title} className={classes.logoImage} />
                                        </div>
                                    )
                                })}
                            </InfiniteCarousel>
                        }
                        {appSide && !smallScreen &&
                            <Grid container justifyContent="flex-start" alignItems="center">
                                {
                                    items && items.map((item, index) => (
                                        <Grid item key={index} style={{ padding: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem' }} xs={4}>
                                            <Img src={item.icon} alt={item.title} className={classes.logoImage} style={{ maxHeight: item.maxHeight }} />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        }
                    </div>}
            </div>
        </>
    )
}

export default PlatformSection
