import React, { useEffect, useState, useRef } from 'react';
import { StationsAPI, Station, SchedulesAPI, Schedules } from 'dash-dashradio-app-client-api';
import { useSelector, useDispatch } from 'react-redux'
import { ReduxAppState, ReduxPlayerState } from '../../reducers';
import { updateAllStations, GenresSelector, AllStationsSelector } from '../../reducers/AllStationsReducer';
import { session } from '../../App';
import Headline from '../../components/views/Headline';
import moment from 'moment';
import './guide.scss';
import GuideStationItem from './GuideStationItem';
import GenresSlider from '../genre/GenresSlider';
import Spacer from '../../components/ui-elements/Spacer';
import { StickyContainer, Sticky } from 'react-sticky';
import { Page } from '../../components/AppDrawer';
import { useMediaQuery } from '@mui/material';
import GuideProgramm from './GuideProgramm';
import GuideProgrammBreak from './GuideProgrammBreak';
import { setPageActive } from '../../components/views/AppDrawerComponents/Functions';


const GuidePage: React.FC = (props) => {
    //Stunden die gerendert werden
    const hours = 72
    //Der aktuelle Tag, beginnend um 0.00 Uhr
    const currentDay = moment().startOf('day')
    //Die aktuelle Stunde, abgerundet auf die volle Stunde
    const currentTime = moment().startOf('hour')
    //Die Breite einer 'Stunde'
    const cellWidth = 350
    let scrollView: HTMLDivElement | null = null;

    const dispatch = useDispatch()
    const [day, setDay] = useState(currentDay)
    const [filteredStations, setFilteredStations] = useState<Station[]>()
    const [allSchedules, setAllSchedules] = useState<Schedules[] | undefined>(undefined)
    const genresSel = useSelector(GenresSelector)
    const allStations: Station[] = useSelector(AllStationsSelector)
    const actualState = useSelector((state: ReduxAppState) => state)
    const smallScreen = useMediaQuery("(max-width: 960px)");
    const mobileScreen = useMediaQuery('(max-width: 480px)')
    const [actualWidth, setActualWidth] = useState(0)

    // useEffect(() => {
    //     StationsAPI.getStations(session)().then(allStations => {
    //         setFilteredStations([...allStations].sort((a, b) => (Number(a['genreNumber'] + a['stationNumber']) - Number(b['genreNumber'] + b['stationNumber']))))
    //         allStations && dispatch(updateAllStations(allStations))
    //     })
    //         .catch(err => {
    //         })
    // }, [dispatch])

    useEffect(() => {
        setFilteredStations([...allStations].sort((a, b) => (Number(a['genreNumber'] + a['stationNumber']) - Number(b['genreNumber'] + b['stationNumber']))))
    }, [allStations])

    useEffect(() => {
        SchedulesAPI.getAllSchedules(session)().then(allSchedules => {
            setAllSchedules(allSchedules)
        }).catch(err => {
        })
    }, [])

    useEffect(() => {
        let left = ((currentTime as any) - (currentDay as any)) / 1000 / 3600 * cellWidth
        if (scrollView)
            scrollView.scrollLeft = left;
    }, [])

    useEffect(() => {
        const listener = () => {
            let width = window.innerWidth - (window.innerWidth * 0.16667)
            setActualWidth(width)
        }

        window.addEventListener('resize', listener)
        return () => {
            window.removeEventListener('resize', listener)
        }
    })

    useEffect(() => {
        let width = window.innerWidth - (window.innerWidth * 0.16667)
        setActualWidth(width)
    }, [])

    const vh = function (v: number) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }

    return (
        <React.Fragment>
            <Spacer size={32} />
            {/* <Headline title="Genres" center /> */}
            <GenresSlider
                onGenreClick={(genre) => {
                    if (genre && genre.id) {
                        const el = document.getElementById(`${genre.id}`)
                        el && window.scrollTo({
                            behavior: 'smooth',
                            left: 0,
                            top: el.offsetTop - 50,
                        })
                    } else {
                        const section = document.querySelector( '#allstations');
                        if(section)
                            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                        setPageActive(Page.ALL_STATIONS)
                    }

                }} />
            <div className="guide-container">
                <div className="fixed-cell">
                    {/* <div className="headline-text" id='header'>{getDay(day)}</div> */}
                    {genresSel && genresSel.map((genre, index) => {
                        return (
                            <div className="left-side" id={String(genre.id)} key={`GuideStationItems_${genre.genre_name}`}>
                                <StickyContainer>
                                    {/* <Sticky topOffset={vh(HeaderHeightInVH) * -1} bottomOffset={80}> */}
                                    <Sticky topOffset={smallScreen ? 59 * -1 : 55 * -1} bottomOffset={80}>
                                        {({ style }) => {
                                            return <Headline id="guide-headline" guideHeader smallScreen={smallScreen} mobileScreen={mobileScreen} center={smallScreen ? true : false} title={genre.genre_name} className="genre-text" style={{
                                                ...style,
                                                // width: 'calc(86vw - 222px)',
                                                // width: '100vw',
                                                // width: 'calc(100vw - 8.33% - 8.33%)',
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                                width: actualWidth,
                                                // ...(mobileScreen && { display: 'flex', justifyContent: 'center' }),
                                                ...(style.top != undefined ? { top: (style.top as number) + (smallScreen ? 59 : 55) } : {})
                                            }} />
                                        }}
                                    </Sticky>
                                    {
                                        filteredStations && filteredStations.map(station => {
                                            if (genre.genre_name === station['genre']) {
                                                return (
                                                    <GuideStationItem station={station} key={station.id} />
                                                )
                                            }
                                        })
                                    }
                                </StickyContainer>
                            </div>
                        )
                    })
                    }
                </div>
                <div style={{ overflow: "hidden", overflowX: "scroll" }} ref={(element) => scrollView = element} onScroll={() => {
                    if (scrollView) {
                        calcDay(scrollView.scrollLeft)
                    }
                }}>
                    {/* <div className="timeline-container" id='header2' style={{ height: "30px", width: hours * cellWidth, marginBottom: '2rem' }} >
                        {getTimes()}
                    </div> */}
                    {genresSel && genresSel.map((genre, index) => {
                        return (
                            <div style={{ marginTop: '2rem' }} key={`ProgramContentGenre_${genre.genre_name}`}>
                                {/* <Headline title={genre.genre_name} className="genre-text" style={{ visibility: 'hidden', minWidth: '15rem' }} /> */}
                                <Headline id="guide-headline" guideHeader smallScreen={smallScreen} mobileScreen={mobileScreen} center={smallScreen ? true : false} title={genre.genre_name} className="genre-text" style={{
                                    // width: 'calc(86vw - 222px)',
                                    // width: '100vw',
                                    // width: 'calc(100vw - 8.33% - 8.33%)',
                                    // ...(smallScreen && { width: 'calc(100vw - 140%)' }),
                                    backgroundColor: '#fff',
                                    zIndex: 1,
                                    width: actualWidth,
                                    top: smallScreen ? 59 : 66,
                                    visibility: 'hidden'
                                }} />

                                <div>
                                    {filteredStations && filteredStations.map(station => {
                                        let stationColor = station.genreObj ? station.genreObj.genre_accent_color : '#000'
                                        let filteredSchedules = allSchedules && allSchedules.filter(schedule => schedule.program.station_id === station.id && moment(schedule.start_date).valueOf() < (moment(currentDay).add(hours / 24, 'day').valueOf() - 20 * 60 * 1000)).sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
                                        if (genre.genre_name === station['genre']) {
                                            return (
                                                <div key={`Program_Station_${station.id}`} className="timeline-container separator" style={{ width: hours * cellWidth, position: 'relative' }}>
                                                    {filteredSchedules && filteredSchedules.map(schedule => {
                                                        return (
                                                            // renderProgram(schedule, stationColor || "#000", station)
                                                            <GuideProgramm schedule={schedule} station={station} color={stationColor || "#000"} currentDay={currentDay} hours={hours} cellWidth={cellWidth} />
                                                        )
                                                    })}
                                                    {filteredSchedules && <GuideProgrammBreak schedules={filteredSchedules} station={station} stationColor={stationColor} currentDay={currentDay} hours={hours} cellWidth={cellWidth} />}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>)
                    })}
                </div>
            </div>
        </React.Fragment >
    )

    function getDay(day: moment.Moment) {
        if (day.isSame(currentDay, "day")) {
            return 'Today'
        } else {
            return day.format('MMM Do')
        }
    }


    //Rendert die Stunden in der Timeline
    function getTimes() {
        let times: JSX.Element[] = []
        for (let i = 0; i < hours; i++) {
            let next = moment(currentDay).add(i, 'hour')
            times.push(<div key={next.format('LLL')} className="time headline-text" style={{ minWidth: `${cellWidth}px` }}>{next.format('LT')}</div>)
        }
        return times
    }

    function calcDay(left: number) {
        if (left < nextDay(1)) {
            setDay(currentDay)
        } else if (left >= nextDay(1) && left < nextDay(2)) {
            setDay(moment(currentDay).add(1, 'day'))
        } else if (left >= nextDay(2) && left < nextDay(3)) {
            setDay(moment(currentDay).add(2, 'day'))
        } else if (left >= nextDay(3) && left < nextDay(4)) {
            setDay(moment(currentDay).add(3, 'day'))
        } else {
        }

    }

    function nextDay(addingDays: number) {
        let h = (moment(currentDay).add(addingDays, 'day').valueOf() - currentDay.valueOf()) / 1000 / 3600
        return h * cellWidth - cellWidth * 0.5
    }

    function renderProgram(schedule: Schedules, color: string, station: Station) {
        //Start des Programms
        let start = moment(schedule.start_date)/* .add(8, 'hour') */
        let end = moment(schedule.end_date)/* .add(8, 'hour') */
        //Berechnet die Größe der Spalte um diese exakt so groß zu machen, wie die Dauer des Programms, außer das Programm läuft aktuell,
        //dann wird die aktuelle Zeit als Startpunkt genommen.
        let t = ((moment(schedule.end_date).valueOf() > (moment(currentDay).add(hours / 24, 'day').valueOf() + 3600 * 1000) ? (moment(currentDay).add(hours / 24, 'day') as any) : (end as any)) - ((start as any) >= (currentDay as any) ? (start as any) : currentDay)) / 1000 / 3600 * cellWidth
        let left = ((start as any) - (currentDay as any)) / 1000 / 3600 * cellWidth
        return (
            <div key={`Program_${schedule.id}`} className="program" style={{ minWidth: t, maxWidth: t, left: left > 0 ? left : 0 }}>
                {t > cellWidth * 0.3 &&
                    <>
                        <div style={{ backgroundColor: color, width: "1px" }}></div>
                        <div className="program-content" style={{ backgroundColor: `${color}20` }}>
                            <span className="program-font" style={{ width: t - 21 }}>{schedule.program.name.replace(/\u00AE/g, "")}</span>
                            <span className="program-font" style={{ width: t - 21 }}>{schedule.program.description}</span>
                            <span className="program-font" style={{ width: t - 21 }}>{`${start.format("L")} ${start.format("LT")} - ${end.format("L")} ${end.format("LT")}`}</span>
                        </div>
                    </>}
            </div>
        )
    }

    function renderProgramBreak(schedules: Schedules[], station: Station, stationColor: string) {
        let l = schedules.length
        let progBreakInfo: {
            start_Date: moment.Moment,
            end_Date: moment.Moment
        }[] = []
        if (l === 0) {
            progBreakInfo.push({ start_Date: currentDay, end_Date: moment(currentDay).add(hours, 'hour') })
        } else {
            schedules.map((schedule, index) => {
                let start: moment.Moment;
                let end: moment.Moment;
                if (index === 0) {
                    start = currentDay
                } else {
                    start = moment(schedules[index - 1].end_date)
                }
                end = (moment(schedule.start_date) as any) < (currentDay as any) ? currentDay : moment(schedule.start_date)
                progBreakInfo.push({ start_Date: start, end_Date: end })
                if (index === l - 1) {
                    start = moment(schedule.end_date)
                    end = moment(currentDay).add(hours, 'hour')
                    progBreakInfo.push({ start_Date: start, end_Date: end })
                }
            })
        }

        return (
            progBreakInfo.map((info, index) => {
                let start = info.start_Date
                let end = info.end_Date
                let t = ((start.valueOf() > (moment(currentDay).add(hours / 24, 'day').valueOf() + 3600 * 1000) ? (moment(currentDay).add(hours / 24, 'day') as any) : (end as any)) - ((start as any) >= (currentDay as any) ? (start as any) : currentDay)) / 1000 / 3600 * cellWidth
                let left = ((start as any) - (currentDay as any)) / 1000 / 3600 * cellWidth
                return (
                    <div key={`Program_Break_${station.id}_${index}`} className="program" style={{ minWidth: t, maxWidth: t, left: left > 0 ? left : 0 }}>
                        {t > cellWidth * 0.3 &&
                            <>
                                <div style={{ backgroundColor: stationColor, width: "1px" }} className={schedules.length === 0 ? 'fixed-program left1' : ''}></div>
                                <div className="program-content" style={{ backgroundColor: "#A7A7AB1A", width: t }}>
                                    <span className={`program-font station-bold ${schedules.length === 0 ? 'fixed-program left11' : ''}`} style={{ width: schedules.length === 0 ? "max-content" : t - 21 }}>{station.name.replace(/\u00AE/g, "")}</span>
                                    <span className={`program-font ${schedules.length === 0 ? 'fixed-program left11' : ''}`} style={{ width: schedules.length === 0 ? "max-content" : t - 21 }}>{station.description}</span>
                                </div>
                            </>}
                    </div>
                )
            })
        )
    }
}

export default React.memo(GuidePage)
