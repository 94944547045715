import React, { useEffect } from "react";

import LoadingBox from "../components/LoadingBox";
import * as querystring from "querystring";
import AppPage from "./AppPage";
import { DashThemeLight } from "../Themes";
import { Page } from "../components/AppDrawer";
import { LoginAPI, PresetsAPI } from "dash-dashradio-app-client-api";
import { session } from "../App";
import { useDispatch } from 'react-redux'
import { setSession } from '../reducers/LoginReducer';
import { setPageActive } from "../components/views/AppDrawerComponents/Functions";
import { setFavorites } from "../reducers/AllStationsReducer";
import LoginHelper from "../helper/LoginHelper";


export const TwitterCallback: React.FC = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if (Boolean(window.location.search)) {
            try {
                const parsed = new URLSearchParams(window.location.search.slice(1))
                if (parsed.get('oauth_token') && parsed.get('oauth_verifier')) {
                    LoginAPI.twitterLogin(session)(parsed.get('oauth_token')!, parsed.get('oauth_verifier')!).then((data) => {
                        if (data && data.access_token) {
                            LoginHelper.handleOAuthCallbackLogin(data, dispatch);
                            return;
                        }  else {
                            setPageActive(Page.TWITTERLOGIN_FAILED);
                        }
                    })
                    return;
                } 
            } catch (error) {
                
            }
        } 
        setPageActive(Page.TWITTERLOGIN_FAILED)
    }, [])

    return (
        <React.Fragment>
            <DashThemeLight>
                <AppPage withLogo home>
                    <LoadingBox loading />
                </AppPage>
            </DashThemeLight>
        </React.Fragment>
    )

}

export default React.memo(TwitterCallback)
