import React from 'react';
import { createStore } from 'redux'
import { Provider } from 'react-redux';

import { playerReducer } from './reducers'

const volatileStore = createStore(playerReducer)

export const PlayerStoreProvider: React.FC<{children?: React.ReactNode}> = (props) => {
    return (
        <Provider store={volatileStore}>
		{
			props.children
		}
        </Provider>
    );
}

export default PlayerStoreProvider
