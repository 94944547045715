import React from 'react'
import RootReduxProvider from '../../GlobalStore'
import { useSelector } from 'react-redux'
import { NextStationSelector } from '../../reducers/AllStationsReducer'
import { Station } from 'dash-dashradio-app-client-api'
import { Referrer } from '../../reducers/PlayerReducer'
import { ReduxAppState } from '../../reducers'

interface Props {
	currentStation?: Station,
	lastStation: boolean,
	currentReferrer?: Referrer,
	currentReferrerId?: string | number,
	children: (nextStation: Station) => any
}
const PlayNextStationHandler: React.FC<Props> = (props) => {
	const { currentStation, lastStation, currentReferrer, currentReferrerId, children } = props

	const nextStation = useSelector((state: ReduxAppState) => NextStationSelector(state, currentStation, lastStation, currentReferrer, currentReferrerId))
	
	if(nextStation)
		return children(nextStation)
}

export default (props: Props)=>(
	<RootReduxProvider>
		<PlayNextStationHandler {...props} />
	</RootReduxProvider>
)