import React, { useState, useRef } from 'react';
import { IconButton, CircularProgress, useMediaQuery } from '@mui/material';
import clsx from 'clsx'
import Modal from '@mui/material/Modal';
import AppSimpleButton from './views/AppSimpleButton';
import AppSimpleInput from './views/AppSimpleInput';
import AppText from './views/AppText';
import { DashThemeLight, DashThemeDark } from '../Themes';
import { RokuAPI } from 'dash-dashradio-app-client-api';
import { session } from '../App';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import FadeInHOC from './ui-elements/FadeInHOC';
import dialogClasses from "./Dialog.module.css";


interface Props {
	onClose: () => void,
	onNoAccountWithEmail?: (input: { email: string, password: string }) => void,
}

export const LinkAccountDialog: React.FC<Props> = (props) => {
	const { onClose } = props
	const smallScreen = useMediaQuery('(max-width: 600px)');

	const rokuSerialInput = useRef(null)

	const [rokuSerialNo, setRokuSerialNo] = useState("")
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(undefined)
	const [success, setSuccess] = useState(false)

	const handleLinkAccount = () => {
		setLoading(true)
		let userID = Number(localStorage.getItem('userID'))
		RokuAPI.linkRoku(session)(rokuSerialNo, userID).then(user => {
			setLoading(false)
			setSuccess(true)
		}).catch((err) => {
			setError(err)
			setLoading(false)
		})

	}

	var errorMessage
	const status: any = (error && error!['status']) || 400
	switch (status) {
		case 401:
			errorMessage = "Wrong password. Please check your input."
			break
		case 404:
			errorMessage = "Token is invalid!"
			break
		case 403:
			errorMessage = "Roku device does not exist"
			break
		default:
		case 400:
			errorMessage = "Please Logout and Login again"
			break
	}

	return (
		<div className={dialogClasses.drawerStyle} style={{ overflowX: 'hidden' }}>
			<div className={clsx(dialogClasses.darkBackground, dialogClasses.flexbox)}>
				<div className={dialogClasses.drawerTitle}>Link Account with Roku!</div>
				<DashThemeDark>
					<IconButton color="primary" onClick={() => onClose()}>
						{/* <img className={classes.iconStyle} src={Icons.ICON_CHEVRON_RIGHT_WHITE} alt="" /> */}
						<CloseIcon scale={24} />
					</IconButton>
				</DashThemeDark>
			</div>

			<div className={dialogClasses.content}>
				<AppSimpleInput
					inputRef={rokuSerialInput}
					placeholder="Roku Serialnumber"
					value={rokuSerialNo}
					onChange={(value) => setRokuSerialNo(value)}
					onSubmit={handleLinkAccount} />

				<div style={{ width: 64, paddingBottom: 12 }}></div>

				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
						<AppSimpleButton disabled={loading} onClick={handleLinkAccount}>Link Account</AppSimpleButton>
						{
							loading &&
							<DashThemeLight>
								<CircularProgress color="primary" style={{ width: 24, height: 24, margin: '0px 16px', lineHeight: 0 }} />
							</DashThemeLight>
						}
					</div>
					{
						error && errorMessage && !success &&
						<div style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 4, paddingRight: 4 }}>
							{
								error && errorMessage && <AppText className={dialogClasses.error}>{errorMessage}</AppText>
							}
						</div>
					}
					{
						errorMessage && !success &&
						<div style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 4, paddingRight: 4 }}>
							{
								errorMessage && <AppText className={dialogClasses.error}>{errorMessage}</AppText>
							}
						</div>
					}
					{
						success &&
						<div style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 4, paddingRight: 4 }}>
							{
								<AppText className={dialogClasses.success}>Account successfully linked</AppText>
							}
						</div>
					}
				</div>


			</div>

		</div>
	)
}


export const LinkAccountModal: React.FC<{ open: boolean } & Props> = (props) => {
	const handleClose = () => props.onClose()
	// const {onForgotPasswordClick, onNoAccountWithEmail, onSignupClick } = props

	return (
		<Modal open={props.open} onClose={handleClose}>
			<div>
				<FadeInHOC>
					<LinkAccountDialog onClose={handleClose} />
				</FadeInHOC>
			</div>
		</Modal>
	)
}

export default LinkAccountModal
