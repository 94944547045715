import React, {ForwardedRef} from 'react';
import { createTheme, ThemeProvider  } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#ffffff'
        },
        secondary: {
            main: '#656565'
        }
    }
});
const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#222223'
        },
        secondary: {
            main: '#656565'
        }
    }
});

export const DashThemeDark: React.FC<{children?: React.ReactNode}> = (props) => {
    return <DashTheme {...props} lightTheme={false} />
}
export const DashThemeLight: React.FC<{children?: React.ReactNode}> = (props) => {
    return <DashTheme {...props} lightTheme={true} />
}
export const DashTheme: React.FC<{lightTheme?: boolean, children?: React.ReactNode}> = (props) => {
    return (
        <ThemeProvider theme={Boolean(props.lightTheme) ? lightTheme : darkTheme}>
        {
            props.children
        }
        </ThemeProvider>
    )
}

interface MaterialColorThemeProps {
    primary?: string,
    secondary?: string,
    renderChildren: (ref: ForwardedRef<any>) => React.ReactNode;
}

export const MaterialColorTheme: React.FC<MaterialColorThemeProps> = React.forwardRef((props, ref) => {
    const primary = props.primary && {
        primary: {
            main: props.primary
        }
    }
    const secondary = props.secondary && {
        secondary: {
            main: props.secondary
        }
    }
    const theme = createTheme({
        palette: {
            ...primary,
            ...secondary
        }
    });
    return (
        <ThemeProvider theme={theme}>
        {
            props.renderChildren(ref)
        }
        </ThemeProvider>
    )
});

// export const COLOR_GENRE_ROCK = "#8C0404";
// export const COLOR_GENRE_HIP_HOP = "#FF0000";
// export const COLOR_GENRE_LATIN = "#FF6900";
// export const COLOR_GENRE_COUNTRY = "#F7921F";
// export const COLOR_GENRE_FAITH_GOSPEL = "#F0DA10";
// export const COLOR_GENRE_JAZZ_CLASSICAL = "#9EFB47";
// export const COLOR_GENRE_DECADE = "#3AB248";
// export const COLOR_GENRE_KIDS_POP = "#E74994";
// export const COLOR_GENRE_POP = "#93278B";
// export const COLOR_GENRE_TALK = "#312575";
// export const COLOR_GENRE_RNB = "#0E72B8";
// export const COLOR_GENRE_WORLD = "#06A89F";
// export const COLOR_GENRE_ELECTRONIC_DANCE = "#47D7AC";

/**
 * Workaround for current api -> TODO add genre color to api
 * @param genre_id
 */
// export function resolveGenreColorStatic(genre_id: number) {
//     switch(genre_id) {
//         case 5: return COLOR_GENRE_COUNTRY;
//         case 6: return COLOR_GENRE_DECADE;
//         case 7: return COLOR_GENRE_ELECTRONIC_DANCE;
//         case 8: return COLOR_GENRE_FAITH_GOSPEL;
//         case 9: return COLOR_GENRE_JAZZ_CLASSICAL;
//         case 11: return COLOR_GENRE_HIP_HOP;
//         case 14: return COLOR_GENRE_LATIN;
//         case 18: return COLOR_GENRE_ROCK;
//         case 21: return COLOR_GENRE_TALK;
//         case 25: return COLOR_GENRE_RNB;
//         case 29: return COLOR_GENRE_WORLD;
//         default:
//         case 31: return COLOR_GENRE_POP;
//         case 32: return COLOR_GENRE_KIDS_POP;
//     }
// }
