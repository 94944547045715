import React from 'react';
import { makeStyles } from '@mui/material/styles';
import {  Station } from 'dash-dashradio-app-client-api';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { PlayingSelector, setCurrentStation, setPlaybackState, PlaybackState, Referrer } from '../reducers/PlayerReducer';
import { ReduxPlayerState } from '../reducers';
import PlayerStoreProvider from '../PlayerStore';
import StationItemHeart from './StationItemHeart';
import { navigationEventEmitter, EVENT_CURRENTSTATION } from './AppDrawer';
import MetadataObserver, { getSongSlogan } from "./player/MetadataObserver";
import classes from "./StationItem.module.css"

interface Props {
    station: Station,
    referrerInfo?: { referrer: Referrer, referrerId?: string | number },
}
const StationItem: React.FC<Props> = (props) => {
    const { station, referrerInfo } = props

    const playing: boolean = useSelector(PlayingSelector)
    const activeStation: Station | undefined = useSelector((state: ReduxPlayerState) => state.player.currentStation)
    const dispatch = useDispatch()

    const stationClick = () => {
        localStorage.setItem('currentStation', JSON.stringify(station))
        if (activeStation && activeStation.id === station.id && playing) {     //station is currently playing
            dispatch(setPlaybackState(PlaybackState.PAUSED))    //pause
        }
        else {
            dispatch(setCurrentStation(station, PlaybackState.PLAYING, referrerInfo && referrerInfo.referrer, referrerInfo && referrerInfo.referrerId))
            navigationEventEmitter.emit(EVENT_CURRENTSTATION)
        }
    }

    const focusElement = () => {
        let titlefocus: any = document.getElementById('title-station')
        titlefocus && titlefocus.focus()
    }

    return (
        <Grid item key={station.id} className='station-items' onClick={() => focusElement()}>
            <MetadataObserver stationId={station.id}>
                {song =>
                    <div
                    className={classes.box}>
                    <StationNumberView classes={classes} station={station} />
                    <img className={classes.imageStyle} src={station.colored_light_logo_url} height={140} width={140} alt="" onClick={stationClick} />
                    <div className={classes.textBox} onClick={stationClick}>
                        <div className="App-Station-Item-Title" id="title-station">{station.name.replace(/\u00AE/g, "")}</div>
                        { song &&
                            <div className="App-Station-Item-Subtitle">{getSongSlogan(song)}</div>
                            }

                        {/* <div className="App-Station-Seperator" style={{ backgroundColor: (station.genreObj && station.genreObj.genre_accent_color) || '#000000', ...(activeStation && activeStation.id === station.id && playing && { backgroundColor: '#A7A7AB80' }) }} /> */}
                        <div className="App-Station-Seperator" style={{ backgroundColor: (station.genreObj && station.genreObj.genre_accent_color) || '#000000' }} />
                    </div>

                </div>
                }
            </MetadataObserver>

        </Grid>
    )
}

const StationNumberView: React.FC<{ classes: any, station: any }> = (props) => {
    const { classes, station } = props

    const genreNumber = station['genreNumber']
    const stationNumber = station['stationNumber']
    //const totalNumber = station['totalNumber']

    return (
        <div className={classes.textNumber}>

            <StationItemHeart station={station} />
            {
                // genreNumber && stationNumber && `${genreNumber}${stationNumber}`
                genreNumber && stationNumber && <div style={{ width: '100%', textAlign: 'end', visibility: 'hidden' }}>{genreNumber}{stationNumber}</div>
            }
            {
                //totalNumber && `${(totalNumber as string).replace(/0/g, "")}`
            }
        </div>
    )
}

export default React.memo((props: Props) => (
    <PlayerStoreProvider>
        <StationItem {...props} />
    </PlayerStoreProvider>
))
