import React from 'react';
import { Station } from 'dash-dashradio-app-client-api';
import { Grid } from '@mui/material';
import { LoadingPage } from './LoadingPage';
import { useSelector } from 'react-redux'
import { ReduxAppState } from '../reducers';
import StationItem from '../components/StationItem';
import Headline from '../components/views/Headline';
import { Referrer } from '../reducers/PlayerReducer';
import Spacer from '../components/ui-elements/Spacer';

const StationsPage: React.FC = (props) => {
    const cachedStations: Station[] = useSelector((state: ReduxAppState) => (state.allStations && state.allStations.stations) || [])

    return (
        <React.Fragment>
            {
                (cachedStations && <AllStationsList stations={cachedStations.sort((a, b) => a.name.localeCompare(b.name))} />) || (<LoadingPage />)
            }
        </React.Fragment>
    )
}


const AllStationsList: React.FC<{ stations: Station[] }> = React.memo((props) => {

    return (
        <>
            <Headline title={"All Stations"} id="allstations" center />
            <Spacer size={16} />
            <Grid container spacing={4} justifyContent="center" alignItems="center" alignContent="center" style={{ width: '100%', margin: 'unset' }}>
                {
                    props.stations && props.stations.map(station => {
                        return <StationItem key={station.id} station={station} referrerInfo={{ referrer: Referrer.ALL }} />
                    })
                }
            </Grid>
        </>
    )
})

export default React.memo(StationsPage)
