import { ReduxAppState } from ".";

interface LocalSettingsState {
    drawerOpen: boolean,
    sideBar: boolean,
    newSideBarOpen: boolean,
}
const initialState: LocalSettingsState = {
    drawerOpen: false, sideBar: false, newSideBarOpen: false
}

const ACTION_UPDATE = "actions-update-settings"
interface ActionUpdate {
    type: typeof ACTION_UPDATE,
    update: Partial<LocalSettingsState>
}
export type Actions = ActionUpdate

export function updateSettings(update: Partial<LocalSettingsState>): ActionUpdate {
    return {
        type: ACTION_UPDATE,
        update
    }
}

export default function LocalSettingsReducer(state = initialState, action: Actions): LocalSettingsState {
    switch (action.type) {
        case ACTION_UPDATE: {
            return {
                ...state,
                ...action.update,
            }
        }
    }
    return state
}

export const IsDrawerOpenSelector = (state: ReduxAppState) => Boolean(state.localSettings.drawerOpen)
export const IsSideBarOpenSelector = (state: ReduxAppState) => Boolean(state.localSettings.sideBar)
export const IsNewSideBarOpenSelector = (state: ReduxAppState) => Boolean(state.localSettings.newSideBarOpen)