import React from 'react'

const centerStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

interface NoHeightProps {
	noHeight?: boolean,
    children?: React.ReactNode
}
const Center: React.FC<NoHeightProps> = (props) => (
    <div style={{...centerStyle, ...(Boolean(props.noHeight) && {height: 'unset'})}}>{props.children}</div>
)

export default Center
