import React from 'react'
import classnames from 'classnames'
import { ICON_EMAIL } from '../../Icons';
import classes from "./AppSimpleButton.module.css";

interface Props {
    classname?: any,
    onClick: () => void,
    disabled?: boolean,
    style?: any,
    popup?: boolean,
    email?: boolean,
    children?: React.ReactNode
}
const AppSimpleButton: React.FC<Props> = (props) => {
    const disabled = Boolean(props.disabled)
    return (
        !props.email ?
            <div className={props.popup ? 'popup-button' : classnames(classes.background, classes.text, disabled && classes.inactive, !disabled && classes.clickable, props.classname)}
                onClick={() => {
                    if (!disabled) {
                        props.onClick()
                    }
                }} style={props.style}>
                {props.children}
            </div>
            :
            props.email &&
            <div className={props.popup ? 'popup-button email' : classnames(classes.background, classes.text, disabled && classes.inactive, !disabled && classes.clickable, props.classname)}
                onClick={() => {
                    if (!disabled) {
                        props.onClick()
                    }
                }} style={props.style}>
                <img src={ICON_EMAIL} width="16" height="20" alt="email" style={{ marginRight: 10, marginLeft: 4, marginTop: 2 }} />
                <div className="social-button">
                    {props.children}
                </div>
            </div>
    )
}

export default AppSimpleButton
