import React, { useEffect, useState } from 'react'
import SocketIOMetadataObserver from '../background-managers/SocketIOMetadataObserver';
import PlayerStoreProvider from '../../PlayerStore';
import { CurrentSong } from 'dash-dashradio-app-client-api';

interface Props {
    children: (song: CurrentSong | undefined) => React.ReactNode
    stationId?: number;
}
const MetadataObserver: React.FC<Props> = (props) => {
    const [currentSong, setCurrentSong] = useState<CurrentSong | undefined>(undefined)

    //const station = useSelector((state: ReduxPlayerState)=>state.player.currentStation)

    useEffect(()=>{
        if(props.stationId == undefined)
            return;
        const listener = {
            onNewSong: song => {
                if(currentSong !== song){
                    setCurrentSong(song);
                }
            },
            stationId: props.stationId
        }
        SocketIOMetadataObserver.getInstance().registerSongListener(listener);
        return () => {
            SocketIOMetadataObserver.getInstance().unregisterSongListener(listener);
        };
       
    }, [props.stationId]);
    return <>{ props.children(currentSong) }</>
}
export function getSongSlogan(song: CurrentSong): string{
    if(!song.title && !song.artist)
        return "";
    if(!song.artist)
        return song.title;
    return song.title + " by " + song?.artist;
}


export default React.memo((props: Props)=>(
    <PlayerStoreProvider>
        <MetadataObserver {...props} />
    </PlayerStoreProvider>
))
