import React, { Suspense } from 'react';
import AspectRatioView from '../ui-elements/AspectRatioView';
import { Station } from 'dash-dashradio-app-client-api';
import {Img, useImage} from 'react-image'
import { IMG_DEFAULT_COVER } from '../../Icons'
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReduxPlayerState } from '../../reducers';
import PlayerStoreProvider from '../../PlayerStore';


const StationImage: React.FunctionComponent<{activeStation: Station}> = (props) => {
    const imageStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer'
    }

    const {src} = useImage({ srcList: [props.activeStation.default_cover_url, IMG_DEFAULT_COVER]});

    return (
        <img src={src}
            style={imageStyle}
            alt=""
            onClick={() => window.location.pathname = ("/" + props.activeStation.slug)}
        />
    );
}

const StationDefaultCover: React.FunctionComponent = (props) => {
    const activeStation = useSelector((state: ReduxPlayerState) => state.player.currentStation)


    const centerStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
    
    return (
        <AspectRatioView>
            {
                activeStation && (activeStation as Station)?.default_cover_url &&
                <Suspense fallback={<div style={centerStyle}><CircularProgress color="primary" /></div>}>
                    <StationImage activeStation={activeStation}/>
                </Suspense>
            }
        </AspectRatioView>
    )
}

export default (props) => (
    <PlayerStoreProvider>
        <StationDefaultCover {...props} />
    </PlayerStoreProvider>
)
