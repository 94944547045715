import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StationFromSlugSelector, updateAllStations } from "../../../reducers/AllStationsReducer";
import PlayerStoreProvider from "../../../PlayerStore";
import { PlayStationHandler } from "./PlayStationHandler";
import { ReduxAppState } from "../../../reducers";

export const SlugHandler: React.FC<{ slug: string, children?: React.ReactNode }> = props => {
    //search for station with slug
    const stationFromSlug = useSelector((state: ReduxAppState) =>
        StationFromSlugSelector(state, props.slug)
    );

    if (stationFromSlug) {
        return (
            <PlayerStoreProvider>
                <PlayStationHandler station={stationFromSlug} />
            </PlayerStoreProvider>
        );
    }

    return <>{props.children}</>;
};
