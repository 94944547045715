import { Station } from "dash-dashradio-app-client-api";
import { ReduxAppState } from ".";
import { Referrer } from "./PlayerReducer";
import { FeaturedStationsSelector } from "./FeaturedStationsReducer";

export interface AllStationsState {
    stations: Station[],
    favorites: number[],
}
const initialState: AllStationsState = { stations: [], favorites: [] }

const ACTION_UPDATE = "actions-all-stations-update"
const ACTION_SET_FAVORITES = "actions-all-stations-set-favorites"
const ACTION_ADD_FAVORITE = "actions-all-stations-add-favorite"
const ACTION_REMOVE_FAVORITE = "actions-all-stations-remove-favorite"
interface ActionUpdate {
    type: typeof ACTION_UPDATE,
    stations: Station[]
}
interface ActionSetFavorites {
    type: typeof ACTION_SET_FAVORITES,
    favorites: number[]
}
interface ActionAddFavorite {
    type: typeof ACTION_ADD_FAVORITE,
    stationId: number
}
interface ActionRemoveFavorite {
    type: typeof ACTION_REMOVE_FAVORITE,
    stationId: number
}
export type Actions = ActionUpdate | ActionSetFavorites | ActionAddFavorite | ActionRemoveFavorite

export function updateAllStations(stations: Station[]): ActionUpdate {
    return {
        type: ACTION_UPDATE,
        stations: stations || []
    }
}

export function setFavorites(favorites: number[]): ActionSetFavorites {
    return {
        type: ACTION_SET_FAVORITES,
        favorites: favorites || []
    }
}

export function addFavorite(stationId: number): ActionAddFavorite {
    return {
        type: ACTION_ADD_FAVORITE,
        stationId
    }
}

export function removeFavorite(stationId: number): ActionRemoveFavorite {
    return {
        type: ACTION_REMOVE_FAVORITE,
        stationId
    }
}


export default function AllStationsReducer(state = initialState, action: Actions): AllStationsState {
    var isEqual = false
    var stateUpdate
    switch (action.type) {
        case ACTION_UPDATE: {
            stateUpdate = {
                ...state,
                stations: action.stations
            }
            break
        }
        case ACTION_SET_FAVORITES: {
            stateUpdate = {
                ...state,
                favorites: action.favorites
            }
            break;
        }
        case ACTION_ADD_FAVORITE: {
            stateUpdate = {
                ...state,
                favorites: state.favorites.concat(action.stationId)
            }
            break;
        }
        case ACTION_REMOVE_FAVORITE: {
            stateUpdate = {
                ...state,
                favorites: state.favorites.filter((value) => Boolean(action.stationId !== value))
            }
            break;
        }
    }

    if (stateUpdate) {
        try {
            if (JSON.stringify(state) === JSON.stringify(stateUpdate)) {
                isEqual = true
            }
        }
        catch (err) {
            //
        }
        if (!isEqual) {
            return stateUpdate      //return new state
        }
    }
    return state
}

export const FavoritesSelector = (state: ReduxAppState): Station[] => {
    return (
        state.allStations.favorites && state.allStations.favorites.map(id => {
            const filtered = (state.allStations.stations && state.allStations.stations.filter(station => Boolean(station.id === id))) || []
            return filtered.length > 0 && filtered[0]
        }).filter(Boolean) as Station[]
    ) || []
}

export const AllStationsSelector = (state: ReduxAppState) => {
    const cachedStations = state.allStations.stations || []
    // debugger
    return cachedStations.sort((a, b) => (Number(a['genreNumber'] + a['stationNumber']) - Number(b['genreNumber'] + b['stationNumber'])))
    // return cachedStations.sort((a,b)=>a.name.localeCompare(b.name))
}

export const StationFromSlugSelector = (state: ReduxAppState, slug: string) => {
    let cachedStations = state.allStations.stations || []
    return slug && cachedStations.find(station => Boolean(station.slug && station.slug.toLowerCase() === slug.toLowerCase()))

}

export const AllStationsInGenreSelector = (state: ReduxAppState, genreId: number) => {
    const cachedStations = state.allStations.stations || []
    return cachedStations
        .sort((a, b) => b.genre_priority - a.genre_priority)
        .filter(station => ((station.genreObj && station.genreObj.id === genreId) || false))
}

export const HighlightsSelector = (state: ReduxAppState): Station[] => {
    return (state.highlights.highlights && state.highlights.highlights.map(highlight => {
        try {
            const linkedStationId = highlight.linked_station_id && Number.parseInt(highlight.linked_station_id)
            if (linkedStationId) {
                return state.allStations.stations && state.allStations.stations.find(station => (station.id === linkedStationId))
            }
        }
        catch (err) {
            //
        }
        return undefined
    }).filter(Boolean) as Station[]) || []
}

export interface GenreObj {
    id: number;
    genre_image_url: string;
    genre_name: string;
    priority: number;
    genre_accent_color: string;
}
export const GenresSelector = (state: ReduxAppState) => {
    var genres: GenreObj[] = []
    const cachedStations = state.allStations.stations || []

    var genreIds: number[] = []
    if (cachedStations) {
        genreIds = Array.from(new Set(cachedStations
            .sort((a, b) => {
                if (a.genreObj && b.genreObj) {
                    return b.genreObj.priority - a.genreObj.priority
                }
                return 0
            })
            .map((station => station.genreObj && station.genreObj.id))
            .filter(Boolean))
        ) as number[]
    }

    if (genreIds) {
        genres = genreIds.map(genreId =>
            cachedStations.find(station => Boolean(station.genreObj && station.genreObj.id === genreId))!['genreObj']
        ).filter(Boolean) as GenreObj[]
    }

    return genres
}

export const NextStationSelector = (state: ReduxAppState, currentStation: Station | undefined, lastStation: boolean, currentReferrer?: Referrer, currentReferrerId?: string | number) => {
    const stationId = currentStation && (currentStation.id || 3)	//fallback station: Dash X
    const referrer = currentReferrer || Referrer.ALL

    let stations: Station[]
    switch (referrer) {
        case Referrer.DASHBOARD:
            stations = FavoritesSelector(state)
            break
        case Referrer.HIGHLIGHTS:
            stations = HighlightsSelector(state)
            break
        case Referrer.FEATURED:
            stations = FeaturedStationsSelector(state)
            break
        case Referrer.GENRE:
            if (currentReferrerId) {
                if (typeof currentReferrerId !== 'number') {
                    currentReferrerId = Number.parseInt(currentReferrerId)
                }
                stations = AllStationsInGenreSelector(state, currentReferrerId)
            }
            else stations = AllStationsSelector(state)
            break
        default:
        case Referrer.ALL:
            stations = AllStationsSelector(state)
            break
    }

    if (stations && stations.length > 0) {
        if (stations.length > 1) {
            const pos = stations.findIndex(station => (station.id === stationId))
            if (lastStation) {
                if (pos < 1) {
                    return stations[stations.length - 1]		//return last element (also if current station was not found list)
                }
                else return stations[Math.max(0, Math.min(stations.length - 1, pos - 1))]		//return last
            }
            else {
                if (pos < stations.length - 1) {
                    return stations[Math.max(0, Math.min(stations.length - 1, pos + 1))]		//return next
                }
                //else return first element
            }
        }
        return stations[0]		//return first element
    }
}
