import React from 'react';
import { Page, navigationEventEmitter, EVENT_OPENMENUS } from '../../AppDrawer';
import { getActivePage, setPageActive } from './Functions';
import DrawerItem from './DrawerItem';
import clsx from 'clsx';
import * as Icons from "../../../Icons";
import "../../../App.scss";

interface DrawerNavigationItemProps {
	drawerOpen: boolean;
	title?: string;
	page: Page;
	secondary?: boolean;
	active?: boolean;
	withIconTitle?: boolean;
	icon?: any;
	mobileScreen?: boolean;
	news?: boolean;
	children?: React.ReactNode;
}

export default class DrawerNavigationItem extends React.Component<DrawerNavigationItemProps, { activeItem: boolean }>{

	constructor(props: DrawerNavigationItemProps) {
		super(props)

		var activeItem = this.checkActiveItem()

		this.state = {
			activeItem: activeItem
		}
	}


	logoClick = () => {
		localStorage.setItem('logoClick', "true")
		setPageActive(Page.HOME)
	}

	componentDidUpdate() {
		let activeItem = this.checkActiveItem()

		if(this.state.activeItem !== activeItem){
			this.setState({
				activeItem: activeItem
			})
		}
	}



	onDrawerClick() {
		setPageActive(this.props.page)
		navigationEventEmitter.emit(EVENT_OPENMENUS)
		// let activeItem = this.checkActiveItem()

		// this.setState({
		// 	activeItem: activeItem
		// })
	}

	private checkActiveItem(): boolean {
		var activePage: Page | undefined = getActivePage();

		if (!this.props.news && (this.props.page !== Page.GENRES)) {
			let itemActive = (this.props.page == activePage)
			return Boolean(itemActive) ? true : false;
		} else if (this.props.page === Page.GENRES) {
			let itemActive = (activePage === Page.GENRES || activePage === Page.FAVORITES || activePage === Page.ALL_STATIONS)
			return itemActive ? itemActive : false;
		}
		else {
			let itemActive = (window.location.pathname.split('/')[1] === 'news')
			return Boolean(itemActive) ? true : false;
		}
	}

	render() {
		return (
			<DrawerItem
				active={this.state.activeItem}
				onClick={() => this.props.page === Page.HOME ? this.logoClick() : this.onDrawerClick()}
				iconOnly={!this.props.drawerOpen}
				mobileScreen={this.props.mobileScreen}
				iconTitle={Boolean(this.props.withIconTitle) ? this.props.title : undefined}
				renderIcon={() => {
					if (this.props.icon) {
						return (
							<img
								alt=""
								src={this.props.icon}
								style={{
									width: 16,
									height: 16,
									objectFit: "contain",
									opacity: this.state.activeItem ? 1 : 0.65
								}}
							/>
						);
					} else return this.props.children;
				}}
			>
				{Boolean(this.props.secondary) ? (
					<div className={clsx(!this.state.activeItem ? "App-Drawer-Navigation-Item-Secondary" : "App-Drawer-Navigation-Item-Secondary-active")}>
						<div style={{ height: '100%', display: 'flex', justifySelf: 'center', alignSelf: 'center' }}>
							<img alt="" src={Icons.ICON_POINT} width={6} height={6} style={{ ...(!this.state.activeItem && { display: 'none' }) }} />
						</div>
						<div style={{ ...(!this.state.activeItem ? { marginLeft: 12 } : { marginLeft: 6 }) }}>
							{this.props.title}
						</div>
					</div>
				) : (
						this.props.title
					)}
			</DrawerItem >
		);

	}

}
