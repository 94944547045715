import React, { useEffect, useRef, useState } from 'react';
import { Grid, Hidden, useMediaQuery } from '@mui/material';
import AppPage from '../AppPage';
import { useSelector, useDispatch } from 'react-redux'
import { updateSettings, IsSideBarOpenSelector } from '../../reducers/LocalSettingsReducer';
import GuidePage from '../guide/GuidePage';
import Spacer from '../../components/ui-elements/Spacer';
import GenrePage from '../genre/GenresPage';
import StationsPage from '../StationsPage';
import { navigationEventEmitter, EVENT_GENRE, EVENT_SCROLL, Page, EVENT_MYFAVORITES } from '../../components/AppDrawer';
import PlayerStoreProvider from '../../PlayerStore';
import { DashThemeLight } from '../../Themes';
import { HighlightsGridList } from '../home/HomeHighlightsPage';
import { ReduxAppState } from '../../reducers';
import { setPageActive } from '../../components/views/AppDrawerComponents/Functions';


const RadioPage: React.FC<{}> = (props) => {
    const dispatch = useDispatch()
    const isSideBarOpen = useSelector(IsSideBarOpenSelector);
    const [scroller, setScroller] = useState(true)
    const highlights = useSelector(
        (state: ReduxAppState) => state.highlights.highlights
    );

    const smallScreen = useMediaQuery("(max-width: 960px)");
    const mobileScreen = useMediaQuery('(max-width: 480px)')
    const smallTablet = useMediaQuery("(max-width: 950px)");

    const favoriteSection: any = useRef(null)
    const genresSection: any = useRef(null)
    const allstationSection: any = useRef(null)

    useEffect(() => {
        if (!isSideBarOpen) {
            dispatch(updateSettings({ sideBar: true }))
        }
    }, [isSideBarOpen, dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const scrollToListener = (section: string) => {
            setScroller(false)
            let switchTo: any = ''
            switch (section) {
                case 'favorites': {
                    switchTo = favoriteSection;
                    setPageActive(Page.FAVORITES)
                    break;
                }
                case 'genres': {
                    switchTo = genresSection;
                    setPageActive(Page.GENRES)
                    break;
                }
                case 'allstations': {
                    switchTo = allstationSection;
                    setPageActive(Page.ALL_STATIONS)
                    break;
                }
            }
            const timer = setTimeout(() => {
                window.scrollTo({
                    behavior: 'smooth',
                    left: 0,
                    top: switchTo && switchTo.current && switchTo.current.offsetTop - 120,
                })
            }, 200);
            return () => clearTimeout(timer)
        }
        navigationEventEmitter.addListener(EVENT_SCROLL, scrollToListener)
        return () => {
            navigationEventEmitter.removeListener(EVENT_SCROLL, scrollToListener)
        }
    })

    useEffect(() => {
        let favoriteSectionPosition = favoriteSection && favoriteSection.current && favoriteSection.current.offsetTop
        let genresSectionPosition = genresSection && genresSection.current && genresSection.current.offsetTop
        let allStationPosition = allstationSection && allstationSection.current && allstationSection.current.offsetTop
        let offset = 120


        const listener = () => {
            if (favoriteSectionPosition && genresSectionPosition && allStationPosition && scroller) {
                if (window.pageYOffset <= genresSectionPosition - offset || window.pageYOffset < favoriteSectionPosition - offset) {
                    setPageActive(Page.FAVORITES)
                } else if ((window.pageYOffset < allStationPosition - offset && window.pageYOffset >= (favoriteSectionPosition - offset))) {
                    setPageActive(Page.GENRES)
                }
                else if (window.pageYOffset >= allStationPosition - offset) {
                    setPageActive(Page.ALL_STATIONS)
                }
            }
            if (!scroller) {
                const timer = setTimeout(() => {
                    setScroller(true)
                }, 500);
                return () => clearTimeout(timer);
            }
        }

        window.addEventListener('scroll', listener)
        return () => {
            window.removeEventListener('scroll', listener)
        }
    })

    useEffect(() => {
        const listener = (data) => {
            const timer = setTimeout(() => {
                window.scrollTo({
                    behavior: 'smooth',
                    left: 0,
                    top: favoriteSection && favoriteSection.current && favoriteSection.current.offsetTop - 60,
                })
            }, 200);
            return () => clearTimeout(timer)
        }

        navigationEventEmitter.on(EVENT_MYFAVORITES, listener);
        return () => {
            navigationEventEmitter.off(EVENT_MYFAVORITES, listener)
        }
    }, [])

    useEffect(() => {
        if (window.history.state && window.history.state.genre) {
            navigationEventEmitter.emit(EVENT_GENRE)
        } else {
            // if (favorites.length > 0) {
            //     setPageActive(Page.FAVORITES)
            // } else {
            setPageActive(Page.GENRES)
            // }
            window.scrollTo(0, 0)
        }
    }, [])

    return (
        <React.Fragment>
            <AppPage>
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={12}>
                        <PlayerStoreProvider>
                            <DashThemeLight>
                                <HighlightsGridList data={highlights} smallScreen={smallScreen} smallTablet={smallTablet} mobileScreen={mobileScreen} />
                            </DashThemeLight>
                        </PlayerStoreProvider>
                    </Grid>
                    <Hidden smDown><Grid item sm={1}></Grid></Hidden>
                    <Grid item xs={12} sm={10}>
                        <span ref={favoriteSection} />
                        <div>
                            <GenrePage allStationPosition={allstationSection && allstationSection.current && allstationSection.current.offsetTop - 120} />
                        </div>

                        <span ref={genresSection} />
                        <div>
                            <GuidePage />
                            <Spacer size={32} />
                        </div>

                        {/* <Spacer size={32} /> */}

                        <span ref={allstationSection} />
                        <div>
                            <StationsPage />
                        </div>
                    </Grid>
                    <Hidden smDown><Grid item sm={1}></Grid></Hidden>
                </Grid>
            </AppPage>
        </React.Fragment>
    )
}


export default React.memo(RadioPage)

