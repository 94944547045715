import React, { useEffect } from 'react';
import { Station } from 'dash-dashradio-app-client-api';
import { Grid, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux'
import { FavoritesSelector } from '../../reducers/AllStationsReducer';
import StationItem from '../../components/StationItem';
import Headline, { Props as HeadlineProps } from '../../components/views/Headline'
import { Referrer } from '../../reducers/PlayerReducer';
import {
    navigationEventEmitter,
    EVENT_GENRE,
    Page
} from "../../components/AppDrawer";
import { ReduxAppState } from '../../reducers';
import Spacer from '../../components/ui-elements/Spacer';
import { getActivePage } from '../../components/views/AppDrawerComponents/Functions';

const GenresPage: React.FC<{ allStationPosition?: any }> = (props) => {
    const favorites: Station[] = useSelector(FavoritesSelector)
    const actualState = useSelector((state: ReduxAppState) => state)

    const activePage = getActivePage()

    useEffect(() => {
        const listener = (data) => {
            let id = window.history.state.genre !== undefined ? window.history.state.genre : data
            const el = document.getElementById(`${id}`)
            if (((activePage == Page.FAVORITES) || (activePage == Page.GENRES) || (activePage == Page.ALL_STATIONS)) && data === undefined) {
                const timer = setTimeout(() => {
                    el && window.scrollTo({
                        behavior: 'smooth',
                        left: 0,
                        top: el.offsetTop - 50,
                    })
                }, 1000);
                return () => clearTimeout(timer)
            } else if (data !== undefined) {
                el && window.scrollTo({
                    behavior: 'smooth',
                    left: 0,
                    top: el.offsetTop - 50,
                })
            }
            else {
                const timer = setTimeout(() => {
                    el && window.scrollTo({
                        behavior: 'smooth',
                        left: 0,
                        top: el.offsetTop - 50,
                    })
                }, 1000);
                return () => clearTimeout(timer)
            }
        }
        navigationEventEmitter.on(EVENT_GENRE, listener)
        return () => {
            navigationEventEmitter.off(EVENT_GENRE, listener)
        }
    })



    return (
        <React.Fragment>
            {
                favorites && favorites.length > 0 &&
                <FavoriteStationsList id={"favorites"} stations={favorites} title={"Favorites"} state={actualState} />
            }
        </React.Fragment>
    )
}

const FavoriteStationsList: React.FC<{ id?: string, stations: Station[], filter?: (station: Station) => boolean, state: any } & HeadlineProps> = React.memo((props) => {
    const smallScreen = useMediaQuery("(max-width: 960px)");
    const mobileScreen = useMediaQuery('(max-width: 480px)')
    const smallTablet = useMediaQuery("(max-width: 950px)");

    return (
        <div style={{ padding: '30px 0' }} id={props.id}>
            {/* <Headline title={props.title} center subtitle={props.subtitle} /> */}
            <Headline title={'GENRES'} center subtitle={props.subtitle} />
            <Spacer size={32} />
            <Grid container spacing={4} justifyContent="center" alignItems="center" alignContent="center" style={{ ...((!mobileScreen && !smallScreen) && { justifyContent: 'flex-start' }) }}>
                {
                    props.stations.filter(props.filter || (() => true)).map((item, index) => {
                        return <StationItem key={`GenreItem_${item.id}`} station={item} referrerInfo={props.id === 'favorites' ? { referrer: Referrer.DASHBOARD } : { referrer: Referrer.GENRE, referrerId: props.id }} />
                    })
                }
            </Grid>
        </div>
    )
})

export default React.memo(GenresPage)
