import React from 'react'
import ProgressSpinner from './ProgressSpinner';

interface Props {
	loading: boolean,
	spinnerSize?: number,
	children?: React.ReactNode
}
const LoadingBox: React.FC<Props> = (props) => {
	const { loading, spinnerSize } = props

	return (
		<div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
			{
				props.children
			}
			{
				loading &&
				<div style={{ position: 'absolute', top: 100, left: 0, right: 0, display: 'flex', justifyContent: 'center', margin: 'auto' }}>
					<div style={{
						// width: '100%',
						width: '20%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}>
						<ProgressSpinner size={spinnerSize} />
					</div>
				</div>
			}
		</div>
	)
}

export default LoadingBox
