import { CurrentSong } from 'dash-dashradio-app-client-api';
import {io, Socket} from 'socket.io-client';
import { session } from '../../App';


export interface StationSongListener {
    onNewSong: (song: CurrentSong | undefined) => void;
    stationId: number
}

export default class SocketIOMetadataObserver {

    private currentSongs: {[key: number]: CurrentSong | undefined} = [];

    private songListeners: StationSongListener[] = [];
    private static msInstance: SocketIOMetadataObserver

    private mSocket?: Socket

    public registerSongListener(songListener: StationSongListener) {
        this.songListeners.push(songListener);
        songListener.onNewSong(this.currentSongs[songListener.stationId]);
    }
    public unregisterSongListener(songListener: StationSongListener){
        this.songListeners = this.songListeners.filter(elem => elem !== songListener);
    }

    public getCurrentSongs(){
        return this.currentSongs;
    }

    public static getInstance(): SocketIOMetadataObserver {
        if (!SocketIOMetadataObserver.msInstance) {
            SocketIOMetadataObserver.msInstance = new SocketIOMetadataObserver()
        }
        return SocketIOMetadataObserver.msInstance
    }

    public connect() {
        this.mSocket = io(session.apiURL, {
           reconnection: true,
           reconnectionDelay: 1500,
           transports: ['websocket'], 
        });

        if (this.mSocket) {
            //connection callbacks
            this.mSocket.on("connect", () => {
                if(!this.mSocket)
                    return;
                //echo test
                this.mSocket.on("echo", (data) => {
                     //console.log("echo: " + data)
                })
                this.mSocket.on("song_updates", (songUpdates: {[key: number]: CurrentSong}) => {
                    this.currentSongs = {...this.currentSongs, ...songUpdates};
                    this.songListeners.forEach(listener => {
                        if(songUpdates[listener.stationId]){
                            listener.onNewSong(songUpdates[listener.stationId]);
                        }
                    });
                    //const metadata = `${song.title} by ${song.artist} on ${song.station.name}`
                });
                 //this.mSocket.emit("echo", "test")
            })
            this.mSocket.on("disconnect", (data) => {
                // console.log("disconnected socket")
            })
            this.mSocket.on("error", (data) => {
                // console.log("socket error: " + data)
            });
        }
    }

    public disconnect() {
        if (this.mSocket) {
            this.mSocket.off();
        }
        this.mSocket = undefined;
    }

    public isConnected() {
        return Boolean(this.mSocket)
    }
}
