import React from 'react';
import { ListenEveryWhere_Background, CHEVROLET_WHITE, TOYOTA_WHITE, SUBARU_WHITE, GM_WHITE, JAGUAR_WHITE, FORD_WHITE, PORSCHE_WHITE, MAZDA_WHITE, BUICK_WHITE, MASERATI_WHITE } from '../../Icons';
import HeadlineBigText2 from '../../components/views/HeadlineBigText2';
import AppButtonNew from '../../components/views/AppButtonNew';
import Spacer from '../../components/ui-elements/Spacer';


const icons = [
    CHEVROLET_WHITE,
    TOYOTA_WHITE,
    SUBARU_WHITE,
    GM_WHITE,
    JAGUAR_WHITE,
    FORD_WHITE,
    PORSCHE_WHITE,
    MAZDA_WHITE,
    BUICK_WHITE,
    MASERATI_WHITE
]

const ListenEveryWhereBanner: React.FunctionComponent<{ smallScreen?: boolean, mobileScreen?: boolean, smallTablet?: boolean }> = props => {
    return (
        <div className="listen-everywhere-banner-container" style={{ backgroundImage: `url(${ListenEveryWhere_Background})`, }}>
            <HeadlineBigText2 smallTablet={props.smallTablet} mobileScreen={props.mobileScreen} center title={'listen everywhere'} subtitle={'smart speakers, vehicles, mobile, anywhere...'} />
            <Spacer size={props.mobileScreen ? 4 : 16} />
            <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly' }}>
                {icons.map((icon, index) => {
                    return (
                        <div key={index} style={{ flexDirection: 'column', display: 'flex' }}>
                            <img src={icon} width={'auto'} className="listen-everywhere-banner-image" />
                        </div>
                    )
                })}
            </div>
            <Spacer size={props.mobileScreen ? 4 : 16} />
            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <AppButtonNew appStore style={{ width: '166px', marginRight: '20px' }} />
                <AppButtonNew playStore style={{ width: '166px' }} />
            </div>
        </div>
    )
}

export default React.memo(ListenEveryWhereBanner)
