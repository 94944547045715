import React, { useEffect, useState } from 'react';
import { Zoom, Fab, useMediaQuery, useTheme } from '@mui/material';
import { DashThemeLight, MaterialColorTheme } from '../Themes';
import ArrowUpIcon from '@mui/icons-material/ExpandLessOutlined';
import { useSelector } from 'react-redux'
import PlayerStoreProvider from '../PlayerStore';
import { AccentColorSelector, PlayerIdleSelector } from '../reducers/PlayerReducer';
import FadeInHOC from '../components/ui-elements/FadeInHOC';
import ResponsivePage, { Device } from '../components/responsive-ui/ResponsivePage';


const FabButton: React.FC<{ visible: boolean, onClick: () => void, children?: React.ReactNode }> = (props) => {
	const playerIdle = useSelector(PlayerIdleSelector)
	const accentColor = useSelector(AccentColorSelector)
	const theme = useTheme();

	return (
		<Zoom
			timeout={500}
			in={props.visible}
			unmountOnExit>

			<MaterialColorTheme primary={accentColor || '#222223'} renderChildren={ref => props.children &&
				<Fab ref={ref} aria-label={""} onClick={props.onClick} sx={{
					bottom: !playerIdle ? 117 : theme.spacing(2),
					right: theme.spacing(2),
					backgroundColor: "white",
					position: "fixed",
					color: "black",
						"&:hover": {
							backgroundColor: '#FF3F90',
							color: 'white'
						}
					}}>
					{
						props.children
					}
				</Fab>}/>

		</Zoom>
	)
}

export const AppPage: React.FC<{ additionalPaddingX?: number, additionalPaddingY?: number, withLogo?: boolean, home?: boolean, SlugLoader?: boolean, children?: React.ReactNode }> = (props) => {
	const hasAdditionalPadding = props.additionalPaddingX || props.additionalPaddingY
	const smallScreen = useMediaQuery("(max-width: 960px)");
	const mobileScreen = useMediaQuery('(max-width: 480px)')

	const [scrollUpButtonVisible, setScrollUpButtonVisible] = useState(false)
	useEffect(() => {
		const listener = (event) => {
			const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
			const scrolledFromTop = (event && event.target && event.target.scrollingElement && event.target.scrollingElement.scrollTop) || 0

			const shouldBeVisible = scrolledFromTop > viewportHeight / 2
			setScrollUpButtonVisible(shouldBeVisible)
		}
		window.addEventListener("scroll", listener)

		return () => {
			window.removeEventListener("scroll", listener)
		}
	}, [])

	return (
		<ResponsivePage>
			{(device) => {
				// const horizontalPadding = device === Device.DESKTOP ? 32 : 16
				// const horizontalPadding = smallScreen ? '110px' : '106px' // Padding from Top Banner for invest banner
				const horizontalPadding = smallScreen ? '59px' : '55px' // Padding from Top Banner without invest banner
				const appPlayerPadding = smallScreen ? '90px' : '140px'
				const leftPadding = ((!smallScreen && !mobileScreen) ? 222 : ((smallScreen && !mobileScreen) ? 0 : 0))
				const rightPadding = 0
				return (
					<FadeInHOC>
						<div className="App">
							<div className="App-page" style={{ overflowX: 'hidden', padding: `${horizontalPadding} ${rightPadding}px ${appPlayerPadding} ${leftPadding}px`, ...(props.SlugLoader && { display: 'flex', justifyContent: 'center' }) }}>
								{
									(hasAdditionalPadding && (
										<div style={{ margin: `${props.additionalPaddingY || 0}px ${props.additionalPaddingX || 0}px` }}>
											{
												props.children
											}
										</div>
									))
									|| props.children
								}
							</div>
							<DashThemeLight>
								<PlayerStoreProvider>
									<FabButton visible={scrollUpButtonVisible} onClick={() => { window.scrollTo({ behavior: "smooth", top: 0, left: 0 }) }}>
										<ArrowUpIcon />
									</FabButton>
								</PlayerStoreProvider>
							</DashThemeLight>
						</div>
					</FadeInHOC>
				)
			}}
		</ResponsivePage>
	)
}
export default AppPage;
