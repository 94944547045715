import React from "react";
import { getActivePage, getActiveMenu } from "./Functions";
import { SlugHandler } from "./SlugHandler";
import { Page } from "../../AppDrawer";
import { Station } from "dash-dashradio-app-client-api";
import { ReduxAppState } from "../../../reducers";
import { useSelector } from 'react-redux'
import { Navigate } from "react-router-dom";

export const DashSlugHandler: React.FC = props => {
    const websitePath = window.location.pathname || "";
    const page = getActivePage();
    const menu = getActiveMenu();

    const cachedStations: Station[] = useSelector((state: ReduxAppState) => (state.allStations && state.allStations.stations) || [])

    if (!page && !menu) {
        if (cachedStations.length > 1) {
            return (
                <SlugHandler slug={websitePath.substr(1)}>
                    <Navigate to="/" />
                </SlugHandler>
            );
        }
    }
    return <></>
    // return <AppPage SlugLoader><CircularProgress color="primary" /></AppPage>;
};