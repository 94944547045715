import React, {CSSProperties, HTMLAttributes} from 'react'
import { InputBase } from '@mui/material';

const styles: {[key: string]: CSSProperties} = {
    background: {
        flex: 1,
        flexShrink: 0,
        width: '100%',
        marginBottom: 16,
        background: 'rgb(29, 233, 182, .1)',
        padding: '8px 16px',
        borderRadius: '30px'
    },
    text: {
        fontFamily: 'Lato',
        fontWeight: 400,   /* Regular */
        fontSize: '0.875rem',
        color: '#1DE9B6',
        letterSpacing: '-0.13px',
        textAlign: 'center',
    },
}

interface Props {
    inputRef?: any,
    classname?: string,
    classnameText?: any,

    style?: CSSProperties;
    password?: boolean,
    placeholder?: string,
    value: string,
    multiline?: boolean,
    onChange: (value: string) => void,
    onSubmit: () => void,
    popup?: boolean
}
const AppSimpleInput: React.FC<Props> = (props) => {
    return (
        <div className={props.popup ? 'popup-simple-input' : props.classname}
            style={props.popup ? {} : {...styles.background, ...styles.text, ...props.style}}>
            <InputBase
                autoComplete="on"
                inputRef={props.inputRef}
                type={Boolean(props.password) ? "password" : "text"}
                multiline={props.multiline}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                onKeyDown={(e) => {
                    if (!Boolean(props.multiline) && e.key === 'Enter') {
                        props.onSubmit()
                        e.preventDefault()
                        e.stopPropagation()
                    }
                }}
                className={props.classname}
                inputProps={{
                    'aria-label': 'naked', style: props.popup ? {} : {...styles.text, ...props.style}, className: props.popup ? 'popup-simple-input-text' : props.classnameText
                }}
                style={{ display: 'flex' }}
            />
        </div>
    )
}

export default AppSimpleInput
