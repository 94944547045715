import * as React from 'react';
import MediaQuery from 'react-responsive';
import { DEVICE_WIDTH_DESKTOP, DEVICE_WIDTH_TABLET } from './ResponsivePage';

interface Props {
	children?: React.ReactNode
}

export default class extends React.Component<Props, {}> {

	// constructor(props: Props) {
	// 	super(props);
	// }

	render() {
		return (
			<MediaQuery minWidth={DEVICE_WIDTH_TABLET} maxWidth={DEVICE_WIDTH_DESKTOP-1}>
				{this.props.children}
			</MediaQuery>
		)
	}
}
