import * as React from 'react'
import { Station } from 'dash-dashradio-app-client-api';
import { Referrer, PlayingSelector, setPlaybackState, PlaybackState, setCurrentStation } from '../../reducers/PlayerReducer';
import { useSelector, useDispatch } from 'react-redux'
import { ReduxPlayerState } from '../../reducers';
import PlayerStoreProvider from '../../PlayerStore';
import StationHeart from './StationHeart';

interface Props {
    station: Station,
    referrerInfo?: { referrer: Referrer, referrerId?: string | number }
}

const GuideStationItem: React.FC<Props> = (props) => {
    const { station, referrerInfo } = props

    const playing: boolean = useSelector(PlayingSelector)
    const activeStation: Station | undefined = useSelector((state: ReduxPlayerState) => state.player.currentStation)
    const dispatch = useDispatch()

    const stationClick = () => {
        localStorage.setItem('currentStation', JSON.stringify(station))
        if (activeStation && activeStation.id === station.id && playing) {
            dispatch(setPlaybackState(PlaybackState.PAUSED))
        }
        else dispatch(setCurrentStation(station, PlaybackState.PLAYING, referrerInfo && referrerInfo.referrer, referrerInfo && referrerInfo.referrerId))
    }

    return (
        <div key={`Station_${station.id}`} className="logo-container schedule-container separator" >
            <StationHeart station={station} />
            <img src={station.colored_light_logo_url} style={{ height: '100%' }} alt="station Logo" onClick={stationClick} />
            <div style={{ display: 'flex', visibility:'hidden' }}>
                <StationNumberView station={station} />
            </div>
        </div>
    )
}

const StationNumberView: React.FC<{ station: any }> = (props) => {
    const { station } = props

    const genreNumber = station['genreNumber']
    const stationNumber = station['stationNumber']
    //const totalNumber = station['totalNumber']

    return (
        <div className="station-number">
            {genreNumber && stationNumber && `${genreNumber}${stationNumber}`}
        </div>
    )
}

export default React.memo((props: Props) => (
    <PlayerStoreProvider>
        <GuideStationItem {...props} />
    </PlayerStoreProvider>
))