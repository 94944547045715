import React, { useState } from 'react';
import {IconButton, useMediaQuery} from '@mui/material';
import clsx from 'clsx'
import Modal from '@mui/material/Modal';
import AppSimpleButton from './views/AppSimpleButton';
import { DashThemeDark } from '../Themes';
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/CloseOutlined';
import FadeInHOC from './ui-elements/FadeInHOC';
import ChangePasswordModal from './ChangePassword';
import LogoutModal from './LogoutDialog';
import { clearSession } from '../reducers/LoginReducer';
import { setFavorites } from '../reducers/AllStationsReducer';
import Backdrop from '@mui/material/Backdrop';
import dialogClasses from "./Dialog.module.css";

/*const useStyle = (smallScreen: boolean) => makeStyles({
	drawerStyle: {
		right: smallScreen ? '5vw' : 100,
		top: smallScreen ? '5vh' : 100,
		position: 'fixed',
		width: smallScreen ? '90vw' : 325,
		backgroundColor: '#fff',
		color: '#000',
		transition: 'all 250ms',
		overflow: 'auto',
		background: 'linear-gradient(180deg, rgba(1,63,94,1) 0%, rgba(6,16,44,1) 100%)',
		borderRadius: '14px'
	},
	darkBackground: {
		// backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--app-drawer-background-color'),
		// background: 'rgb(1,63,94)',
		// background: 'linear-gradient(180deg, rgba(1,63,94,1) 0%, rgba(6,16,44,1) 100%)'

	},
	iconStyle: {
		width: 24,
		height: 24,
		objectFit: "contain"
	},
	flexbox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingTop: 16,
		paddingBottom: 16,
		paddingLeft: 16,
		paddingRight: 8,
	},
	drawerTitle: {
		fontFamily: 'Lato',
		fontWeight: 900,
		fontSize: '1.5625rem',
		color: '#fff',
		letterSpacing: -0.32,
		flex: 1,
	},
	content: {
		paddingTop: 24,
		paddingBottom: 16,
		paddingLeft: 24,
		paddingRight: 24,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	error: {
		color: '#f00',
	},

	link: {
		cursor: 'pointer',
		"&:hover": {
			textDecoration: "underline",
		},
	},
	blackText: {
		color: getComputedStyle(document.documentElement).getPropertyValue('--primary-black'),
	},
	blueText: {
		color: '#1DE9B6',
	},
})()*/

interface Props {
	onClose: () => void,
	onChoose: (loggedOut?: boolean) => void
}


export const LoggedInDialog: React.FC<Props> = (props) => {
	const { onClose, onChoose } = props
	const smallScreen = useMediaQuery('(max-width: 600px)');

	const [logoutConfDialogOpen, setLogoutConfDialogOpen] = useState(false);
	const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);

	const handleLogout = () => {
		dispatch(clearSession())
		dispatch(setFavorites([]))
		// setExpanded(false)
		localStorage.clear()
		setLogoutConfDialogOpen(false)
		onChoose(true)
	}

	const dispatch = useDispatch()


	return (
		<>
			<ChangePasswordModal
				open={changePasswordDialogOpen}
				onClose={() => setChangePasswordDialogOpen(false)}
			/>

			<LogoutModal
				open={logoutConfDialogOpen}
				onClose={() => setLogoutConfDialogOpen(false)}
				onYes={handleLogout}
			/>
			<div className='dialog-popups logged-in' style={{ overflowX: 'hidden' }}>
				<div className={clsx(dialogClasses.darkBackground, dialogClasses.flexbox)}>
					<div className={dialogClasses.drawerTitle}>Choose your next step!</div>
					<DashThemeDark>
						<IconButton color="primary" onClick={() => onClose()}>
							<CloseIcon scale={24} />
						</IconButton>
					</DashThemeDark>
				</div>

				<div className={dialogClasses.content}>
					<div style={{ width: 64, paddingBottom: 12 }}></div>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
							<AppSimpleButton onClick={() => setChangePasswordDialogOpen(true)} popup>Change Password</AppSimpleButton>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
							<AppSimpleButton onClick={() => setLogoutConfDialogOpen(true)} popup>Logout</AppSimpleButton>
						</div>
					</div>
				</div>

			</div>
		</>
	)
}


export const LoggedInModal: React.FC<{ open: boolean } & Props> = (props) => {
	const handleClose = () => props.onClose()

	return (
		<Modal open={props.open} onClose={handleClose} BackdropProps={{ timeout: 800 }} BackdropComponent={Backdrop}>
			<div>
				<FadeInHOC>
					<LoggedInDialog onClose={handleClose} onChoose={props.onChoose} />
				</FadeInHOC>
			</div>

		</Modal>
	)
}

export default LoggedInModal
