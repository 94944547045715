import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const FallbackRouterApp: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/:page/*' element={<App/>}/>
                <Route index element={<Navigate to='/home'/>}/>
            </Routes>
        </BrowserRouter>
    )
}
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
document.addEventListener('DOMContentLoaded', function(event) {
    root.render(<FallbackRouterApp />);
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
