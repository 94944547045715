import * as React from 'react';
import { makeStyles } from '@mui/material/styles';
import clsx from 'clsx';
import {CSSProperties} from "react";

interface Props {
	w?: number,
	h?: number,
	children?: React.ReactNode,
}

const aspectRatioViewBackgroundStyle = (percentage): CSSProperties => ({
	position: 'relative',
	width: '100%',
	height: 0,
	paddingTop: `${percentage}%`,
});
const aspectRatioViewContentStyle: CSSProperties = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
	objectFit: 'contain',
}

export const AspectRatioView: React.FC<Props> = (props) => {
	const { w, h } = props;

	const ratio = ((h || 1) / (w || 1));
	let percentage = (ratio * 100).toString();
	const pIndex = percentage.indexOf('.')
	if(pIndex>0) {
		percentage = percentage.substr(0, pIndex)
	}

	return (
		<div style={aspectRatioViewBackgroundStyle(percentage)}>
			<div style={aspectRatioViewContentStyle}>
				{props.children}
			</div>
		</div>
	)
}

export default AspectRatioView
