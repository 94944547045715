import { combineReducers } from "redux";
import AllStationsReducer from "./AllStationsReducer";
import PlayerReducer from "./PlayerReducer";
import HighlightsReducer from "./HighlightsReducer";
import LoginReducer from "./LoginReducer";
import FeaturedStationsReducer from "./FeaturedStationsReducer";
import LocalSettingsReducer from "./LocalSettingsReducer";

const rootReducer = combineReducers({
    allStations: AllStationsReducer,
    highlights: HighlightsReducer,
    featuredStations: FeaturedStationsReducer,
    login: LoginReducer,
    localSettings: LocalSettingsReducer,
})
export default rootReducer

export type ReduxAppState = ReturnType<typeof rootReducer>


//player reducer
export const playerReducer = combineReducers({
    player: PlayerReducer
})
export type ReduxPlayerState = ReturnType<typeof playerReducer>
