import React, {CSSProperties} from 'react'
import clsx from 'clsx';

const AppText: React.FC<{ className?: any, noPadding?: boolean, style?: CSSProperties,onClick?: any, cursor?: string, popup?: boolean, children?: React.ReactNode }> = (props) => (
    <div className={props.popup ? 'popup-app-text' : clsx("App-Page-Text", props.className)}
         style={{ ...(Boolean(props.noPadding) && { paddingBottom: 0 }), cursor: props.cursor, ...props.style }}
         onClick={props.onClick}>
            {props.children}
    </div>
)

export default AppText
