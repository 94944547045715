import React, { useState, useEffect, Suspense } from 'react';
import './App.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { AppDrawer, Page, navigationEventEmitter, EVENT_NAVIGATION } from './components/AppDrawer';
import { ICON_LOGO_DASH } from './Icons';
import AppPlayer from './components/player/AppPlayer';
import { DashboardClientAPI, Session, PresetsAPI } from 'dash-dashradio-app-client-api';
import { EventEmitter } from 'events';
import PersistentReduxStoreProvider from './GlobalStore'
import { useSelector, useDispatch } from 'react-redux'
import LoadingPage from './pages/LoadingPage';
import { ReduxAppState } from './reducers';
import { clearSession } from './reducers/LoginReducer';
import { setFavorites } from './reducers/AllStationsReducer';
import FadeInHOC from './components/ui-elements/FadeInHOC';
import { IsDrawerOpenSelector, updateSettings, IsSideBarOpenSelector, IsNewSideBarOpenSelector } from './reducers/LocalSettingsReducer';
import { CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga';
import { TwitterCallback } from './pages/TwitterCallback';
import { SonosConnect } from './pages/sonos/SonosConnect';
import RadioPage from './pages/radio/RadioPage';
import { SocialLoginFailed } from './pages/SocialLoginFailed';
import { TroubleshootingSection, ContestRulesSection, PrivacyPolicySection, TermsOfServiceSection, ContactSection } from './pages/contact/ContactPage';
import { AppsPage } from './pages/apps/AppsPage';
import { getActivePage } from './components/views/AppDrawerComponents/Functions';
import { DashSlugHandler } from './components/views/AppDrawerComponents/DashSlugHandler';
import { AppPageContainer } from './components/views/AppDrawerComponents/AppPageContainer';
import { DashRoute } from './components/views/AppDrawerComponents/DashRoute';
import { initializeApp, getApps, getApp } from 'firebase/app';
import SocketIOMetadataObserver from './components/background-managers/SocketIOMetadataObserver';
import { DiscordCallback } from './pages/DiscordCallback';
const HomeHighlightsPage = React.lazy(() => import('./pages/home/HomeHighlightsPage'))
const NewsPage = React.lazy(() => import('./pages/news/NewsPage'))
const NewsDetailPage = React.lazy(() => import('./pages/news/NewsDetailPage'))

ReactGA.initialize('UA-53969906-1');
let page = getActivePage()
page && ReactGA.pageview(page)

export const session: Session = {
	//dev
	// apiURL: 'https://app-api.develop.dash-api.com',
	//prod
	apiURL: 'https://web-api.dash-api.com',
	//apiURL: 'http://localhost:8082',
	eventEmitter: (new EventEmitter()) as any
}

const config = {
	apiKey: "AIzaSyDVJudw_piz5PM1g_1ALeHYJh5rNVpP7Ew",
	authDomain: "dash-radio-85a65.firebaseapp.com",
	databaseURL: "https://dash-radio-85a65.firebaseio.com",
	projectId: "dash-radio-85a65",
	storageBucket: "dash-radio-85a65.appspot.com",
	messagingSenderId: "1069170196158",
	appId: "1:1069170196158:web:3797255e9da0efe8dca8ef"
};

const App: React.FC = () => {
	const appDrawerOpen = useSelector(IsDrawerOpenSelector);
	const sideBarOpen = useSelector(IsSideBarOpenSelector);
	const isNewSidebarOpen = useSelector(IsNewSideBarOpenSelector)

	const dispatch = useDispatch()
	const setAppDrawerOpen = (open: boolean) => {
		dispatch(updateSettings({ drawerOpen: open }))
	}

	const [, forceUp] = React.useState(0);
	const forceUpdate = () => forceUp(a => a + 1)

	const app = initializeApp(config);


	if (getApps().length == 0) {
		initializeApp(config);
	} else {
		getApp();
	}
	useEffect(() => {
		const onWindowStatePopped = (event: PopStateEvent) => {
			forceUpdate();		//re-render component
		}
		window.addEventListener("popstate", onWindowStatePopped);
		return () => window.removeEventListener("popstate", onWindowStatePopped);
	}, [])
	useEffect(() => {
		const listener = (data) => {
			forceUpdate();		//re-render component
			ReactGA.pageview(data.page)
		}
		navigationEventEmitter.addListener(EVENT_NAVIGATION, listener)
		return () => {
			navigationEventEmitter.removeListener(EVENT_NAVIGATION, listener)
		}
	}, []);
	useEffect(() => {
		if (!SocketIOMetadataObserver.getInstance().isConnected())
			SocketIOMetadataObserver.getInstance().connect();
	});
	return (
		<React.Fragment>
			<CookiesProvider>
				<CssBaseline />
				<AppDrawer drawerOpen={appDrawerOpen} setDrawerOpen={setAppDrawerOpen} />
				<AppPageContainer>
					<FadeInHOC>
						<MainPage />
					</FadeInHOC>
				</AppPageContainer>
				<AppPlayer drawerOpen={appDrawerOpen} isNewSideBarOpen={isNewSidebarOpen} />
				<PresetsLoader />
			</CookiesProvider>
		</React.Fragment>
	)
}

const MainPage: React.FC = (props) => {
	const dispatch = useDispatch()
	const sessionToken = useSelector((state: ReduxAppState) => (state.login.auth_token))
	session.session_token = sessionToken
	session.unauthorizedHandler = (error) => {
		dispatch(clearSession())
	}
	return (
		<Suspense fallback={<LoadingPage />}>
			<DashRoute route={Page.TWITTER_CALLBACK}>
				<TwitterCallback />
			</DashRoute>
			<DashRoute route={Page.DISCORD_CALLBACK}>
				<DiscordCallback />
			</DashRoute>
			<DashRoute route={Page.HOME}>
				{/* <HomeHighlightsPage /> */}
				<RadioPage />
			</DashRoute>
			<DashRoute route={Page.RESET_PASSWORD}>
				<HomeHighlightsPage token={window.location.href} />
			</DashRoute>
			<DashRoute route={Page.TWITTERLOGIN_FAILED}>
				<SocialLoginFailed socialName="Twitter" />
			</DashRoute>
			<DashRoute route={Page.DISCORDLOGIN_FAILED}>
				<SocialLoginFailed socialName="Discord" />
			</DashRoute>
			<DashRoute route={Page.LINK_ACCOUNT}>
				<HomeHighlightsPage link={true} />
			</DashRoute>
			{/*<DashRoute route={[Page.NEWS]}>
				<NewsPage />
	</DashRoute>*/}
			<DashRoute route={[Page.NEWS_DETAIL]}>
				<NewsDetailPage path={window.location.pathname || ""} />
			</DashRoute>
			{/*<DashRoute route={Page.VIDEO}>
				<VideoPage />
			</DashRoute>*/}

			{/* menu users */}
			<DashRoute route={Page.PROFILE}>
				<FallbackPage />
			</DashRoute>
			<DashRoute route={Page.POINTS}>
				<FallbackPage />
			</DashRoute>

			{/* Sonos Connect */}
			<DashRoute route={Page.SONOS_CONNECT}>
				<SonosConnect code={window.location.href} />
			</DashRoute>

			<DashRoute route={[Page.RADIO, Page.GUIDE, Page.GENRES, Page.ALL_STATIONS, Page.FAVORITES]}>
				{/* <DashRoute route={[Page.RADIO]}> */}
				<RadioPage />
			</DashRoute>

			{/* menu dash */}
			{/* <DashRoute route={[Page.ABOUT, Page.APPS, Page.CONTACT, Page.TROUBLESHOOTING, Page.CONTEST_RULES, Page.PRIVACY_POLICY, Page.TERMS_OF_SERVICE, Page.CORPORATE]}> */}
			<DashRoute route={Page.ABOUT}>
				<AppsPage />
			</DashRoute>

			<DashRoute route={Page.CONTACT}>
				<ContactSection />
			</DashRoute>

			<DashRoute route={Page.TROUBLESHOOTING}>
				<TroubleshootingSection />
			</DashRoute>

			<DashRoute route={Page.CONTEST_RULES}>
				<ContestRulesSection />
			</DashRoute>

			<DashRoute route={Page.PRIVACY_POLICY}>
				<PrivacyPolicySection />
			</DashRoute>

			<DashRoute route={Page.TERMS_OF_SERVICE}>
				<TermsOfServiceSection />
			</DashRoute>

			{/* handle slugs */}
			<DashSlugHandler />
		</Suspense>
	)
}

const PresetsLoader: React.FC = () => {
	const dispatch = useDispatch()
	const [presetsLoaded, setPresetsLoaded] = useState(false)

	//load presets if logged in
	if (!presetsLoaded && session.session_token) {
		PresetsAPI.getAllPresets(session)().then(presets => {
			setPresetsLoaded(true)

			//console.log("presets", presets)

			const dashboard = presets.filter(preset => Boolean(preset.station && preset.station.enabled)).map(preset => preset.station_id)
			// console.log("dashboard", dashboard)

			//apply dashboard stations from api to redux store
			dispatch(setFavorites(dashboard))
		})
			.catch(err => {
				console.error("presets", err)
			})
	}
	return <></>
}

const FallbackPage: React.FC = () => {
	return (
		<div className="App">
			<header className="App-header">
				<img src={ICON_LOGO_DASH} style={{ width: '33vw' }} alt="logo" />
				<p>
					LITT Live Web Player - coming soon
				</p>
				<a
					className="App-link"
					href="https://littlive.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					Open
				</a>
			</header>
		</div>
	)
}

export default () => (
	<DashboardClientAPI session={session}>
		<PersistentReduxStoreProvider>
			<App />
		</PersistentReduxStoreProvider>
	</DashboardClientAPI>
)
