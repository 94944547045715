import { Highlight } from "dash-dashradio-app-client-api";

interface HighlightsState {
    highlights: Highlight[]
}
const initialState: HighlightsState = { highlights: [] }

const ACTION_UPDATE = "actions-highlights-update"
interface ActionUpdate {
    type: typeof ACTION_UPDATE,
    highlights: Highlight[]
}
export type Actions = ActionUpdate

export function updateHighlights(highlights: Highlight[]): ActionUpdate {
    return {
        type: ACTION_UPDATE,
        highlights: highlights || []
    }
}


export default function HighlightsReducer(state = initialState, action: Actions): HighlightsState {
    var isEqual = false
    var stateUpdate
    switch(action.type) {
        case ACTION_UPDATE: {
            stateUpdate = {
                ...state,
                highlights: action.highlights
            }
            break
        }
    }
    if(stateUpdate) {
        try {
            if(JSON.stringify(state)===JSON.stringify(stateUpdate)) {
                isEqual = true
            }
        }
        catch(err) {
            //
        }
        if(!isEqual) {
            return stateUpdate      //return new state
        }
    }
    return state
}