import * as React from 'react';
import MediaQuery from 'react-responsive';
import { DEVICE_WIDTH_TABLET } from './ResponsivePage';

export default class extends React.Component<{children?: React.ReactNode}> {

	// constructor(props: Props) {
	// 	super(props);
	// }

	render() {
		return (
			<MediaQuery maxWidth={DEVICE_WIDTH_TABLET-1}>
				{this.props.children}
			</MediaQuery>
		)
	}
}
