import React from 'react'

const contentStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
}
const contentIcon: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}

const Content: React.FC<{ icons?: boolean, children?: React.ReactNode }> = (props) => (
    <div style={props.icons ? contentIcon : contentStyle}>{props.children}</div>
)

export default Content
