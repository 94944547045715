import React from 'react'
import HeadlineText from './HeadlineText';
import HeadlineSubText from './HeadlineSubText';
import clsx from 'clsx';

export interface Props {
    title: string,
    id?: string,
    subtitle?: string,
    subtitleExtend?: string,
    style?: any,
    center?: boolean,
    onClick?: any,
    mobileScreen?: boolean;
    smallScreen?: boolean;
    className?: string;
    guideHeader?: boolean;
}
class Headline extends React.Component<Props> {
    constructor(props) {
        super(props);
    }
    render() {
        return (<div className={this.props.guideHeader ? 'genre-text-container' : ''} style={{ ...(this.props.style), ...(this.props.center && !this.props.mobileScreen && { display: 'flex', justifyContent: 'center' }), ...(this.props.mobileScreen && { display: 'flex', justifyContent: 'center' }) }} onClick={this.props.onClick}>
            {/* <HeadlineText id={this.props.id} className={this.props.className + " " + this.props.guideHeader ? "guide" : ""} style={{ ...(!this.props.guideHeader && this.props.smallScreen && { width: 'calc(100% - 8.33% - 8.33%)', height: 51 }), ...(this.props.center && { textAlign: 'center', fontFamily: 'Lato', color: '#4C4C4C', fontWeight: 'bold' }) }}>{this.props.title.toUpperCase()}</HeadlineText> */}
            <HeadlineText id={this.props.id} className={clsx(this.props.className, this.props.guideHeader && (this.props.className + " guide"))} style={{ ...(!this.props.guideHeader && this.props.smallScreen && { width: 'calc(100% - 8.33% - 8.33%)', height: 51 }), ...(this.props.center && { textAlign: 'center', fontFamily: 'Lato', color: '#4C4C4C', fontWeight: 'bold' }) }}>{this.props.title.toUpperCase()}</HeadlineText>
            {this.props.subtitle && <HeadlineSubText style={{ ...(this.props.center && { textAlign: 'center', fontFamily: 'Helvetica', fontSize: '1.4rem', color: '#A1A4AB', fontWeight: 500 }) }}>{this.props.subtitle.toUpperCase()}</HeadlineSubText>}
            {this.props.subtitleExtend && <HeadlineSubText>{this.props.subtitleExtend}</HeadlineSubText>}
        </div>);
    }
}

export default Headline