import React from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import clsx from 'clsx'
import Modal from '@mui/material/Modal';
import AppSimpleButton from './views/AppSimpleButton';
import { DashThemeDark } from '../Themes';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import FadeInHOC from './ui-elements/FadeInHOC';
import dialogClasses from "./Dialog.module.css";


interface Props {
	onClose: () => void,
	onYes: () => void
}

export const LogoutDialog: React.FC<Props> = (props) => {
	const { onClose } = props
	const smallScreen = useMediaQuery('(max-width: 600px)');

	return (
		<div className='dialog-popups logged-in' style={{ overflowX: 'hidden' }}>
			<div className={clsx(dialogClasses.darkBackground, dialogClasses.flexbox)}>
				<div className={dialogClasses.drawerTitle}>Logout</div>
				<DashThemeDark>
					<IconButton color="primary" onClick={() => onClose()}>
						{/* <img className={classes.iconStyle} src={Icons.ICON_CHEVRON_RIGHT_WHITE} alt="" /> */}
						<CloseIcon scale={24} />
					</IconButton>
				</DashThemeDark>
			</div>

			<div className={dialogClasses.content}>
				<div className="dialog-title">Are you sure you want to log out?</div>
				<div style={{ width: 64, paddingBottom: 24 }}></div>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', width: '100%', }}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
						<AppSimpleButton onClick={() => props.onClose()} style={{ marginRight: '10px' }} popup>NO</AppSimpleButton>
						<AppSimpleButton onClick={() => {
							props.onYes()
						}} popup>YES</AppSimpleButton>
					</div>
				</div>


			</div>

		</div>
	)
}


export const LogoutModal: React.FC<{ open: boolean } & Props> = (props) => {
	const handleClose = () => props.onClose()
	const handleYes = () => props.onYes()
	// const {onForgotPasswordClick, onNoAccountWithEmail, onSignupClick } = props


	return (
		<Modal open={props.open} onClose={handleClose}>
			<div>
				<FadeInHOC>
					<LogoutDialog onClose={handleClose} onYes={handleYes} />
				</FadeInHOC>
			</div>
		</Modal>
	)
}

export default LogoutModal
