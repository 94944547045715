import React, { useEffect, useState } from "react";
import {
	Grid,
	makeStyles,
	CircularProgress,
	useMediaQuery,
	Hidden,
} from "@mui/material";
import AppPage from "../AppPage";
import HeadlineText from "../../components/views/HeadlineText";
import { session } from "../../App";
import HeadlineSubText from "../../components/views/HeadlineSubText";
import { ReduxAppState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { clearSession } from "../../reducers/LoginReducer";
import { setFavorites } from "../../reducers/AllStationsReducer";
import LoginDialogModal from "../../components/LoginDialog";
import SignupDialogModal from "../../components/SignupDialog";
import ForgotPasswordDialogModal from "../../components/ForgotPasswordDialog";
import { LoginAPI } from "dash-dashradio-app-client-api";
import { updateSettings, IsSideBarOpenSelector } from '../../reducers/LocalSettingsReducer';
import AppSimpleButton from "../../components/views/AppSimpleButton";


const styles = {
	button: {
		padding: '.7rem',
		marginRight: '.7rem'
	},
	buttonContainer: {
		marginTop: "2rem"
	}
};

interface Props {
	code: string
}

export const SonosConnect: React.FC<Props> = props => {
	const dispatch = useDispatch()
	const isSideBarOpen = useSelector(IsSideBarOpenSelector);

	const [selectedCode, setSelectedCode] = useState<string>();
	const [loggedIn, setLoggedIn] = useState(session.session_token ? true : false);
	const [loginDialogOpen, setLoginDialogOpen] = useState(false);
	const [signupDialogOpen, setSignupDialogOpen] = useState(false);
	const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false);
	// const [setAlertDialogEmail] = useState<string | undefined>(undefined);
	const [loader, setLoader] = useState(false)
	const [authentificated, setAuthentificated] = useState(false)

	const currentUser = useSelector((state: ReduxAppState) => state.login.user);

	const smallScreen = useMediaQuery("(max-width: 750px)");

	useEffect(() => {
		var split = props.code.split("=");
		setSelectedCode(split[split.length - 1]);
	}, [props.code]);

	useEffect(() => {
		if (isSideBarOpen) {
			dispatch(updateSettings({ sideBar: false }))
		}
	}, [isSideBarOpen, dispatch])


	const changeAccount = () => {
		dispatch(clearSession())
		dispatch(setFavorites([]))
		// setExpanded(false)
		localStorage.clear()
		setLoggedIn(false)
	}

	const handleLogin = () => {
		setLoginDialogOpen(true)
		setLoader(true)
	}

	const handleAuthenticate = () => {
		if(selectedCode && currentUser){
			setLoader(true)
			LoginAPI.linkSonos(session)(currentUser.id, selectedCode).then(data => {
				setLoader(false)
				setAuthentificated(true)
			})
		}
	}

	return (
		<React.Fragment>
			<LoginDialogModal
				open={loginDialogOpen}
				onClose={() => setLoginDialogOpen(false)}
				onSignupClick={() => {
					setLoginDialogOpen(false);
					setSignupDialogOpen(true);
				}}
				onNoAccountWithEmail={input => {
					// setAlertDialogEmail(input.email);
				}}
				onForgotPasswordClick={() => {
					setLoginDialogOpen(false);
					setForgotPasswordDialogOpen(true);
				}}
				sonos
			/>
			<SignupDialogModal
				open={signupDialogOpen}
				onClose={() => setSignupDialogOpen(false)}
				onBack={() => {
					setLoginDialogOpen(true);
					setSignupDialogOpen(false);
				}}
			/>

			<ForgotPasswordDialogModal
				open={forgotPasswordDialogOpen}
				onClose={() => setForgotPasswordDialogOpen(false)}
				onBack={() => {
					setLoginDialogOpen(true);
					setForgotPasswordDialogOpen(false);
				}}
			/>
			<AppPage withLogo>
				<Grid container direction="row" justifyContent="center">
					<Hidden smDown>
						<Grid item sm={1}></Grid>
					</Hidden>
					<Grid item xs={12} sm={10}>
						{/* {!smallScreen && <img src={ICON_LOGO_DASH} width="250rem" alt="Logo Dash" />} */}
						<HeadlineText
							style={{
								textAlign: "center",
								fontSize: "30px",
								...(!smallScreen && {
									marginTop: "55px"
								}),
								...(smallScreen && {
									marginTop: "59px"
								})
							}}
						>
							{(!loggedIn || !currentUser) && <span>Welcome!</span>}
							{(loggedIn && currentUser) && (
								<span>
									You are logged in as {currentUser.first_name}{" "}
									{currentUser.last_name}
								</span>
							)}
						</HeadlineText>
						<HeadlineSubText style={{ textAlign: "center" }}>
							{(!loggedIn || !currentUser) && (
								<span>Please login in order to connect with Sonos.</span>
							)}
							{(loggedIn && currentUser && !authentificated) && <span>You can now connect with Sonos</span>}
							{authentificated && <span>You are now authentificated!</span>}
						</HeadlineSubText>
						{(!loggedIn || !currentUser) && (
							<Grid container justifyContent="center" style={styles.buttonContainer}>
								<Grid item>
									{loader && <CircularProgress color="primary" />}
									{/* {!loader && <AppButton onClick={handleLogin} classname={classes.button}>Login</AppButton>} */}
									{!loader && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
										<AppSimpleButton onClick={handleLogin} popup>Login</AppSimpleButton>
									</div>}
								</Grid>
							</Grid>
						)}
						{(loggedIn && currentUser) && (
							<Grid container justifyContent="center" style={styles.buttonContainer}>
								<Grid item style={{ marginRight: '2rem' }}>
									{loader && <CircularProgress color="primary" />}
									{/* {!loader && <AppButton onClick={handleAuthenticate} classname={classes.button}>Authenticate</AppButton>} */}
									{!loader && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
										<AppSimpleButton onClick={handleAuthenticate} popup>Authenticate</AppSimpleButton>
									</div>}
								</Grid>
								<Grid item>
									{/* <AppButton onClick={changeAccount} classname={classes.button}>Change Account</AppButton> */}
									<AppSimpleButton onClick={changeAccount} popup>Change Account</AppSimpleButton>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Hidden smDown>
						<Grid item sm={1}></Grid>
					</Hidden>
				</Grid>
			</AppPage>
		</React.Fragment>
	);
};

export default React.memo(SonosConnect);
