import * as React from 'react'
import { Station, Schedules } from 'dash-dashradio-app-client-api';
import { Referrer, PlayingSelector, setPlaybackState, PlaybackState, setCurrentStation } from '../../reducers/PlayerReducer';
import { useSelector, useDispatch } from 'react-redux'
import { ReduxPlayerState } from '../../reducers';
import PlayerStoreProvider from '../../PlayerStore';
import moment from 'moment';
import MetadataObserver, { getSongSlogan } from '../../components/player/MetadataObserver';

interface Props {
    station: Station,
    referrerInfo?: { referrer: Referrer, referrerId?: string | number },
    schedules: Schedules[],
    stationColor: string,
    currentDay: moment.Moment,
    hours: number,
    cellWidth: number
}

const GuideProgrammBreak: React.FC<Props> = (props) => {
    const { station, referrerInfo, schedules, stationColor, currentDay, hours, cellWidth } = props

    const playing: boolean = useSelector(PlayingSelector)
    const activeStation: Station | undefined = useSelector((state: ReduxPlayerState) => state.player.currentStation)
    const dispatch = useDispatch()

    const stationClick = () => {
        localStorage.setItem('currentStation', JSON.stringify(station))
        if (activeStation && activeStation.id === station.id && playing) {
            dispatch(setPlaybackState(PlaybackState.PAUSED))
        }
        else dispatch(setCurrentStation(station, PlaybackState.PLAYING, referrerInfo && referrerInfo.referrer, referrerInfo && referrerInfo.referrerId))
    }

    let l = schedules.length
    let progBreakInfo: {
        start_Date: moment.Moment,
        end_Date: moment.Moment
    }[] = []

    if (l === 0) {
        progBreakInfo.push({ start_Date: currentDay, end_Date: moment(currentDay).add(hours, 'hour') })
    } else {
        schedules.forEach((schedule, index) => {
            let start: moment.Moment;
            let end: moment.Moment;
            if (index === 0) {
                start = currentDay
            } else {
                start = moment(schedules[index - 1].end_date)
            }
            end = (moment(schedule.start_date) as any) < (currentDay as any) ? currentDay : moment(schedule.start_date)
            progBreakInfo.push({ start_Date: start, end_Date: end })
            if (index === l - 1) {
                start = moment(schedule.end_date)
                end = moment(currentDay).add(hours, 'hour')
                progBreakInfo.push({ start_Date: start, end_Date: end })
            }
        })
    }

    return (
        <>
            {
                progBreakInfo.map((info, index) => {
                    let start = info.start_Date
                    let end = info.end_Date
                    let t = ((start.valueOf() > (moment(currentDay).add(hours / 24, 'day').valueOf() + 3600 * 1000) ? (moment(currentDay).add(hours / 24, 'day') as any) : (end as any)) - ((start as any) >= (currentDay as any) ? (start as any) : currentDay)) / 1000 / 3600 * cellWidth
                    let left = ((start as any) - (currentDay as any)) / 1000 / 3600 * cellWidth
                    return (
                        <div key={`Program_Break_${station.id}_${index}`} className="program" style={{ minWidth: t, maxWidth: t, left: left > 0 ? left : 0 }} onClick={stationClick}>
                            {t > cellWidth * 0.3 &&
                                <MetadataObserver stationId={station.id}>
                                    {(song) => <>
                                        <div style={{ backgroundColor: stationColor, width: "1px" }} className={schedules.length === 0 ? 'fixed-program left1' : ''}></div>
                                        <div className="program-content" style={{ backgroundColor: "#A7A7AB1A", width: t }}>
                                            <span className={`program-font station-bold ${schedules.length === 0 ? 'fixed-program left11' : ''}`} style={{ width: schedules.length === 0 ? "max-content" : t - 21 }}>{station.name.replace(/\u00AE/g, "")}</span>
                                            { song &&
                                                <span className={`program-font ${schedules.length === 0 ? 'fixed-program left11' : ''}`} style={{ width: schedules.length === 0 ? "max-content" : t - 21 }}>{getSongSlogan(song)}</span>
                                            }
                                        </div> 
                                    </>}
                                </MetadataObserver>}
                        </div>
                    )
                })}
        </>
    )
}


export default React.memo((props: Props) => (
    <PlayerStoreProvider>
        <GuideProgrammBreak {...props} />
    </PlayerStoreProvider>
))