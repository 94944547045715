import React, { useEffect } from "react";
import { LoginAPI } from "dash-dashradio-app-client-api";
import { Page } from "../components/AppDrawer";
import LoadingBox from "../components/LoadingBox";
import { setPageActive } from "../components/views/AppDrawerComponents/Functions";
import { DashThemeLight } from "../Themes";
import AppPage from "./AppPage";
import { session } from "../App";
import { useCookies } from "react-cookie";
import LoginHelper from "../helper/LoginHelper";
import {useDispatch, useSelector} from "react-redux";
import {ReduxAppState} from "../reducers";
import {setDiscordCSRFToken} from "../reducers/LoginReducer";

export const DiscordCallback: React.FC = (props) => {
    const [cookies,, removeCookie] = useCookies(["discordCSRToken"]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (Boolean(window.location.search)) {
            const parsed = new URLSearchParams(window.location.search.slice(1));
            const code = parsed.get("code");
            const state = parsed.get("state");
            const cookieState = cookies.discordCSRToken;
            removeCookie("discordCSRToken");
            if (code && state && state === cookieState) {
                LoginAPI.discordLogin(session, code).then(data => {
                    LoginHelper.handleOAuthCallbackLogin(data, dispatch);
                }).catch(error => setPageActive(Page.DISCORDLOGIN_FAILED));
                return;
            } else {
                console.log("missmatch state " + state + " :: " + cookieState + " :: " + code);
            }
        }
        setPageActive(Page.DISCORDLOGIN_FAILED)
    }, []);

    return (
        <React.Fragment>
            <DashThemeLight>
                <AppPage withLogo home>
                    <LoadingBox loading />
                </AppPage>
            </DashThemeLight>
        </React.Fragment>
    )
}
