import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import { createTheme, IconButton, Slider, withStyles, LinearProgress, CircularProgress } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/PauseCircleOutline';
import { View } from '../ui-elements/View';
import { ICON_PLAYER_VOLUME_WHITE, ICON_DIRTY_WHITE, ICON_CLEAN_WHITE, ICON_CLEAN_GREY, ICON_DIRTY_GREY, IMG_DEFAULT_COVER, ICON_HEART_USED_WHITE, ICON_NEXT, ICON_PREV, ICON_EXPLICIT, ICON_CLEAN, ICON_HEART_UNUSED_WHITE } from '../../Icons';
import Sound from 'react-sound';
import { MaterialColorTheme } from '../../Themes';
import { Station } from 'dash-dashradio-app-client-api';
import { useSelector, useDispatch } from 'react-redux'
import { PlayingSelector, UseCleanStreamSelector, setVolume, StreamUrlSelector, HasCleanStreamSelector, setPlaybackState, PlaybackState, setUseCleanStream, setCurrentStation } from '../../reducers/PlayerReducer';
import { ReduxPlayerState } from '../../reducers';
import PlayerStoreProvider from '../../PlayerStore';
import MetadataObserver from './MetadataObserver';
import { SongHistoryDrawer } from '../SongHistoryDrawer';
import PlayNextStationHandler from './PlayNextStationHandler';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Img} from 'react-image'

import './AppPlayer.scss'
import StationItemHeart from '../StationItemHeart';
import {ThemeProvider} from "@mui/material/styles";

interface Props {
    drawerOpen: boolean,
    isNewSideBarOpen: boolean
}
export const AppPlayer: React.FC<Props> = (props) => {
    const barTheme = createTheme({
        palette: {
            primary: {
                main: '#ffffff'
            },
            secondary: {
                main: '#656565'
            }
        }
    })
    const buttonTheme = createTheme({
        palette: {
            primary: {
                main: '#222223'
            },
            secondary: {
                main: '#A7A7AB'
            }
        }
    });

    const playing = useSelector(PlayingSelector)
    const station = useSelector((state: ReduxPlayerState) => state.player.currentStation)
    const referrer = useSelector((state: ReduxPlayerState) => state.player.referrer)
    const referrerId = useSelector((state: ReduxPlayerState) => state.player.referrerId)
    // const active = useSelector((state: ReduxAppState) => Boolean(state.allStations.favorites && station.id && state.allStations.favorites.includes(station.id)))
    const useCleanStream = useSelector(UseCleanStreamSelector)
    const volume = useSelector((state: ReduxPlayerState) => state.player.volumeLevel)
    const dispatch = useDispatch()
    const hasCleanStream = useSelector(HasCleanStreamSelector)
    const playerIsIdle = Boolean(useSelector((state: ReduxPlayerState) => state.player.playbackState === PlaybackState.IDLE))
    const [songHistoryOpen, setSongHistoryOpen] = useState(false)
    const [musicWidth, setMusicWidth] = useState(0)
    const mobileScreen = useMediaQuery('(max-width: 480px)')
    const mobileScreen2 = useMediaQuery('(max-width: 600px)')
    const tabletScreen = useMediaQuery('(max-width: 960px)')
    const breakpointOne = useMediaQuery('(max-width: 800px)')
    const breakpointThree = useMediaQuery('(max-width: 375px)')
    const breakpointFour = useMediaQuery('(max-width: 320px)')
    const breakpointVolume = useMediaQuery('(max-width: 1050px')

    const marqueeRef: any = useRef(null)

    useEffect(() => {
        (window as any).soundManager.setup({ debugMode: false })
    }, [])

    useEffect(() => {
        let activeStation = localStorage.getItem('currentStation')
        activeStation = activeStation && activeStation.toString()
        if (activeStation) {
            let station = JSON.parse(activeStation)
            dispatch(setCurrentStation(station, PlaybackState.PAUSED))
        }
    }, [dispatch])

    const handleVolumeChange = (event, value) => {
        dispatch(setVolume(value))
    }

    const handlePlayPause = () => {
        dispatch(setPlaybackState(playing ? PlaybackState.PAUSED : PlaybackState.PLAYING))
    }
    const handleStationNext = (station: Station) => {
        //PlaybackManager.getInstance().nextStation()
        if (station) {
            dispatch(setCurrentStation(station, PlaybackState.PLAYING, referrer, referrerId))
        }
    }
    const handleStationPrevious = (station: Station) => {
        //PlaybackManager.getInstance().previousStation()
        if (station) {
            dispatch(setCurrentStation(station, PlaybackState.PLAYING, referrer, referrerId))
        }
    }

    const handleUseCleanStream = (useCleanStream: boolean) => {
        if (station) {
            dispatch(setUseCleanStream(station.id, useCleanStream))
        }
    }

    useEffect(() => {
        function handler() {
            setMusicWidth(marqueeRef && marqueeRef.current && marqueeRef.current.offsetWidth)
        }

        window.addEventListener('resize', handler)
        window.addEventListener('load', handler)

        return () => {
            window.removeEventListener('resize', handler)
            window.removeEventListener('load', handler)
        }
    }, [])

    if (playerIsIdle) {
        return <></>        //return empty view
    }


    return (
        <ThemeProvider theme={barTheme}>
            <SongHistoryDrawer drawerOpen={songHistoryOpen} setDrawerOpen={setSongHistoryOpen} />
            <AppBar position='fixed' color='primary' className="appbar">
                <AnimatedPlayer playing={playing} volume={volume} station={station} />
                <ThemeProvider theme={buttonTheme}>
                    {tabletScreen && !breakpointFour &&
                        <div className="station-logo-left" onClick={() => window.location.pathname = ("/" + (station as Station).slug.toLowerCase())}>
                            <Img
                                src={[(station as Station).default_cover_url, IMG_DEFAULT_COVER]}
                                loader={<div style={{}}><CircularProgress color="primary" /></div>}
                                style={{ height: '100%', objectFit: 'contain' }}
                                alt="" />
                        </div>
                    }
                    <div className="spacing" />
                    <div className="music-dataview-container" id="music-dataview-container" ref={marqueeRef}>
                        <MusicMetadataView currentStation={station}
                            width={musicWidth}
                            onClick={() => window.location.pathname = ("/" + (station as Station).slug).toLowerCase()} />
                    </div>
                    <div className="main-container">
                        {!mobileScreen2 &&
                            <div className="heart-lyrics-coontainer">
                                <div className="spacing" />
                                <div className="station-heart" style={{ width: 'unset' }}>
                                    {/* <img src={active ? ICON_HEART_USED_WHITE : ICON_HEART_UNUSED_WHITE} className="image-heart" /> */}
                                    <StationItemHeart station={station} appPlayer className="image-heart" />
                                    {/* <img src={ICON_HEART_USED_WHITE} className="image-heart" /> */}
                                </div>
                                <div className="spacing" />
                                <MetadataObserver stationId={station?.id}>
                                    {(song) => {
                                        return (
                                            <div className="lyrics-container" onClick={() => { window.open(`https://genius.com/search?q=${(song && encodeURIComponent(`${song.title} ${song.artist}`)) || ""}`, '_blank') }}>
                                                <div className="lyrics">Lyrics</div>
                                            </div>
                                        )
                                    }}
                                </MetadataObserver>
                            </div>
                        }
                        <div className="play-next-prev-container">
                            <div className="spacing" />
                            <PlayNextStationHandler currentStation={station} lastStation={true} currentReferrer={referrer} currentReferrerId={referrerId}>
                                {
                                    previousStation => <IconButton className="prev-next prev" onClick={() => handleStationPrevious(previousStation)}><img src={ICON_PREV} className="icon-style" /></IconButton>
                                }
                            </PlayNextStationHandler>
                            <IconButton onClick={handlePlayPause} className="play-pause">
                                <PlayPauseIcon playing={playing} />
                            </IconButton>

                            <PlayNextStationHandler currentStation={station} lastStation={false} currentReferrer={referrer} currentReferrerId={referrerId}>
                                {
                                    nextStation => <IconButton className="prev-next next" onClick={() => handleStationNext(nextStation)}><img src={ICON_NEXT} className="icon-style" /></IconButton>
                                }
                            </PlayNextStationHandler>

                        </div>
                        {mobileScreen2 &&
                            <div className="heart-lyrics-coontainer mobile">
                                <div className="spacing" />
                                <div className="station-heart" style={{ width: 'unset' }}>
                                    {/* <img src={ICON_HEART_USED_WHITE} className="image-heart" /> */}
                                    <StationItemHeart station={station} appPlayer className="image-heart" />
                                </div>
                            </div>
                        }
                        {!mobileScreen && !tabletScreen && !breakpointVolume &&
                            <div className="volume-slider-container">
                                <div className="spacing" />
                                <img className="icon-style-small" src={ICON_PLAYER_VOLUME_WHITE} alt="" />
                                <div className="spacing" />
                                <Slider style={{color: "white", height: "2px"}} value={volume} onChange={handleVolumeChange} aria-labelledby="continuous-slider" className="volume-slider" />
                            </div>
                        }
                        <View visible={hasCleanStream}>
                            {!mobileScreen && !tabletScreen &&
                                <div className="explicit-clean-container">
                                    <div className="spacing" />
                                    <AppPlayerButton onClick={() => handleUseCleanStream(false)} style={{ marginRight: 20, cursor: 'pointer' }} active={!useCleanStream}>{!breakpointOne ? 'Explicit' : <div onClick={() => handleUseCleanStream(false)}><img src={useCleanStream ? ICON_CLEAN_WHITE : ICON_CLEAN_GREY} style={{ height: 18 }} /></div>}</AppPlayerButton>
                                    <AppPlayerButton onClick={() => handleUseCleanStream(true)} style={{ cursor: 'pointer' }} active={useCleanStream}>{!breakpointOne ? 'Clean' : <div onClick={() => handleUseCleanStream(true)}><img src={!useCleanStream ? ICON_DIRTY_WHITE : ICON_DIRTY_GREY} style={{ height: 18 }} /></div>}</AppPlayerButton>
                                </div>
                            }
                            {tabletScreen &&
                                <div className="explicit-clean-container mobile">
                                    <div className="spacing" />
                                    <div onClick={() => handleUseCleanStream(!useCleanStream)} style={{ cursor: 'pointer' }}><div className="clean-dirty-img-container"><img src={!useCleanStream ? ICON_EXPLICIT : ICON_CLEAN} className="clean-dirty-img" /></div></div>
                                    <div className="spacing-logo" />
                                </div>
                            }
                        </View>
                    </div>
                    <div className="image-container" onClick={() => window.location.pathname = ("/" + (station as Station).slug).toLowerCase()}>
                        <img src={station && station.large_logo_url} alt="station logo" className="station-logo" />
                        <div className="spacing" />
                    </div>
                    {/* } */}
                </ThemeProvider>
            </AppBar>
        </ThemeProvider >
    )
}

const PlayPauseIcon: React.FC<{ playing: boolean }> = React.memo((props) => {
    return props.playing ? <PauseIcon className="icon-style-play" color="secondary" /> : <PlayIcon className="icon-style-play" color="secondary" />
})

const AppPlayerButton: React.FC<{ active: boolean, style?: React.CSSProperties, className?: any, onClick?: any, children?: React.ReactNode }> = React.memo((props) => {
    return <span onClick={props.onClick} className={props.className ? props.className : (props.active ? "App-Player-Button-Text-Active explicit-clean ex" : "App-Player-Button-Text explicit-clean")} style={props.style}>{props.children}</span>
})

const MusicMetadataView: React.FC<{ width?: any, onClick?: any, currentStation: Station | undefined}> = (props) => {
    const bigScreen = useMediaQuery('(min-width: 1450px)')

    return (
        <MetadataObserver stationId={props.currentStation?.id}>
            {(song) =>  (
                <>
                    {!bigScreen ?
                        <div className="marquee-container" style={{ width: props.width === 0 ? 100 : props.width }} onClick={props.onClick}>
                            <div id="marquee1" className="marquee" style={{ maxWidth: props.width === 0 ? 100 : props.width }}><span>{(song && song.title) || ""}</span></div>
                        </div>
                        :
                        <span className="App-Player-Song-Title-Text" id='title-text' onClick={props.onClick}>
                            {(song && song.title) || ""}
                        </span>
                    }
                    {!bigScreen ?
                        <div className="marquee-container" style={{ width: props.width === 0 ? 100 : props.width }} onClick={props.onClick}>
                            {/* <Marquee direction="left" speed={0.03} delay={1000} >
                                <span className="App-Player-Song-Artist-Text" id='artist-text'>
                                    {(song && song.artist && 'by ' + song.artist) || ""}
                                </span>
                            </Marquee> */}
                            <div id="marquee2" className="marquee artist" style={{ maxWidth: props.width === 0 ? 100 : props.width }}><span className="artist">{(song && song.artist && 'by ' + song.artist) || ""}</span></div>
                        </div>
                        :
                        <span className="App-Player-Song-Artist-Text" id='artist-text' onClick={props.onClick}>
                            {(song && song.artist && 'by ' + song.artist) || ""}
                        </span>

                    }
                </>
            )}
        </MetadataObserver>
    )
}

const AnimatedPlayer: React.FC<{ playing: boolean, volume: number, station: Station | undefined }> = React.memo((props) => {
    const { playing, volume, station } = props
    const [buffering, setBuffering] = useState(false);

    const streamUrl = useSelector(StreamUrlSelector)

    const loaderStyle: React.CSSProperties = {
        margin: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0
    }

    return (
        <>
            {
                buffering &&
                <MaterialColorTheme primary={(station && station.genreObj && station.genreObj.genre_accent_color) || '#000000'} renderChildren={ref =>
                    <LinearProgress ref={ref} color="primary" variant="indeterminate" style={loaderStyle} />}/>

            }
            {
                playing &&
                <Sound
                    url={streamUrl}
                    playStatus={Sound.status.PLAYING}
                    volume={volume}
                    onLoading={() => { }}
                    onPlaying={() => { }}
                    onFinishedPlaying={() => { }}
                    onBufferChange={(buffering) => { setBuffering(buffering) }} />
            }
        </>
    )
})

export default React.memo((props: Props) => (
    <PlayerStoreProvider>
        <AppPlayer {...props} />
    </PlayerStoreProvider>
))
