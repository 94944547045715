import React, { useEffect } from "react";
import { DashThemeLight } from "../Themes";
import AppPage from './AppPage';
import HeadlineBigText from "../components/views/HeadlineBigText";
import { useDispatch, useSelector } from "react-redux";
import { updateSettings, IsSideBarOpenSelector } from '../reducers/LocalSettingsReducer';
import { Page } from "../components/AppDrawer";
import { setPageActive } from "../components/views/AppDrawerComponents/Functions";

interface Prop {
    socialName: string;
}

export const SocialLoginFailed: React.FC<Prop> = (props) => {

  const dispatch = useDispatch();
  const isSideBarOpen = useSelector(IsSideBarOpenSelector);

  useEffect(() => {
    if (isSideBarOpen) {
      dispatch(updateSettings({ sideBar: false }))
    }

    const timer = setTimeout(() => {
      setPageActive(Page.HOME)
    }, 5000);
    return () => clearTimeout(timer);
  }, [isSideBarOpen, dispatch])


  return (
    <React.Fragment>
      <DashThemeLight>
        <AppPage withLogo>
          <div style={{ width: '100%', background: 'linear-gradient(180deg, rgba(254,97,97,.3) 0%, rgba(254,97,97,.3) 100%)', padding: '5rem' }}>
            <HeadlineBigText style={{ textAlign: 'center' }}>{props.socialName} login failed</HeadlineBigText>
            <HeadlineBigText style={{ textAlign: 'center' }}>Please try again!</HeadlineBigText>
          </div>
        </AppPage>
      </DashThemeLight>
    </React.Fragment>
  )

}

export default React.memo(SocialLoginFailed)