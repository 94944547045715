import React from 'react'
import Center from './ui-elements/Center';
import * as Icons from '../Icons';
import classes from "./SocialLoginButton.module.css";

const COLOR_FACEBOOK = '#3A5998'
const COLOR_DISCORD = '#6c89e0'
const COLOR_TWITTER = '#4199FC'
const COLOR_BLACK = '#000'

interface OnClickProps {
	onClick: () => void,
}
interface Props {
	title: string,
	icon: any,
	backgroundColor?: string,
	twitter?: boolean
}
const SocialLoginButton: React.FC<Props & OnClickProps> = (props) => {
	const { onClick, title, icon, backgroundColor, twitter } = props;

	return (
		<div className={classes.root} style={{ background: backgroundColor }} onClick={onClick}>
			<Center>
				<img src={icon} className={classes.icon} style={{ ...(twitter && { marginBottom: -5.5 }) }} alt="social login" />
				<div className='social-button'>{title}</div>
			</Center>
		</div>
	)
}

export const FacebookLoginButton: React.FC<OnClickProps> = (props) => (
	<SocialLoginButton onClick={props.onClick} title="Sign In with Facebook" icon={Icons.ICON_LOGO_FACEBOOK} backgroundColor={COLOR_FACEBOOK} />
)
export const TwitterLoginButton: React.FC<OnClickProps> = (props) => (
	<SocialLoginButton onClick={props.onClick} title="Sign In with Twitter" icon={Icons.ICON_LOGO_TWITTER} backgroundColor={COLOR_TWITTER} />
)
export const AppleLoginButton: React.FC<OnClickProps> = (props) => (
	<SocialLoginButton onClick={props.onClick} title="Sign In with Apple" icon={Icons.ICON_LOGO_APPLE_WHITE} backgroundColor={COLOR_BLACK}/>
)
export const DiscordLoginButton: React.FC<OnClickProps> = (props) => (
	<SocialLoginButton onClick={props.onClick} title="Sign In with Discord" icon={Icons.ICON_LOGO_DISCORD} backgroundColor={COLOR_DISCORD}/>
)
