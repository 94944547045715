import React from 'react'
import { makeStyles } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'
import { GenresSelector } from '../../reducers/AllStationsReducer';
import { useMediaQuery } from '@mui/material';
import classes from "./GenresSlider.module.css";

export interface Props {
    onGenreClick: (genre: any) => void
}


const GenresSlider: React.FC<Props> = (props) => {
    const genres = useSelector(GenresSelector)
    const mobileLayout = useMediaQuery('(max-width: 480px)')
    // const smallScreen = useMediaQuery("(max-width: 1280px)");
    const smallScreen = useMediaQuery("(max-width: 960px)");
    const mobileScreen = useMediaQuery('(max-width: 480px)')

    return (
        <div className={classes.root}>
            <div className={classes.horizontalFlexbox} style={{ ...(mobileScreen && { paddingLeft: 16, paddingRight: 16 }) }}>
                <div className={classes.sliderContainer}>
                    <div className={classes.slider} style={{ ...((smallScreen && !mobileLayout) && { justifyContent: 'flex-start', paddingLeft: '3rem', paddingRight: '3rem' }) }}>
                        <AllGenresButton {...{ classes, onGenreClick: props.onGenreClick }} style={{ display: 'flex', ...((mobileLayout && smallScreen) ? { width: '100%', backgroundColor: '#FF3F90', marginRight: 0, color: 'white', paddingLeft: '3rem', paddingRight: '3rem' } : { backgroundColor: '#FF3F90', color: 'white' }) }} />
                        {genres && genres.map((genre, index) => {
                            return <div key={index} style={{ display: 'flex', ...(mobileLayout && { width: 'calc(33.3333% - 5px)', marginRight: 5, height: 40, marginBottom: 8 }) }}><div key={`genre_slider_item_${genre.id}`} className={classes.headlineText} style={{ display: 'flex', whiteSpace: 'break-spaces', ...(mobileLayout && { fontSize: 15, width: '100%', justifyContent: 'center', whiteSpace: 'break-spaces', ...(smallScreen && {}) }) }} onClick={() => props.onGenreClick(genre)}>{genre['genre_name']}</div></div>
                        })}

                    </div>
                </div>
            </div>
            <div style={{ flexShrink: 0, height: 24 }} />
        </div>

    )
}

const AllGenresButton: React.FC<{ classes: any, onGenreClick: (genre: any) => void, style?: any }> = (props) => (
    <><div className={props.classes.headlineText} style={props.style} onClick={() => props.onGenreClick('allstations')}>All Genres</div>
    </>
)

export default GenresSlider
