import React, {useState, useRef, CSSProperties} from 'react';
import AppSimpleInput from '../../components/views/AppSimpleInput';
import clsx from 'clsx';
import AppSimpleButton from '../../components/views/AppSimpleButton';
import axios from 'axios'
import { RenderedAlertDialog, AlertDialogProps } from '../../components/AlertDialog';
import LoadingBox from '../../components/LoadingBox'
import { DashThemeLight } from '../../Themes';

const styles: {[key: string]: CSSProperties} = {
    text: {
        fontFamily: 'Lato',
        fontWeight: 400,        /* regular */
        fontSize: '0.875rem',
        color: '#606060',
        letterSpacing: '-0.2px',
        textAlign: 'start',
        background: '#0000',
        marginBottom: 0,
        width: '100%',
    },
    multiline: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    box: {
        width: '100%',
        border: '1px solid #A7A7AB',
        marginBottom: 16,
        overflow: 'hidden',
        borderRadius: 23
    },
};

const ContactForm: React.FC = (props) => {
    const nameInput = useRef(null)
    const emailInput = useRef(null)
    const messageInput = useRef(null)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [alertDialogProps, setAlertDialogProps] = useState<AlertDialogProps | undefined>(undefined)

    const onSubmitForm = () => {
        if (name.length > 0 && email.length > 0 && message.length > 0) {
            setLoading(true)
            axios.post('https://6qq2zjxkze.execute-api.us-west-1.amazonaws.com/production/contact', {
                from: email,
                message,
                name,
            }, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": "lNkZE4LDV82ESpTIRm2YY7jvBdTwDIF53RHS9CtY",		//MD token
                    }
                }).then(response => {
                    setLoading(false)
                    setName("")
                    setEmail("")
                    setMessage("")
                    setAlertDialogProps({
                        title: "Contact",
                        message: "Your message has been submitted successfully."
                    })
                })
                .catch(err => {
                    setLoading(false)
                    setAlertDialogProps({
                        title: "Contact",
                        message: "Couldn't submit your message. Please try again."
                    })
                })
        }
        else {
            setAlertDialogProps({
                title: "Contact",
                message: "Please fill in all fields."
            })
        }
    }

    return (
        <DashThemeLight>
            <div>
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <div style={{...styles.box, marginRight: '1rem'}}>
                        <AppSimpleInput
                            inputRef={nameInput}
                            placeholder="Name"
                            style={styles.text}
                            value={name}
                            onChange={(value) => setName(value)}
                            onSubmit={() => {
                                emailInput && (emailInput.current as any).focus()
                            }} />
                    </div>
                    <div style={{...styles.box, marginLeft: '1rem' }}>
                        <AppSimpleInput
                            inputRef={emailInput}
                            placeholder="Email"
                            style={styles.text}
                            value={email}
                            onChange={(value) => setEmail(value)}
                            onSubmit={() => {
                                messageInput && (messageInput.current as any).focus()
                            }} />
                    </div>
                </div>
                <div style={{ ...styles.box, height: 240 }}>
                    <AppSimpleInput
                        inputRef={messageInput}
                        multiline
                        placeholder="Message"
                        style={{...styles.text, ...styles.multiline}}
                        value={message}
                        onChange={(value) => setMessage(value)}
                        onSubmit={() => {
                            onSubmitForm()
                        }} />
                </div>
                <LoadingBox loading={loading} spinnerSize={12}>
                    <AppSimpleButton disabled={loading} onClick={onSubmitForm}>Submit</AppSimpleButton>
                </LoadingBox>
                {alertDialogProps && <RenderedAlertDialog {...alertDialogProps} onClose={() => setAlertDialogProps(undefined)} />}
            </div>
        </DashThemeLight>
    )
}

export default ContactForm
