import React from 'react'
import {Img} from 'react-image'
import { CircularProgress } from '@mui/material';
import { IMG_DEFAULT_COVER } from '../Icons';

interface Props {
    url: string,
    className?: any,
    style?: any
}

const styles = ({
    imageStyle: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    centerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})


const LoadingImage: React.FC<Props> = (props) => {
    return (
        <Img
            src={[props.url, IMG_DEFAULT_COVER]}
            loader={<div style={styles.centerStyle} ><CircularProgress color="primary" /></div>}
            className={props.className}
            alt=""
            style={{...styles.imageStyle, ...props.style}}/>
    )
}

export default React.memo(LoadingImage)
