import { Page, navigationEventEmitter, EVENT_NAVIGATION, MenuPage } from "../../AppDrawer";
import { Station, StationsAPI } from "dash-dashradio-app-client-api";
import { GenreObj, AllStationsState, updateAllStations } from "../../../reducers/AllStationsReducer";
import { session } from "../../../App";
import { useDispatch } from "react-redux";

export function getFavorites(stations: AllStationsState) {
	return (
		stations.favorites && stations.favorites.map(id => {
			const filtered = (stations.stations && stations.stations.filter(station => Boolean(station.id === id))) || []
			return filtered.length > 0 && filtered[0]
		}).filter(Boolean) as Station[]
	) || []
}

export function AllStationsInGenreSelector(stations: Station[], genreId: number) {
	return stations
		.sort((a, b) => b.genre_priority - a.genre_priority)
		.filter(station => ((station.genreObj && station.genreObj.id === genreId) || false))
}

export function getGenres(stations: Station[]) {
	var genres: GenreObj[] = []
	const cachedStations = stations || []

	var genreIds: number[] = []
	if (cachedStations) {
		genreIds = Array.from(new Set(cachedStations
			.sort((a, b) => {
				if (a.genreObj && b.genreObj) {
					return b.genreObj.priority - a.genreObj.priority
				}
				return 0
			})
			.map((station => station.genreObj && station.genreObj.id))
			.filter(Boolean))
		) as number[]
	}

	if (genreIds) {
		genres = genreIds.map(genreId =>
			cachedStations.find(station => Boolean(station.genreObj && station.genreObj.id === genreId))!['genreObj']
		).filter(Boolean) as GenreObj[]
	}

	return genres
}

export function getActivePage(fallbackPage?: Page) {
	const websitePath = window.location.pathname || "";
	var activePage: Page | undefined = fallbackPage;

	Object.keys(Page).forEach(key => {
		if (
			websitePath === Page[key] ||
			(activePage === fallbackPage &&
				Page[key].endsWith("/*") &&
				websitePath.startsWith(Page[key].substr(0, Page[key].length - 2)))
		) {
			activePage = Page[key];
		}
	});

	return activePage;
}

export function setPageActive(
	page: Page,
	passive?: boolean,
	data?: {},
	urlParamters?: string
) {
	var url: string = page || "/home";
	if (urlParamters) {
		if (url.endsWith("/*")) {
			url = url.substr(0, url.length - 1);
		}
		if (!url.endsWith("/")) {
			url += "/";
		}
		url += urlParamters.startsWith("/") ? urlParamters.substr(1) : urlParamters;
	}
	if (window.location.pathname == url) {
		return	// was called hundreds of times unnecessarily
	}
	window.history.pushState(data || {}, window.document.title, url);

	//emit navigation event
	navigationEventEmitter.emit(EVENT_NAVIGATION, { url, page, passive });
}

export function getActiveMenu(fallbackMenu?: MenuPage) {
	const websitePath = window.location.pathname || "";
	var activeMenu: MenuPage | undefined = fallbackMenu;

	Object.keys(MenuPage).forEach(key => {
		if (websitePath.startsWith(MenuPage[key])) {
			activeMenu = MenuPage[key];
		}
	});

	return activeMenu;
}

export function getDrawerWidth(drawerOpen: boolean) {
	return getComputedStyle(document.documentElement).getPropertyValue(
		drawerOpen ? "--dimen-drawer-width-open" : "--dimen-drawer-width-closed"
	);
}

export function getDrawerWidthMobile(drawerOpen: boolean) {
	return getComputedStyle(document.documentElement).getPropertyValue(
		drawerOpen
			? "--dimen-drawer-width-open-mobile"
			: "--dimen-drawer-width-closed-mobile"
	);
}

export function goToTop() {
	window.scrollTo({
		behavior: 'smooth',
		left: 0,
		top: 0
	})
}
