import React, { useState, useRef, useEffect } from 'react';
import { IconButton, CircularProgress, useMediaQuery, DialogContent } from '@mui/material';
import clsx from 'clsx'
import Modal from '@mui/material/Modal';
import AppSimpleButton from './views/AppSimpleButton';
import AppSimpleInput from './views/AppSimpleInput';
import AppText from './views/AppText';
import { DashThemeLight, DashThemeDark } from '../Themes';
import { LoginAPI, PresetsAPI } from 'dash-dashradio-app-client-api';
import { session } from '../App';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import FadeInHOC from './ui-elements/FadeInHOC';
import { SocialLoginSection } from './LoginDialog';
import { useDispatch } from 'react-redux'
import { setPageActive } from './views/AppDrawerComponents/Functions';
import { Page } from './AppDrawer';
import Spacer from './ui-elements/Spacer';
import LoginHelper from '../helper/LoginHelper';
import dialogClasses from "./Dialog.module.css";

interface Props {
	onClose: () => void,
	onNoAccountWithEmail?: (input: { email: string, password: string }) => void,
	token?: string
}

export const ChangePasswordDialog: React.FC<Props> = (props) => {
	const { onClose } = props

	const passwordInput = useRef(null)
	const oldPasswordInput = useRef(null)

	const [oldPassword, setOldPassword] = useState("")
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<Error | undefined>(undefined)
	const [confirmError, setConfirmError] = useState<string | undefined>()
	const [success, setSuccess] = useState(false)
	const token = props.token
	const dispatch = useDispatch()
	const loginHelper: LoginHelper = new LoginHelper(dispatch, setError, setLoading, onClose);

	const handleChangePassword = () => {
		setConfirmError(undefined)
		setError(undefined)
		setLoading(true)
		if (password === confirmPassword && token) {
			LoginAPI.resetPassword(session)(token, password).then((resp) => {
				// onClose()
				setSuccess(true)
				setOldPassword("")
				setPassword("")
				setConfirmPassword("")
				setLoading(false)
			})
				.catch((err) => {
					setSuccess(false)
					setLoading(false)
					setError(err)
				})
		} else if (password === confirmPassword && !token) {
			LoginAPI.changePassword(session)(oldPassword, password).then((resp) => {
				// onClose()
				setSuccess(true)
				setOldPassword("")
				setPassword("")
				setConfirmPassword("")
				setLoading(false)
			})
				.catch((err) => {
					setSuccess(false)
					setLoading(false)
					setError(err)
				})
		} else {
			setConfirmError("Confirm Password wrong!")
			setLoading(false)
			setSuccess(false)
		}
	}

	var errorMessage
	const status: any = (error && error!['status']) || 400
	switch (status) {
		case 401:
			errorMessage = "Wrong password. Please check your input."
			break
		case 404:
			errorMessage = "Token is invalid!"
			break
		default:
		case 400:
			if (password.length < 6)
				errorMessage = "Please choose a password with at least 6 characters."
			else
				errorMessage = "Invalid credentials provided. Please check your input."
			break
	}

	return (
		<div className='dialog-popups' style={{ overflowX: 'hidden' }}>
			<div className='popup-header-container'>
				<div className='popup-title'>Hey there!</div>
				<DashThemeDark>
					<IconButton color="primary" onClick={() => onClose()} style={{ padding: 0 }}>
						<CloseIcon scale={24} />
					</IconButton>
				</DashThemeDark>
			</div>
			{!success &&
				<form className={dialogClasses.content}>
					<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 12 }}>
						<div className="dialog-title">Change your Password</div>
					</div>
					<div className='simple-input-container popup'>
						{!token &&
							<AppSimpleInput password
								inputRef={oldPasswordInput}
								placeholder="Old Password"
								value={oldPassword}
								onChange={(value) => setOldPassword(value)}
								onSubmit={handleChangePassword}
								popup />
						}
					</div>
					<div className='simple-input-container popup'>
						<AppSimpleInput password
							inputRef={passwordInput}
							placeholder="New Password"
							value={password}
							onChange={(value) => setPassword(value)}
							onSubmit={handleChangePassword}
							popup />
					</div>
					<div className='simple-input-container popup'>
						<AppSimpleInput password
							inputRef={passwordInput}
							placeholder="Confirm Password"
							value={confirmPassword}
							onChange={(value) => setConfirmPassword(value)}
							onSubmit={handleChangePassword}
							popup />
					</div>
					<div style={{ paddingBottom: 8}}>
						{
							((error && errorMessage) || confirmError) &&
							<AppText className={dialogClasses.error}>
								{(error && errorMessage) ? errorMessage : confirmError}
							</AppText>
						}
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: 29 }}>
						<div className='popup-button-container'>
							<AppSimpleButton disabled={loading} onClick={handleChangePassword} popup>Change Password</AppSimpleButton>
							{
								loading &&
								<DashThemeLight>
									<CircularProgress color="primary" style={{ width: 24, height: 24, margin: '0px 16px', lineHeight: 0, color: '#ff3f90' }} />
								</DashThemeLight>
							}
						</div>
					</div>
					{
						!(error && errorMessage) &&
						<SocialLoginSection isLoading={loading} setLoading={setLoading}
							loginHelper={loginHelper} />
					}
				</form>
			}
			{success &&
				<>
					<div className={dialogClasses.content}>
						<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 11, marginTop: 56 }}>
							<div className="dialog-title">Password Succesfully Changed</div>
						</div>
						{ token &&
							<>
							<div className='popup-button-container' style={{ marginBottom: 83 }}>
							<AppSimpleButton disabled={loading} onClick={handleChangePassword} popup>Sign In</AppSimpleButton>
							{
								loading &&
								<DashThemeLight>
									<CircularProgress color="primary" style={{ width: 24, height: 24, margin: '0px 16px', lineHeight: 0, color: '#1DE9B6' }} />
								</DashThemeLight>
							}
						</div>
						{
							!(error && errorMessage) &&
							<SocialLoginSection isLoading={loading} setLoading={setLoading}
								loginHelper={loginHelper}/>
						}
						<Spacer size={34} />
						<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<div style={{ width: 275 }}>
								<AppText popup>By signing up, you agree to our <span className={dialogClasses.link} onClick={() => setPageActive(Page.TERMS_OF_SERVICE)}>Terms and Conditions</span> and <span className={dialogClasses.link} onClick={() => setPageActive(Page.PRIVACY_POLICY)}>Privacy Policy</span>.</AppText>
							</div>
						</div>
						</>
						}
					</div>
				</>
			}
		</div>
	)
}


export const ChangePasswordModal: React.FC<{ open: boolean, token?: string } & Props> = (props) => {
	const handleClose = () => props.onClose()

	return (
		<Modal open={props.open} onClose={handleClose} >
			<div>
				<FadeInHOC>
					<ChangePasswordDialog token={props.token} onClose={handleClose} />
				</FadeInHOC>
			</div>

		</Modal>
	)
}

export default ChangePasswordModal
