export default
   `The following are basic contest rules applying to any and all contests run on LITT Live. Each contest may have its own special qualifications and eligibility rules, in which case, a separate list of rules will be available for public inspection.

I. All LITT Live contests are open to all listeners, unless a specific age is required and specified by an advertiser, agency or by LITT Live. Full and part-time employees, and their families, of LITT Live, its representatives, its agencies or employees of any other AM, FM or television broadcast station serving the United States and persons, and their families, who have been employed by LITT Live in the (60) day period immediately preceding the effective date of the contest are ineligible to enter or participate in any and all LITT Live contests.

II. No purchase is necessary and the contest is void where prohibited by law.

III. Winners are not eligible to participate in any LITT Live contests for a period of sixty (60) days from the original date of qualifying or winning a contest and or prize conducted by LITT Live.

IV. All prizes, unless otherwise specified by LITT Live, must be retrieved within thirty (30) days from the date won. Winners will be required to present their valid government issued photo identification to claim prize. It is the responsibility of the winner, unless otherwise specified by LITT Live, to claim their prize within the time limit. Prizes specified to be picked-up must be claimed in person and signed for by the winner. Designated winner’s representatives will be allowed to claim prizes only if LITT Live is informed in writing and by phone that such a representative will be picking up their prize and that they have been given the power of attorney to sign a prize release for the winner and or approved by a representative of LITT Live.

V. If a winner is under 18 years of age, LITT Live requires that their parent or legal guardian accompany said winner when claiming prize. Additionally, parent or legal guardian must present valid photo identification and must sign the LITT Live prize release form to claim prize.

VI. All prizes not claimed within the thirty (30) day period will become the property of LITT Live. LITT Live may at its discretion, award the prize to another listener, either in the same contest, or at a later date and separate contest. Every attempt will be made to award all major contest prizes, whether claimed by the original winner or not.

VII. In case of dispute over telephone line malfunctions, the decision of LITT Live will be final. In the event that 2 or more phone lines are “tied together” during a phone-in contest, the entire call will be disqualified, and another call will be taken. LITT Live cannot award duplicate prizes to both parties in a “tied-together” situation, which is a circumstance beyond the control of LITT Live.
`