import React from 'react'
import { CircularProgress } from '@mui/material'

interface Props {
	size?: number
}
const ProgressSpinner: React.FC<Props> = (props) => {
	const size = props.size || 24

	return (
		<CircularProgress size={size} style={{lineHeight: 0}} />
	)
}

export default ProgressSpinner
