import React from 'react'
import { useMediaQuery } from '@mui/material';
import { ICON_APPLE_WHITE, ICON_PLAYSTORE } from '../../Icons';
import { URL_APPSTORE_IOS, URL_APPSTORE_ANDROID } from '../../Constants';
import classes from "./AppButtonNew.module.css";


interface Props {
    classname?: any,
    disabled?: boolean,
    style?: any,
    appStore?: boolean,
    playStore?: boolean,
    mobileScreen?: boolean
}
const AppButtonNew: React.FC<Props> = (props) => {
    const smallScreen = useMediaQuery('(max-width: 960px)');
    return (
        <>
            {props.appStore &&
                <div className={props.classname ? props.classname : classes.iconContainer} style={{...props.style}} onClick={() => window.open(URL_APPSTORE_IOS, "_blank")}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={ICON_APPLE_WHITE} alt="app store" width={14} height={14} />
                    </div>
                    <div className={classes.textContainer} style={{ fontSize: smallScreen ? 10 : 17 }}>
                        App Store
            </div>
                </div>}
            {props.playStore &&
                <div className={props.classname ? props.classname : classes.iconContainer} style={{backgroundColor: 'white', ...props.style }} onClick={() => window.open(URL_APPSTORE_ANDROID, "_blank")}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={ICON_PLAYSTORE} alt="app store" width={14} height={14} />
                    </div>
                    <div className={classes.textContainer} style={{ color: '#034F66', fontSize: smallScreen ? 10 : 17 }}>
                        Google Play
                     </div>
                </div>}
        </>
    )
}


export default AppButtonNew
