import { FeaturedStation } from "dash-dashradio-app-client-api";
import { ReduxAppState } from ".";

interface FeaturedStationsState {
    featuredStations: FeaturedStation[]
}
const initialState: FeaturedStationsState = { featuredStations: [] }

const ACTION_UPDATE = "actions-featured-stations-update"
interface ActionUpdate {
    type: typeof ACTION_UPDATE,
    featuredStations: FeaturedStation[]
}
export type Actions = ActionUpdate

export function updateFeaturedStations(featuredStations: FeaturedStation[]): ActionUpdate {
    return {
        type: ACTION_UPDATE,
        featuredStations: featuredStations || []
    }
}


export default function FeaturedStationsReducer(state = initialState, action: Actions): FeaturedStationsState {
    var isEqual = false
    var stateUpdate
    switch(action.type) {
        case ACTION_UPDATE: {
            stateUpdate = {
                ...state,
                featuredStations: action.featuredStations
            }
            break
        }
    }
    if(stateUpdate) {
        try {
            if(JSON.stringify(state)===JSON.stringify(stateUpdate)) {
                isEqual = true
            }
        }
        catch(err) {
            //
        }
        if(!isEqual) {
            return stateUpdate      //return new state
        }
    }
    return state
}

export const FeaturedStationsSelector = (state: ReduxAppState) => {
    return (
        state.featuredStations.featuredStations && state.featuredStations.featuredStations.sort((a,b)=>(b.priority-a.priority)).map(featured => {
            var station //= featured['stationObj']
            if(!station) {
                const filtered = state.allStations.stations.filter(station => (station.id===featured.station_id))
                station = filtered && filtered.length>0 && filtered[0]
            }
            return station
        }).filter(Boolean)
    ) || []
}