import * as React from 'react'
import { Station, Schedules } from 'dash-dashradio-app-client-api';
import { Referrer, PlayingSelector, setPlaybackState, PlaybackState, setCurrentStation } from '../../reducers/PlayerReducer';
import { useSelector, useDispatch } from 'react-redux'
import { ReduxPlayerState } from '../../reducers';
import PlayerStoreProvider from '../../PlayerStore';
import moment from 'moment';

interface Props {
    station: Station,
    referrerInfo?: { referrer: Referrer, referrerId?: string | number },
    schedule: Schedules,
    color: string,
    currentDay: moment.Moment,
    hours: number,
    cellWidth: number
}

const GuideProgramm: React.FC<Props> = (props) => {
    const { station, referrerInfo, schedule, color, currentDay, hours, cellWidth } = props

    const playing: boolean = useSelector(PlayingSelector)
    const activeStation: Station | undefined = useSelector((state: ReduxPlayerState) => state.player.currentStation)
    const dispatch = useDispatch()

    const stationClick = () => {
        localStorage.setItem('currentStation', JSON.stringify(station))
        if (activeStation && activeStation.id === station.id && playing) {
            dispatch(setPlaybackState(PlaybackState.PAUSED))
        }
        else dispatch(setCurrentStation(station, PlaybackState.PLAYING, referrerInfo && referrerInfo.referrer, referrerInfo && referrerInfo.referrerId))
    }

    let start = moment(schedule.start_date)/* .add(8, 'hour') */
    let end = moment(schedule.end_date)/* .add(8, 'hour') */
    //Berechnet die Größe der Spalte um diese exakt so groß zu machen, wie die Dauer des Programms, außer das Programm läuft aktuell,
    //dann wird die aktuelle Zeit als Startpunkt genommen.
    let t = ((moment(schedule.end_date).valueOf() > (moment(currentDay).add(hours / 24, 'day').valueOf() + 3600 * 1000) ? (moment(currentDay).add(hours / 24, 'day') as any) : (end as any)) - ((start as any) >= (currentDay as any) ? (start as any) : currentDay)) / 1000 / 3600 * cellWidth
    let left = ((start as any) - (currentDay as any)) / 1000 / 3600 * cellWidth
    return (
        <div key={`Program_${schedule.id}`} className="program" style={{ minWidth: t, maxWidth: t, left: left > 0 ? left : 0 }} onClick={stationClick}>
            {t > cellWidth * 0.3 &&
                <>
                    <div style={{ backgroundColor: color, width: "1px" }}></div>
                    <div className="program-content" style={{ backgroundColor: `${color}20` }}>
                        <span className="program-font" style={{ width: t - 21 }}>{schedule.program.name.replace(/\u00AE/g, "")}</span>
                        <span className="program-font" style={{ width: t - 21 }}>{schedule.program.description}</span>
                        <span className="program-font" style={{ width: t - 21 }}>{`${start.format("L")} ${start.format("LT")} - ${end.format("L")} ${end.format("LT")}`}</span>
                    </div>
                </>}
        </div>
    )
}

export default React.memo((props: Props) => (
    <PlayerStoreProvider>
        <GuideProgramm {...props} />
    </PlayerStoreProvider>
))