import React, { useState, useRef } from 'react';
import { IconButton, CircularProgress, useMediaQuery, Backdrop } from '@mui/material';
import * as EmailValidator from 'email-validator';
import Modal from '@mui/material/Modal';
import AppSimpleButton from './views/AppSimpleButton';
import AppSimpleInput from './views/AppSimpleInput';
import AppText from './views/AppText';
import { DashThemeLight, DashThemeDark } from '../Themes';
import { LoginAPI, PresetsAPI } from 'dash-dashradio-app-client-api';
import { session } from '../App';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import FadeInHOC from './ui-elements/FadeInHOC';
import { SocialLoginSection } from './LoginDialog';
import { useDispatch } from 'react-redux'
import LoginHelper from '../helper/LoginHelper';
import dialogClasses from "./Dialog.module.css";

export const ForgotPasswordDialog: React.FC<{ onCloseClick: () => void, onBackClick?: () => void }> = (props) => {
    const { onCloseClick, onBackClick } = props
    const emailInput = useRef(null)

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>(undefined)
    const [successDialogVisible, setSuccessDialogVisible] = useState(false)
    const dispatch = useDispatch()
    const loginHelper: LoginHelper = new LoginHelper(dispatch, setError, setLoading, onBackClick || onCloseClick);

    const handleForgotPassword = () => {
        setLoading(true)
        LoginAPI.forgotPassword(session)(email).then(data => {
            if (data) {
                setLoading(false)
                setError(undefined)
                //show success dialog
                setSuccessDialogVisible(true)
            }
        }).catch(err => {
            setLoading(false)
            setError(err)
        })
    }

    var errorMessage
    const status: any = (error && error!['status']) || 400
    switch (status) {
        default:
        case 400:
            if(!EmailValidator.validate(email))
                errorMessage = "Please provide a valid email address."
            else
                errorMessage = "Please check your input and fill in all fields."
            break
        case 404:
            errorMessage = "We could not find anybody with this email address."
            break;

    }

    return (
        <DashThemeLight>
            <div className='dialog-popups' style={{ overflowX: 'hidden' }}>
                <div className='popup-header-container'>
                    <div className='popup-title'>Hey there!</div>
                    <DashThemeDark>
                        <IconButton color="primary" onClick={() => props.onCloseClick()} style={{ padding: 0 }}>
                            <CloseIcon scale={24} />
                        </IconButton>
                    </DashThemeDark>
                </div>
                <div className={dialogClasses.content}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 12 }}>
                        <div className="dialog-title">Forgot your password?</div>
                    </div>
                    <div className='simple-input-container popup'>
                        <AppSimpleInput
                            inputRef={emailInput}
                            placeholder="email@example.com"
                            value={email}
                            onChange={(value) => setEmail(value)}
                            onSubmit={handleForgotPassword}
                            popup />
                    </div>
                    { !successDialogVisible &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            <div className='popup-button-container'>
                                <AppSimpleButton disabled={loading} onClick={handleForgotPassword} popup>Recover Password</AppSimpleButton>
                                {
                                    loading &&
                                    <DashThemeLight>
                                        <CircularProgress color="primary" style={{ width: 24, height: 24, margin: '0px 16px', lineHeight: 0, color: '#ff3f90' }} />
                                    </DashThemeLight>
                                }
                            </div>
                        </div>
                    }
                    { successDialogVisible &&
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 11, marginTop: 20 }}>
							<div className="dialog-title">We sent you an email to confirm the password reset.</div>
					    </div>
                    }
                    <div style={{ paddingTop: 8, justifyContent: "center"}}>
						{
							(error && errorMessage)  &&
							<AppText className={dialogClasses.error}>
								{errorMessage}
							</AppText>
						}
					</div>
                    <div className="dialog-title go-back" onClick={props.onCloseClick}>Go Back to Sign In</div>
                    {
                        !(error && errorMessage) &&
                        <SocialLoginSection isLoading={loading} setLoading={setLoading}
                            loginHelper={loginHelper} />
                    }
                </div>
            </div>
            {/* <div className={classes.drawerStyle} style={{ overflowX: 'hidden' }}>
                <div className={clsx(classes.darkBackground, classes.flexbox)}>
                    {
                        onBackClick &&
                        <DashThemeDark>
                            <IconButton color="primary" onClick={onBackClick!}>
                                <img className={classes.iconStyle} src={Icons.ICON_CHEVRON_LEFT_WHITE} alt="" />
                            </IconButton>
                        </DashThemeDark>
                    }
                    <div className={classes.drawerTitle}>Hey there!</div>
                    <DashThemeDark>
                        <IconButton color="primary" onClick={() => onCloseClick()}>
                            <CloseIcon scale={24} />
                        </IconButton>
                    </DashThemeDark>
                </div>

                <div className={classes.content}>
                    <Headline2Text className={classes.whiteText}>Reset Passwort</Headline2Text>
                    <AppText>Please enter your email address. We will send you a link to reset your password.</AppText>

                    <div style={{ width: 64, paddingBottom: 12, paddingTop: 4 }}></div>

                    <AppSimpleInput
                        inputRef={emailInput}
                        placeholder="email@example.com"
                        value={email}
                        onChange={(value) => setEmail(value)}
                        onSubmit={() => {
                            handleForgotPassword()
                        }} />

                    <div style={{ width: 64, paddingBottom: 12 }}></div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <AppSimpleButton disabled={loading} onClick={handleForgotPassword}>Send Reset Link</AppSimpleButton>
                        {
                            loading &&
                            <CircularProgress color="primary" style={{ width: 24, height: 24, margin: '0px 16px' }} />
                        }
                    </div>

                    <div style={{ minHeight: 75, paddingTop: 16, paddingBottom: 16 }}>
                        {
                            error && errorMessage && <AppText className={classes.error}>{errorMessage}</AppText>
                        }
                    </div>
                    <AppText>By signing up, you agree to our <span className={classes.link} onClick={() => setPageActive(Page.TERMS_OF_SERVICE)}>Terms and Conditions</span> and <span className={classes.link} onClick={() => setPageActive(Page.PRIVACY_POLICY)}>Privacy Policy</span>.</AppText>

                </div>

                {
                    successDialogVisible &&
                    <RenderedAlertDialog onClose={() => onCloseClick()} title="Forgot Password" message={`An email has been sent to ${email}. You can reset your password using the link in the email.`} />
                }

            </div> */}
        </DashThemeLight>
    )
}

export const ForgotPasswordDialogModal: React.FC<{ open: boolean, onClose: () => void, onBack?: () => void }> = (props) => {
    const handleClose = () => props.onClose()

    return (
        <Modal open={props.open} onClose={handleClose} >
            <div>
                <FadeInHOC>
                    <ForgotPasswordDialog onCloseClick={handleClose} onBackClick={props.onBack} />
                </FadeInHOC>
            </div>

        </Modal>
    )
}

export default ForgotPasswordDialogModal
