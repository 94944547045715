import * as React from 'react';
import MediaQuery from 'react-responsive';



export default class extends React.Component<{children?: React.ReactNode}> {

	// constructor(props: Props) {
	// 	super(props);
	// }

	render() {
		return (
			<MediaQuery orientation="landscape">
				{this.props.children}
			</MediaQuery>
		)
	}
}
